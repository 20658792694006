import { combineReducers } from "redux";
import {
  GET_CUSTOMER_PERFORMANCE,
  GET_CUSTOMER_PERFORMANCE_ERR,
  GET_CUSTOMER_PERFORMANCE_OK,

  GET_LEADERBOARD,
  GET_LEADERBOARD_OK,
  GET_LEADERBOARD_ERR,
  DO_LEADERBOARD_CLEANUP,

  GET_LEADERBOARD_LIST,
  GET_LEADERBOARD_LIST_OK,
  GET_LEADERBOARD_LIST_ERR,
  DO_LEADERBOARD_LIST_CLEANUP,

  GET_SUPPORT_STATS,
  GET_SUPPORT_STATS_OK,
  GET_SUPPORT_STATS_ERR,
  DO_SUPPORT_STATS_CLEANUP,

  GET_SCHEDULER_CHATS,
  GET_SCHEDULER_CHATS_OK,
  GET_SCHEDULER_CHATS_ERR,
  DO_SCHEDULER_CHATS_CLEANUP,

  GET_SCHEDULER_PROGRESS_ORDERS,
  GET_SCHEDULER_PROGRESS_ORDERS_OK,
  GET_SCHEDULER_PROGRESS_ORDERS_ERR,
  DO_SCHEDULER_PROGRESS_ORDERS_CLEANUP,
  GET_APPOINTMENTS_LIST_OK,
  GET_APPOINTMENTS_LIST_ERR,
  DO_APPOINTMENTS_LIST_CLEANUP,
  GET_APPOINTMENTS_LIST
} from "./actionTypes"

/********** CUSTOMER PERFORMANCE **********/

const defaultCustomerPerformanceState = {
  stats: null,
  isLoadInProgress: false,
  customerPerformanceError: null,
}

export const CustomerPerformance = (state = defaultCustomerPerformanceState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_PERFORMANCE:
      state = { ...state, isLoadInProgress: true, customerPerformanceError: null };
      break
    case GET_CUSTOMER_PERFORMANCE_OK:
      state = { ...state, stats: action.payload.response, isLoadInProgress: false };
      break
    case GET_CUSTOMER_PERFORMANCE_ERR:
      state = { ...state, customerPerformanceError: action.payload.error, isLoadInProgress: false };
      break
  }

  return state;
}

/********** LEADERBOARD **********/

const defaultLeaderboardState = {
  leaderboard: [],
  isLoadInProgress: false,
  leaderboardError: null,
}

export const Leaderboard = (state = defaultLeaderboardState, action) => {
  switch (action.type) {
    case GET_LEADERBOARD:
      state = {
        ...state,
        isLoadInProgress: true,
        leaderboardError: null
      };
      break
    case GET_LEADERBOARD_OK:
      state = {
        leaderboard: action.payload.response.dealerStores,
        isLoadInProgress: false
      };
      break
    case GET_LEADERBOARD_ERR:
      state = {
        ...state,
        leaderboardError: action.payload.error,
        isLoadInProgress: false
      };
      break
    case DO_LEADERBOARD_CLEANUP:
      state = { ...defaultLeaderboardState };
      break
  }

  return state;
}

/********** LEADERBOARD LIST **********/

const defaultLeaderboardListState = {
  leaderboard: [],
  isLoadInProgress: false,
  leaderboardError: null,
  totalCount: 0,
  listParams: {
    limit: null
  },
}

export const LeaderboardList = (state = defaultLeaderboardListState, action) => {
  switch (action.type) {
    case GET_LEADERBOARD_LIST:
      state = {
        ...state,
        listParams: action.payload.params,
        isLoadInProgress: true,
        leaderboardError: null
      };
      break
    case GET_LEADERBOARD_LIST_OK:
      state = {
        ...state,
        leaderboard: action.payload.response.dealerStores,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false
      };
      break
    case GET_LEADERBOARD_LIST_ERR:
      state = {
        ...state,
        leaderboard: [],
        totalCount: 0,
        leaderboardError: action.payload.error,
        isLoadInProgress: false
      };
      break
    case DO_LEADERBOARD_LIST_CLEANUP:
      state = { ...defaultLeaderboardListState };
      break
  }

  return state;
}

/********** SUPPORT STATS **********/

const defaultSupportStatsState = {
  totalMessages: null,
  workInProgress: null,
  remoteVid: null,
  remote: null,
  instore: null,
  sealed: null,
  isLoadInProgress: false,
  statsError: null,
}

const SupportStats = (state = defaultSupportStatsState, action) => {
  switch (action.type) {
    case GET_SUPPORT_STATS:
      state = { ...state, statsError: null, isLoadInProgress: true };
      break
    case GET_SUPPORT_STATS_OK:
      state = {
        ...state,
        isLoadInProgress: false,
        workInProgress: action.payload.response.workInProgress,
        remote: action.payload.response.remote,
        instore: action.payload.response.instore,
        remoteVid: action.payload.response.remoteVid,
        sealed: action.payload.response.sealed
      };
      break
    case GET_SUPPORT_STATS_ERR:
      state = { ...state, isLoadInProgress: false, statsError: action.payload.error };
      break
    case DO_SUPPORT_STATS_CLEANUP:
      state = { ...defaultSupportStatsState };
      break
  }

  return state;
}

/********** SCHEDULER CHATS **********/

const defaultSchedulerChats = {
  chats: [],
  isLoadInProgress: false,
  chatsError: null
}

export const SchedulerChats = (state = defaultSchedulerChats, action) => {
  switch (action.type) {
    case GET_SCHEDULER_CHATS:
      state = {
        ...state,
        isLoadInProgress: true,
        chatsError: null,
      };
      break
    case GET_SCHEDULER_CHATS_OK:
      state = {
        ...state,
        chats: action.payload.response.chats,
        isLoadInProgress: false,
      };
      break
    case GET_SCHEDULER_CHATS_ERR:
      state = {
        ...state,
        chats: [],
        isLoadInProgress: false,
        chatsError: action.payload.error
      };
      break
    case DO_SCHEDULER_CHATS_CLEANUP:
      state = { ...defaultSchedulerChats };
      break
  }

  return state;
}

/********** SCHEDULER IN PROGRESS ORDERS**********/

const defaultSchedulerProgressOrders = {
  progressOrders: [],
  isLoadInProgress: false,
  progressOrdersError: null,
  listParams: {
    limit: null
  },
}

export const SchedulerProgressOrders = (state = defaultSchedulerProgressOrders, action) => {
  switch (action.type) {
    case GET_SCHEDULER_PROGRESS_ORDERS:
      state = {
        ...state,
        isLoadInProgress: true,
        progressOrdersError: null,
        listParams: action.payload.params,
      };
      break
    case GET_SCHEDULER_PROGRESS_ORDERS_OK:
      state = {
        ...state,
        progressOrders: action.payload.response.orders,
        isLoadInProgress: false
      };
      break
    case GET_SCHEDULER_PROGRESS_ORDERS_ERR:
      state = {
        ...state,
        isLoadInProgress: false,
        progressOrdersError: action.payload.error,
      };
      break
    case DO_SCHEDULER_PROGRESS_ORDERS_CLEANUP:
      state = { ...defaultSchedulerProgressOrders };
      break
  }

  return state;
}

/********** APPOINTMENTS LIST **********/

const defaultAppointmentList = {
  appointments: [],
  appointmentsError: null,
  totalCount: 0,
  listParams: {
    sortBy: 'id',
    sortDir: 'desc',
    pageSize: 10,
    page: 1,
    search: null,
    filters: null,
  },
  isLoadInProgress: false,
};

const AppointmentsList = (state = defaultAppointmentList, action) => {
  switch (action.type) {
    case GET_APPOINTMENTS_LIST:
      state = {
        ...state,
        listParams: action.payload.params,
        appointmentsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_APPOINTMENTS_LIST_OK:
      state = {
        ...state,
        appointments: action.payload.response.appointments,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false,
      };
      break
    case GET_APPOINTMENTS_LIST_ERR:
      state = {
        ...state,
        appointments: [],
        totalCount: 0,
        appointmentsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_APPOINTMENTS_LIST_CLEANUP:
      state = {
        ...state,
        appointments: [],
        appointmentsError: null,
      };
      break
  }
  return state;
}

export default combineReducers({
  CustomerPerformance,
  Leaderboard,
  LeaderboardList,
  SupportStats,
  SchedulerChats,
  SchedulerProgressOrders,
  AppointmentsList
});