import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom"
import MetaTitle from "components/Shared/MetaTitle";
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import Preloader from "components/Shared/Preloader";
import Error from "pages/Error";
import AccessDenied from "pages/Error/AccessDenied";
import { useDispatch, useSelector } from "react-redux";
import { perms, useAccess } from "context/access";
import { route, routes } from "helpers/routeHelper";
import { doOrderSingleCleanup, getOrderNotaryBidDt, getOrderWithCustomerSigners, startOrderNotaryBid } from "store/actions";
import { useParams, useNavigate } from "react-router-dom";
import DataTableNotaryBids from "../Partial/DataTable/NotaryBids";
import Confirmation from "components/Shared/Confirmation";
import { showError, showSuccess } from "helpers/utilHelper";
import { useSocketOn, useSubscribeToOrder } from "hooks/socket";
import socketEvent from "constants/socketEvent";
import config from "config";
import Messages from "../Partial/DataTable/Messages";
import NotaryBid from "model/notaryBid";
import { timestamp } from "helpers/dateHelper";
import { AccessDeniedException, ServerErrorException, ORDER_STATUS_INVALID_FOR_START_BIDDING } from "helpers/errorHelper";

const ViewNotary = () => {

  let { id } = useParams();
  id = parseInt(id);

  const dispatch = useDispatch();

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();
  // router hook that helps redirect
  const navigate = useNavigate();

  /********** STATE **********/

  // get redux state from the store
  const { order, orderError, isLoadInProgress } = useSelector(state => state.Order.Single);
  const { started, isStartInProgress, startError } = useSelector(state => state.Order.NotaryBid);
  const { notaryBids } = useSelector(state => state.Order.NotaryBidDt);
  const [initiateBiddingModal, setInitiateBiddingModal] = useState(false);

  /********** OTHER **********/

  const schedulerAssigned = !!order && !!order.schedulerId;

  const biddingIsNotStarted = !!order && !order.isNotarySearchExhausted && order.notarySearchUpdatedTs === null;

  const biddingIsExhausted = !!order && order.isNotarySearchExhausted;

  const biddingIsComplete = notaryBids.some(bid => bid.status === NotaryBid.STATUS_AWARDED_BY_SCHEDULER);

  const biddingIsStuck = () => {
    if (!order || biddingIsNotStarted || biddingIsExhausted || biddingIsComplete) {
      return false;
    }
    // we need a way to determine whether the bidding is stuck and will never complete (this can happen when the bidding worker crashes)
    // and to do that, we look at when the bidding was last updated
    // worst case scenario (no response from any of the notaries), the bidding will be updated once every config.NOTARY_BID_EXPIRY seconds
    // so if we find that too much time has passed since the bidding was last updated, we assume it is stuck
    // also since the timing between server and client is not in perfect sync, we allow for a 5 min margin
    const minValidTimestamp = timestamp() - parseInt(config.NOTARY_BID_EXPIRY) - 300;
    return order.notarySearchUpdatedTs < minValidTimestamp;
  }

  const biddingIsRunning = !biddingIsNotStarted && !biddingIsExhausted && !biddingIsComplete && !biddingIsStuck();

  const refreshNotaryBids = () => dispatch(getOrderNotaryBidDt(id));

  const refreshOrder = () => dispatch(getOrderWithCustomerSigners(id));

  // Display the Initiate Bidding button based on the following conditions
  const showInitiateBiddingBtn = () => {
    if (isStartInProgress || biddingIsRunning) {
      // bidding is in the process of starting or already running
      // so no need for this button
      return;
    }
    let btnText = 'Initiate Bidding';
    if (biddingIsStuck()) {
      btnText = 'Restart Bidding';
    }
    return <Button type="button" color="primary" className="btn-rounded" onClick={() => setInitiateBiddingModal(true)}>
      {isStartInProgress ? <i className="mdi mdi-spin mdi-loading me-1" /> : <i className="mdi mdi-autorenew me-1" />}
      {btnText}
    </Button>
  }

  // Display the Initiate Bidding messages based on the following conditions
  const showBiddingNotification = () => {

    if (biddingIsComplete) return <Messages description='Bidding complete! Notary awarded.' status='success' />

    if (biddingIsExhausted) return <Messages description='Search finished. No more notaries to add' status='success' />

    if (!schedulerAssigned) return <Messages description='No scheduler assigned to this order' status='warning' />

    if (biddingIsNotStarted) return <Messages description='Please start the bidding process' status='warning' />

    if (biddingIsStuck()) return <Messages description='There seems to be a problem with the search. Please restart the bidding.' status='error' />

    return <Messages description='Notary search in progress' status='progress' />
  }

  /********** SOCKET **********/

  // start receiving updates about this particular order
  useSubscribeToOrder(id);

  const onNotaryBiddingChanged = useCallback(() => {
    refreshNotaryBids();
    refreshOrder();
  }, [refreshNotaryBids]);

  // listen for changes on messages
  useSocketOn(socketEvent.notaryBiddingChanged, onNotaryBiddingChanged);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the order data
    dispatch(getOrderWithCustomerSigners(id));
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderSingleCleanup());
    }
  }, [id]);

  // runs whenever the "bidded" flag changes
  // which happens after a initiate-bid attempt
  useEffect(() => {
    if (started === true) {
      showSuccess("Bidding has started");
    } else if (started === false) {
      if (startError instanceof AccessDeniedException) {
        if (startError.code == ORDER_STATUS_INVALID_FOR_START_BIDDING) {
          // admin can't initiate bidding , beacause there is no scheduler assigned
          showError("A scheduler needs to be assigned to the signing, for the bidding to start.");
          return;
        }
      }
      showError("Unable to initiate bidding");
    }
  }, [started, startError]);

  return <React.Fragment>
    {iAmGranted(perms.view_orders) && <div className="page-content">
      {order && <React.Fragment>
        <MetaTitle>#{order.id} | Orders</MetaTitle>
        <Container fluid>
          <Row>
            <Col><Breadcrumbs breadcrumbItems={breadcrumbs(order)} /></Col>
            <Col className="text-end">
              {showBiddingNotification()}
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-transparent pt-3 pb-0">
                  <Row>
                    <Col>
                      <div className="card-title mt-2 mb-0">Notary Assignment</div>
                    </Col>
                    <Col>
                      <div className="text-end">
                        {iAmGranted(perms.view_notary_bidding_logs) && <Link to={route(routes.view_order_bidding_logs, order.id)} className="btn btn-outline-dark btn-rounded me-2">
                          <i className="mdi mdi-autorenew me-1" /> View logs
                        </Link>}
                        <Button type="button" color="primary" className="btn-rounded me-2" onClick={() => navigate(route(routes.view_order_notary_manual_search, order.id))}>
                          <i className="mdi mdi-plus me-1" /> Add Notary Manually
                        </Button>
                        {showInitiateBiddingBtn()}
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <DataTableNotaryBids />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {initiateBiddingModal && <Confirmation
          confirmBtnText="Initiate Bidding"
          onConfirm={() => {
            dispatch(startOrderNotaryBid(order.id));
            setInitiateBiddingModal(false);
          }}
          onCancel={() => {
            setInitiateBiddingModal(false);
          }}>
          <span>Are you sure you want to initiate the bidding process? Notaries will be notified!</span>
        </Confirmation>}
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !order && <Preloader className="inner" />}
      {orderError && <Error error={orderError} title404="Order not found" />}
    </div>}
    {iAmNotGranted(perms.view_orders) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = order => [{
  title: `${order.signers[0].fullName}`,
  url: route(routes.view_order, order.id),
}, {
  title: "Edit order",
  url: route(routes.view_order, order.id),
}, {
  title: "Notary",
}];

export default ViewNotary;