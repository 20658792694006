import Tag from "model/tag";
import { get, del, post, put } from "./apiHelper"
import { flattenFilters, withAuth } from "./utilHelper"

const getEntityList = (url, params) => get(url, { params: flattenFilters(params) });

// User

export const getAuthUser = () => get('/me');

export const impersonateUser = id => post(withAuth(`/impersonate/${id}`));

export const getUserDt = params => getEntityList('/users', params);

export const getDealerUserList = () => get('users/dealers');

export const getSchedulerDt = params => getEntityList('/users/schedulers', params);

export const getSchedulerUserList = () => get('/users/schedulers/list');

export const getAccountManagers = () => get('/users/account-managers');

export const getUser = id => get(`/user/${id}`);

export const getManagedStores = id => get(`/user/${id}/dealer-stores`);

export const deleteUser = id => del(`/user/${id}`);

export const createUser = data => post('/user', data);

export const updateUser = (data, id) => put(`/user/${id}`, data);

export const sendVerificationEmail = userId => post(`/user/${userId}/send-verification-link`);

export const getVerificationLink = userId => get(`/user/${userId}/verification-link`);

export const getPasswordResetLink = userId => get(`/user/${userId}/password-reset-link`);

export const unlockAccount = userId => put(`/user/${userId}/unlock`);

export const checkEmailAlert = userId => post(`/user/${userId}/check-email-alert`);

export const goIdle = () => put('/me/idle');

export const goActive = () => put('/me/active');

// UserRole

export const getUserRoles = () => get('/user-roles/list');

// DealerGroup

export const getDealerGroups = () => get('/dealer-groups/list');

export const getDealerGroupDt = params => getEntityList('/dealer-groups', params);

export const getDealerGroup = id => get(`/dealer-group/${id}`);

export const getDealerGroupStoresDetails = id => get(`/dealer-group/${id}/stores-advanced`);

export const deleteDealerGroup = id => del(`/dealer-group/${id}`);

export const createDealerGroup = data => post('/dealer-group', data);

export const updateDealerGroup = (data, id) => put(`/dealer-group/${id}`, data);

export const updateDealerSpecialInstructions = (data, id) => put(`/dealer-group/${id}/special-instructions`, data);

export const getDealerGroupManagers = id => get(`/dealer-group/${id}/managers`);

export const getDealerGroupRegions = id => get(`/dealer-group/${id}/regions`);

export const getDealerGroupStores = id => get(`/dealer-group/${id}/stores`);

// DealerRegion

export const getDealerRegionDt = params => getEntityList('/dealer-regions', params);

export const getDealerRegion = id => get(`/dealer-region/${id}`);

export const deleteDealerRegion = id => del(`/dealer-region/${id}`);

export const createDealerRegion = data => post('/dealer-region', data);

export const updateDealerRegion = (data, id) => put(`/dealer-region/${id}`, data);

export const getDealerRegionManagers = id => get(`/dealer-region/${id}/managers`);

export const getDealerRegionStores = id => get(`/dealer-region/${id}/stores`);

// DealerStore

export const getDealerStores = () => get('/dealer-stores/list');

export const getDealerStoreDt = params => getEntityList('/dealer-stores', params);

export const getDealerStore = id => get(`/dealer-store/${id}`);

export const deleteDealerStore = id => del(`/dealer-store/${id}`);

export const createDealerStore = data => post('/dealer-store', data);

export const updateDealerStoreInfo = (data, id) => put(`/dealer-store/${id}/info`, data);

export const updateDealerStoreContact = (data, id) => put(`/dealer-store/${id}/contact`, data);

export const updateDealerStoreContact2 = (data, id) => put(`/dealer-store/${id}/contact2`, data);

export const updateDealerStoreFinance = (data, id) => put(`/dealer-store/${id}/finance`, data);

export const updateDealerStoreBilling = (data, id) => put(`/dealer-store/${id}/billing`, data);

export const getDealerStoreUsers = id => get(`/dealer-store/${id}/users`);

export const getDealerStoreSigners = id => get(`/dealer-store/${id}/signers`);

export const extendDealerStoreFreeTrial = id => put(`/dealer-store/${id}/extend-free-trial`);

export const getSubscriptionDt = params => getEntityList('/dealer-stores/subscriptions', params);

// DealerStoreSigner

export const getDealerStoreSigner = id => get(`/dealer-store-signer/${id}`);

export const deleteDealerStoreSigner = id => del(`/dealer-store-signer/${id}`);

export const createDealerStoreSigner = data => post('/dealer-store-signer', data);

export const updateDealerStoreSigner = (data, id) => put(`/dealer-store-signer/${id}`, data);

// PaymentPlan

export const getPaymentPlans = () => get('/payment-plans/list');

export const getPaymentPlanDt = params => getEntityList('/payment-plans', params);

export const getPaymentPlan = id => get(`/payment-plan/${id}`);

export const deletePaymentPlan = id => del(`/payment-plan/${id}`);

export const createPaymentPlan = data => post('/payment-plan', data);

export const updatePaymentPlan = (data, id) => put(`/payment-plan/${id}`, data);

export const getPaymentPlanFees = (id) => get(`/payment-plan/${id}/fees`);

export const createPaymentPlanFee = (data, id) => post(`/payment-plan/${id}/fee`, data);

export const getPaymentPlanFee = id => get(`payment-plan-fee/${id}`);

export const updatePaymentPlanFee = (data, id) => put(`payment-plan-fee/${id}`, data);

export const deletePaymentPlanFee = id => del(`payment-plan-fee/${id}`);

// Statement

export const getDealerStatementDt = params => getEntityList('/statements/dealer', params);

export const getNotaryStatementDt = params => getEntityList('/statements/notary', params);

export const getStatement = id => get(`/statement/${id}`);

export const addPaymentRef = (id, paymentRefId) => put(`/statement/${id}/payment-ref`, paymentRefId);

export const sendStatementToQb = (id) => post(`/statement/${id}/invoice`);

export const sendStatementToCoupa = (id) => post(`/statement/${id}/invoiceCoupa`);

// AdditionalFee

export const getAdditionalFees = () => get('/additional-fees');

export const getAdditionalFee = id => get(`/additional-fee/${id}`);

export const updateAdditionalFee = (data, id) => put(`/additional-fee/${id}`, data);

export const getAdditionalFeesList = () => get('/additional-fees/list');


// Order

export const getOrderDt = params => getEntityList('/orders', params);

export const getOrder = id => get(`/order/${id}`);

export const deleteOrder = id => del(`/order/${id}`);

export const updateOrderCustomer = (data, id) => put(`/order/${id}/customer`, data);

export const updateOrderVehicle = (data, id) => put(`/order/${id}/vehicle`, data);

export const updateOrderContract = (data, id) => put(`/order/${id}/contract`, data);

export const updateNotaryMeetingDate = (id, data) => put(`notary/order/${id}/set-appointment`, data);

export const cancelOrder = id => put(`/order/${id}/cancel`);

export const uncancelOrder = id => put(`/order/${id}/uncancel`);

export const resendCustomerNotifications = id => post(`/order/${id}/resend-customer-notifications`);

export const reloadOrderDealerSigners = id => post(`/order/${id}/reload-dealer-signers`);

export const createOrderMeeting = (orderId, role) => post(`/order/${orderId}/meet/${role}`);

export const getOrderSigners = (orderId, signerType) => get(`/order/${orderId}/signers/${signerType}`);

export const getOrderVehicles = orderId => get(`/order/${orderId}/vehicles`);

export const getOrderNotaries = orderId => get(`/order/${orderId}/notaries`);

export const getOrderVidStatus = id => get(`/order/${id}/vid/status`);

export const getOrderVid = (id, signer) => get(`/order/${id}/vid/${signer}`);

export const getOrderESignStatus = id => get(`/order/${id}/e-sign/status`);

export const getOrderInkSignStatus = id => get(`/order/${id}/ink-sign/status`);

export const getOrderFinanceStatus = id => get(`/order/${id}/finance/status`);

export const getOrderSupportingStatus = id => get(`/order/${id}/supporting/status`);

export const getESignOrderDocs = id => get(`/order/${id}/docs/e-sign`);

export const getInkSignOrderDocs = orderId => get(`/order/${orderId}/docs/ink-sign`);

export const getFinanceOrderDocs = id => get(`/order/${id}/docs/finance`);

export const getSupportingOrderDocs = id => get(`/order/${id}/docs/supporting`);

export const getOrderActivity = (id, limit) => get(`/order/${id}/activity?pageSize=${limit}`);

export const getOrderMessages = (orderId, channelId, params) => getEntityList(`/order/${orderId}/messages/${channelId}`, params);

export const getAllOrderMessages = (orderId, params) => getEntityList(`/order/${orderId}/messages`, params);

export const getChatMembers = (orderId, channelId) => get(`/order/${orderId}/messages/${channelId}/participants`);

export const muteAllChannels = orderId => put(`/order/${orderId}/messages/mute-all-channels`);

export const unmuteAllChannels = orderId => put(`/order/${orderId}/messages/unmute-all-channels`);

export const toggleChannelMute = (orderId, channelId) => put(`/order/${orderId}/messages/toggle-channel-mute/${channelId}`);

export const getChannels = orderId => get(`/order/${orderId}/messages/channels`);

export const assignScheduler = (data, orderId) => put(`/order/${orderId}/scheduler`, data);

export const awardNotaryBid = id => put(`/notary-bid/${id}/award`);

export const rejectNotaryBid = id => put(`/notary-bid/${id}/reject`);

export const notifyNotaryBid = id => put(`/notary-bid/${id}/notify`);

export const startNotaryBid = id => post(`/order/${id}/start-notary-bidding`);

export const addOrderNotaryBid = (orderId, notaryId) => post(`/order/${orderId}/notary-bid`, { notaryId });

export const getOrderNotaryBidDt = id => get(`/order/${id}/notary-bids`);

export const getOrderNotaryDt = (id, params) => getEntityList(`/order/${id}/search-notaries`, params);

export const startNotaryRequeue = (id, notaryId) => post(`/order/${id}/notary-bid`, { notaryId });

export const getOrderBiddingLogs = id => get(`/order/${id}/notary-bidding-logs`);

export const reviewOrder = (id, data) => put(`/order/${id}/review`, data);

export const markAsReadyForBilling = id => put(`/order/${id}/ready-for-billing`);

export const getOrderQbInfo = id => get(`/order/${id}/qb-accounting`);

export const saveOrderQbInfo = (id, data) => put(`/order/${id}/qb-accounting`, data);

export const uploadOrderDoc = (data, orderId, config) => post(`/order/${orderId}/doc/upload`, data, config);

export const addOrderDoc = (data, orderId) => post(`/order/${orderId}/doc/add`, data);

export const skipVid = (id, data) => put(`/order/${id}/skip-vid`, data);

export const refreshOrderStatus = id => post(`/order/${id}/refresh-status`);

export const refreshOrderFees = id => post(`/order/${id}/refresh-fees`);

export const setIsRural = (id, isRural) => put(`/order/${id}/set-rural/${isRural}`);

export const acceptAllInkSignDocs = orderId => put(`/order/${orderId}/docs/ink-sign/accept-all`);

export const updateOrderTrackingAndShipping = (data, id) => put(`/order/${id}/shipping`, data);

export const toggleRushService = orderId => put(`/order/${orderId}/toggle-rush-service`);

export const addVIDService = orderId => put(`/order/${orderId}/vid/add`);

export const removeVIDService = orderId => put(`/order/${orderId}/vid/remove`);

export const addESign = orderId => put(`/order/${orderId}/e-sign/add`);

export const removeESign = orderId => put(`/order/${orderId}/e-sign/remove`);

export const ignoreAccounting = (orderId, data) => put(`/order/${orderId}/ignore-accounting`, data);

export const removeNotary = (orderId, notaryId) => del(`/order/${orderId}/notary/${notaryId}`);

export const createOrderDealerStatement = id => post(`/order/${id}/create-dealer-statement`);

export const createOrderNotaryStatement = id => post(`/order/${id}/create-notary-statement`);

export const getNotaryOrdersDt = (id, params) => getEntityList(`/notary/${id}/orders`, params);

export const getSimilarOrders = id => get(`/order/${id}/similar-orders`);

// OrderDoc

export const getOrderDoc = id => get(`/order-doc/${id}`);

export const deleteOrderDoc = id => del(`/order-doc/${id}`);

export const reprocessOrderDoc = id => post(`/order-doc/${id}/reprocess`);

export const uploadOrderDocPdf = (id, data) => put(`/order-doc/${id}/pdf/upload`, data);

export const deleteOrderDocPdf = id => del(`/order-doc/${id}/pdf/delete`);

export const uploadOrderDocPage = (data, id, page) => post(`/order-doc/${id}/page/${page}`, data);

export const clearOrderDocPage = (id, page) => put(`/order-doc/${id}/page/${page}/clear`);

// Order Fees

export const getOrderFees = id => get(`/order/${id}/fees`);

export const getOrderAvailableFees = id => get(`/order/${id}/available-fees`);

export const updateDealerAccounting = (id, data) => put(`/order/${id}/dealer-accounting`, data);

export const updateNotaryAccounting = (id, data) => put(`/order/${id}/notary-accounting`, data);

// API TBD

export const updateQuickbookAccounting = id => put(`/order/${id}/quickbook-accounting`);

// Fedex and UPS

export const getOrderShipping = id => get(`/order/${id}/track/shipping`);

export const getOrderReturn = id => get(`/order/${id}/track/return`);

// Quickbooks

export const getQBCheckConnection = () => get('/qb/check-connection');

export const getQBInitConnection = () => get('/qb/init-connection');

export const acquireQBToken = (data) => post('/qb/acquire-token', data);

export const getQbInvoiceItems = () => get('/qb/search/invoice-item/*');

export const getQbBillItems = () => get('/qb/search/bill-item/*');

export const getQbDealers = () => get('/qb/search/customer/*');

export const getQbNotaries = () => get('/qb/search/vendor/*');

export const getQbInvoiceItem = id => get(`/qb/invoice-item/${id}`);

export const getQbBillItem = id => get(`/qb/bill-item/${id}`);

export const getQbCustomer = id => get(`/qb/customer/${id}`);

export const getQbVendor = id => get(`/qb/vendor/${id}`);

// Order Signer
export const getOrderSignerSupportCases = id => get(`/order-signer/${id}/support-cases`);

// VidRequest

export const getVidRequests = orderId => get(`/order/${orderId}/vid-requests`);

export const getVidRequest = id => get(`/vid-request/${id}`);

export const resubmitVidRequest = id => post(`/vid-request/${id}/resubmit`);

export const deleteVidRequest = id => del(`/vid-request/${id}`);

// Messages

export const getMessageDt = params => getEntityList('/messages', params);

export const createMessage = (data, config) => post('/message', data, config);

export const deleteMessage = id => del(`/message/${id}`);

// SupportCase

export const getSupportCaseDt = params => getEntityList('/support-cases', params);

export const getSupportCase = id => get(`/support-case/${id}`);

export const getTags = id => get(`/support-case/${id}/tags`);

export const createTag = (id, data) => post(`/support-case/${id}/tags`, data);

export const deleteTag = id => del(`/support-case-tag/${id}`)

export const getComments = id => get(`/support-case/${id}/comments`);

export const createComment = (id, data) => post(`/support-case/${id}/comment`, data);

export const updateComment = (id, data) => put(`/support-case-comment/${id}`, data);

export const deleteComment = id => del(`/support-case-comment/${id}`);

export const getTagList = categoryId => get(`/tags/list/${categoryId}`);

export const getSuggestedTagList = () => get('/support-case-tags/top');

export const getDeviceList = () => get('/devices/list');

export const getOsList = () => get('/os/list-simple');

export const updateSupportCase = (data, id) => put(`/support-case/${id}`, data);

export const setSupportCaseStatus = (id, data) => put(`/support-case/${id}/status`, data);

export const setSupportCasePriority = (id, data) => put(`/support-case/${id}/priority`, data);

export const getSupportCaseHandlers = () => get('/users/support-case-handlers');

export const assignSupportCase = (id, data) => put(`/support-case/${id}/assign`, data);

export const setSupportCaseInternalStatus = (id, data) => put(`/support-case/${id}/internal-status`, data);

export const getSupportCaseFiles = id => get(`/support-case/${id}/files`);

export const uploadSupportCaseFile = (id, data, config) => post(`/support-case/${id}/file`, data, config);

export const deleteSupportCaseFile = (id, fileName) => del(`/support-case/${id}/file/${fileName}`);

// Notary

export const getNotaryDt = params => getEntityList('/notaries', params);

export const getNotary = id => get(`/notary/${id}`);

export const getNotaryUserList = () => get('/notaries/list');

export const deleteNotary = id => del(`/notary/${id}`);

export const getNotaryCredentials = id => get(`/notary/${id}/credentials`);

export const deleteNotaryCredential = id => del(`/notary-credential/${id}`);

export const editNotaryCredential = (id, data, type) => put(`/notary-credential/${id}`, data, type);

export const uploadNotaryCredential = (data, notaryId) => post(`/notary-credential`, data, { notaryId });

export const updateNotaryAdditionalInfo = (data, id) => put(`/notary/${id}/additional-info`, data);

export const addNotaryRating = (id, data) => post(`/notary/${id}/rating`, data);

export const getNotaryRating = id => get(`/notary/${id}/rating`);

export const getNotaryRatings = id => get(`/notary/${id}/ratings`);

export const getMyNotaryRating = (notaryId, orderId) => get(`/notary/${notaryId}/order/${orderId}/my-rating`);

export const updateNotaryRating = (ratingId, data) => put(`/notary-rating/${ratingId}`, data);

// Report

export const getSchedulerReport = params => get('/report/scheduler-performance', { params })

export const getGroupUsageReport = params => get('/report/group-usage', { params })

export const getDealerSalesReport = params => get('/report/dealer-sales', { params })

export const getNewDealersReport = params => get('/report/new-dealers', { params })

// Dashboard

export const getCustomerPerformance = (unit, customerLocation) => get(`/dashboard/customer-performance/${unit}/${customerLocation}`);

export const getStoreLeaderboard = params => getEntityList('/dashboard/store-leaderboard', params);

export const getOrderStats = () => get('/dashboard/order-stats');

export const getNewStoresStats = () => get('/dashboard/account-manager/new-stores-stats');

export const getTotalMessages = () => get('/dashboard/total-messages');

export const getSchedulerChats = () => get('/dashboard/scheduler/recent-chats');

export const getSchedulerInProgressOrders = params => getEntityList('/dashboard/scheduler/in-progress-orders', params);

export const getAccountManagerNewStores = params => getEntityList('/dashboard/account-manager/new-stores', params);

export const getAppointments = params => getEntityList('/dashboard/appointments', params);

// Notification

export const getNotificationDt = params => getEntityList('/notifications', params);

export const readManyNotifications = data => put('/notifications/read', data);

export const unreadManyNotifications = data => put('/notifications/unread', data);

export const deleteManyNotifications = data => del('/notifications', { data });

export const getNotificationList = () => getEntityList('/notifications', { page: 1, pageSize: 10, sortBy: 'id', sortDir: 'desc', });

export const getNotification = id => get(`/notification/${id}`);

export const deleteNotification = id => del(`/notification/${id}`);

export const readNotification = id => put(`/notification/${id}/read`);

export const unreadNotification = id => put(`/notification/${id}/unread`);

// Settings

export const refreshAppSettings = () => post('/settings/refresh');

// Payments

export const getPaymentDt = params => getEntityList('/payments', params);

export const getPayment = id => get(`/payments/${id}`);

export const retryPayment = id => post(`/payments/${id}/retry`);

// Biddings

export const getBiddingDt = params => getEntityList('/biddings', params);

// CronJob

export const getCronJobs = () => get('/cron-jobs');

export const getCronJob = id => get(`/cron-job/${id}`);

// Developer Tools

export const getErrCodeList = () => get('/dev/err-codes');
export const getProcessList = () => get('/dev/sys-processes');
export const getProcessLogs = (id, channel, lines) => get(`/dev/sys-process/${id}/${channel}/${lines}`);

// Knowledgebase

export const getServicedeskArticles = (searchTerm) => get(`/knowledgebase/articles?query=${searchTerm}`);