import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Alert } from "reactstrap";
import SimpleBar from "simplebar-react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { doNotificationListCleanup, getNotificationList } from "store/actions";
import { timeAgo } from "helpers/dateHelper";
import { route, routes } from "helpers/routeHelper";
import SpinnerChase from "components/Shared/SpinnerChase";
import socketEvent from "constants/socketEvent";
import { useSocketOn } from "hooks/socket";
import { stripTags } from "helpers/utilHelper";

const NotificationDropdown = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const { notifications, notificationsError, isLoadInProgress, unreadCount } = useSelector(state => state.Notification.List);
  const noNotifications = !isLoadInProgress && !notificationsError && !notifications?.length

  const [menu, setMenu] = useState(false);

  /********** SOCKET **********/

  const onNotificationReceived = useCallback(() => {
    // both dispatch and redux actions are stable functions
    // so no need to pass as dependencies
    dispatch(getNotificationList());
  }, []);

  // listen for changes on notifications
  useSocketOn(socketEvent.notificationReceived, onNotificationReceived);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // get notifications
    dispatch(getNotificationList());
    return () => {
      // state cleanup on component unmount
      dispatch(doNotificationListCleanup());
    }
  }, []);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className={classnames('bx bx-bell', { 'bx-tada': !!unreadCount })} />
          {!!unreadCount && <span className="badge bg-danger rounded-pill">{unreadCount}</span>}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">Notifications</h6>
              </Col>
            </Row>
          </div>
          {isLoadInProgress && <SpinnerChase />}
          <SimpleBar style={{ height: "230px" }}>
            {!isLoadInProgress && notifications?.map(({ id, color, icon, subject, message, createdTs, isRead, link }, index) => {
              const stripedMessage = stripTags(message);

              return <Link key={index} to={route(routes.view_notification, id)} className="text-reset notification-item">
                <div className={`d-flex ${isRead ? "" : "unread"}`}>
                  <div className="avatar-xs me-3" style={{ minWidth: "32px" }}>
                    <span className={`avatar-title bg-${color} rounded-circle font-size-32`}>
                      <i className={icon} />
                    </span>
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="mt-0 mb-1">
                      {subject}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        {/* shorten texts longer than 114 chars (approx. 3 lines on most devices) and add an ellipsis */}
                        {stripedMessage.length < 114 ? stripedMessage : `${stripedMessage.substring(0, 114).trim()}...`}
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline me-1" />
                        {timeAgo(createdTs)}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            })}
            {noNotifications && <div className="text-reset notification-item">
              <span className="d-flex">No notifications found</span>
            </div>}
            {!isLoadInProgress && notificationsError && <Alert color="danger" className="fade show text-center mb-0">
              <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load notifications
            </Alert>}
          </SimpleBar>
          {!noNotifications && <div className="p-2 border-top d-grid">
            <Link className="btn btn-sm btn-link font-size-14 text-center" to={route(routes.list_notifications)}>
              <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">View all</span>
            </Link>
          </div>}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;