import React, { useState, useCallback, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { AccordionItem, AccordionHeader, AccordionBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from "reactstrap";
import classnames from "classnames";
import OrderDoc from "model/orderDoc";
import { getBeUrl, showError, showSuccess } from "helpers/utilHelper";
import { useSocketOn, useSubscribeToOrderDoc } from "hooks/socket";
import socketEvent from 'constants/socketEvent';
import { getOrderDocNormListItem, reprocessOrderDocNormListItem } from "store/actions";
import 'photoswipe/dist/photoswipe.css';
import { Gallery } from 'react-photoswipe-gallery';
import { formats, formatTimestamp } from "helpers/dateHelper";
import pdfIcon from 'assets/images/pdf-file.svg';
import notarizedIcon from 'assets/images/notarized-badge.svg';
import InkSignPage from "./Page";
import InkSignPageContext from "./PageContext";
import Order from "model/order";
import Confirmation from "components/Shared/Confirmation";
import { deleteOrderDocPdf, uploadOrderDocPdf } from "helpers/backendHelper";
import { perms, useAccess } from "context/access";

const InkSignDoc = ({ id, num, order }) => {

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // hooks that check permissions
  const { iAmGranted } = useAccess();

  /********** STATE **********/

  const fileInput = useRef(null);

  // get redux state from the store
  const orderDoc = useSelector(state => state.OrderDoc.NormList.orderDocs[id]);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isDocError, setIsDocError] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [isUploadPdfConfirmationVisible, setIsUploadPdfConfirmationVisible] = useState(false);
  const [isDeletePdfConfirmationVisible, setIsDeletePdfConfirmationVisible] = useState(false);
  const [isUploadingPdf, setIsUploadingPdf] = useState(false);
  const [isDeletingPdf, setIsDeletingPdf] = useState(false);

  /********** EFFECTS **********/

  // runs whenever order doc is changed
  useEffect(() => {
    // notary additional documents have 0 number of pages (no limit)
    // we need the actual number, in order to display pages
    setNumberOfPages(orderDoc.numOfPages ? orderDoc.numOfPages : orderDoc.pages ? Object.keys(orderDoc.pages).length : 0);
  }, [orderDoc]);

  /********** OTHER **********/

  const refreshOrderDoc = useCallback(() => dispatch(getOrderDocNormListItem(orderDoc.id)), [orderDoc.id]);

  /********** SOCKET **********/

  // start receiving updates about this particular document
  useSubscribeToOrderDoc(orderDoc.id);

  const onOrderDocChanged = useCallback(data => {
    // this socket client is shared by the entire app
    // and here we are listening for an event that might be triggered by multiple documents
    // therefore we need to check whether this update refers to the right document
    if (data.id == orderDoc.id) {
      refreshOrderDoc();
    }
  }, [orderDoc.id, refreshOrderDoc]);

  // listen for changes on order documents
  useSocketOn(socketEvent.orderDocChanged, onOrderDocChanged);

  /********** OTHER **********/

  const restartProcessing = () => dispatch(reprocessOrderDocNormListItem(orderDoc.id));

  /********** OTHER **********/

  const onGalleryOpen = pswp => {
    // disable gallery focus trapping
    // so the user can type in the reject reason modal
    setTimeout(function () {
      const ev = pswp.events._pool.find(e => e.type == 'focusin');
      if (ev) {
        pswp.events.remove(ev.target, ev.type, ev.listener, ev.passive);
      }
    }, 1000);
  }

  const isReady = () => orderDoc.status > OrderDoc.STATUS_PENDING_INITIAL_PROCESSING;

  const canBeDownloaded = () => orderDoc.status === OrderDoc.STATUS_COMPLETE && !isDocError;

  const canBeViewed = () => {
    if (isDocError) {
      // the file has an error so it cannot be viewed
      return false;
    }
    if (orderDoc.isCapturedAsPdf) {
      // the doc has been captured as pdf so we know there is a pdf file to view
      return true;
    }
    if (orderDoc.status === OrderDoc.STATUS_COMPLETE && !!orderDoc.numOfPages) {
      // completed documents are converted to pdf so there is a file to view
      // however the user has the option to 'accept all docs' which may include empty docs
      // in which case there is no file to view
      return true;
    }
    if (order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_UPLOAD && !orderDoc.isStdDoc && !!orderDoc.numOfPages) {
      // generally uploaded docs can be viewed
      // except for std docs (not sure why we decided this)
      // we also check the 'numOfPages' to distinguish between files uploaded by dealer (numOfPages > 0)
      // and files uploaded by notary (numOfPages = 0) which are uploaded as pages and cannot be viewed
      return true;
    }
    return false;
  }

  const canUploadPdf = () => [OrderDoc.STATUS_PENDING_CUSTOMER_SIGNATURE, OrderDoc.STATUS_PENDING_DEALER_REVIEW, OrderDoc.STATUS_REJECTED].includes(orderDoc.status) && !orderDoc.isCapturedAsPdf;

  const canDeletePdf = () => [OrderDoc.STATUS_PENDING_CUSTOMER_SIGNATURE, OrderDoc.STATUS_PENDING_DEALER_REVIEW, OrderDoc.STATUS_REJECTED].includes(orderDoc.status) && orderDoc.isCapturedAsPdf;

  const canBeReprocessed = () => iAmGranted(perms.edit_orders) && isProcessingFailed();

  const isProcessingFailed = () => orderDoc.isProcessingFailed;

  const hasDropdownMenu = () => canBeDownloaded() || canBeViewed() || canUploadPdf() || canDeletePdf() || canBeReprocessed();

  const pdfFileSelected = async e => {
    const formData = new FormData();
    formData.append("pdfFile", e.target.files[0]);
    setIsUploadingPdf(true);
    try {
      await uploadOrderDocPdf(orderDoc.id, formData);
      refreshOrderDoc();
      showSuccess('The pdf file has been uploaded');
    } catch (err) {
      showError("Unable to upload pdf file");
    } finally {
      setIsUploadingPdf(false);
    }
  }

  const removePdfFile = async () => {
    setIsDeletingPdf(true);
    try {
      await deleteOrderDocPdf(orderDoc.id);
      refreshOrderDoc();
      showSuccess('The pdf file has been deleted');
    } catch (err) {
      showError("Unable to delete pdf file");
    } finally {
      setIsDeletingPdf(false);
    }
  }

  return orderDoc && <AccordionItem>
    <AccordionHeader targetId={'doc' + orderDoc.id} className={classnames('bg-soft', `bg-${OrderDoc.getStatusColor(orderDoc.status)}`)}>
      <div className="doc-list-col doc-no" title={'Id: ' + orderDoc.id}>{num}</div>
      <div className="doc-list-col doc-name">
        <img src={pdfIcon} className="ms-n1 me-1" />
        {orderDoc.name}
        {!!orderDoc.isNotarizationRequired && <span className="notarized-badge ms-3">
          to be notarized <img src={notarizedIcon} className='ms-2' alt='notarized-icon' />
        </span>}
        {(isUploadingPdf || isDeletingPdf) && <i className="mdi mdi-spin mdi-loading ms-1" />}
        {orderDoc.isCapturedAsPdf && <i className="mdi mdi-file-pdf-outline ms-2 captured-as-pdf-icon" title="Document was captured as pdf"></i>}
      </div>
      <div className="doc-list-col doc-status">
        <span className={classnames(`badge badge-lg rounded-pill ps-3 pe-3 bg-${OrderDoc.getStatusColor(orderDoc.status)}`)}>{orderDoc.statusName}</span>
      </div>
      <div className="doc-list-col doc-up-by">{orderDoc.creatorFullName}</div>
      <div className="doc-list-col doc-up-date">{formatTimestamp(orderDoc.createdTs, formats.US_DATE)}</div>
      <div className="doc-list-col doc-pages">{numberOfPages}</div>
      <div className="doc-list-col doc-sign-date">{orderDoc.signedTs ? formatTimestamp(orderDoc.signedTs, formats.US_DATE) : '--'}</div>
      <div className="doc-list-col doc-actions">
        {/* we need e.stopPropagation() to prevent toggling the accordion item when toggling the dropdown menu */}
        {hasDropdownMenu() && <Dropdown isOpen={menuIsOpen} toggle={e => { e.stopPropagation(); setMenuIsOpen(!menuIsOpen) }} className="btn-group">
          {/* we need tag="a" to prevent this error: Warning: validateDOMNesting(...): <button> cannot appear as a descendant of <button> */}
          <DropdownToggle tag="a" className="btn btn-default dropdown-toggle">
            <i className="bx bx-dots-horizontal-rounded" />
          </DropdownToggle>
          <DropdownMenu end>
            {orderDoc.isWithdrawn ? <React.Fragment>
              {/* Preview/download links of order documents become unavailable if isWithdrawn is true */}
              {canBeDownloaded() && <><DropdownItem tag="span" id='download-doc'>Download</DropdownItem><UncontrolledTooltip placement="top" target="download-doc">Document no longer available.</UncontrolledTooltip></>}
              {isReady() && canBeViewed() && <><DropdownItem tag="span" id="view-doc">View</DropdownItem><UncontrolledTooltip placement="top" target="view-doc">Document no longer available.</UncontrolledTooltip></>}
            </React.Fragment> :
              <React.Fragment>
                {canBeDownloaded() && <DropdownItem tag="a" href={getBeUrl(`order-doc/${orderDoc.id}/pdf/download`)} target="_blank">Download</DropdownItem>}
                {isReady() && canBeViewed() && <DropdownItem tag="a" href={getBeUrl(`order-doc/${orderDoc.id}/pdf`)} target="_blank">View</DropdownItem>}
              </React.Fragment>}

            {canUploadPdf() && <React.Fragment>
              <DropdownItem tag="a" onClick={() => setIsUploadPdfConfirmationVisible(true)} >Upload Pdf</DropdownItem>
              <input type="file" accept="application/pdf" onChange={pdfFileSelected} className="d-none" ref={fileInput} />
            </React.Fragment>}
            {canDeletePdf() && <DropdownItem tag="a" onClick={() => setIsDeletePdfConfirmationVisible(true)} >Delete Pdf</DropdownItem>}
            {canBeReprocessed() && <DropdownItem tag="a" onClick={() => restartProcessing()} disabled={orderDoc.isReprocessInProgress}>Reprocess</DropdownItem>}
          </DropdownMenu>
        </Dropdown>
        }
      </div>
    </AccordionHeader>
    <AccordionBody accordionId={'doc' + orderDoc.id} className={classnames('bg-soft mt-2', `bg-${OrderDoc.getStatusColor(orderDoc.status)}`)}>
      {!orderDoc.isCapturedAsPdf && <div className="ink-sign-pages pt-2">
        <Gallery options={{ mainClass: 'ink-sign-gallery' }} onOpen={onGalleryOpen}>
          {[...Array(numberOfPages).keys()].map(n => <InkSignPageContext key={n} docId={orderDoc.id} pageNum={n + 1} refreshDocHandler={refreshOrderDoc}>
            <InkSignPage onImageError={() => setIsDocError(true)} />
          </InkSignPageContext>)}
        </Gallery>
      </div>}
    </AccordionBody>
    {isUploadPdfConfirmationVisible && <Confirmation
      confirmBtnText="Upload"
      onConfirm={() => {
        setIsUploadPdfConfirmationVisible(false);
        fileInput.current.click();
      }}
      onCancel={() => {
        setIsUploadPdfConfirmationVisible(false);
      }}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to upload the full document &quot;{orderDoc.name}&quot; as a pdf file? All scanned pages of this document will be removed!</span>
    </Confirmation>}
    {isDeletePdfConfirmationVisible && <Confirmation
      confirmBtnText="Delete"
      onConfirm={() => {
        setIsDeletePdfConfirmationVisible(false);
        removePdfFile();
      }}
      onCancel={() => {
        setIsDeletePdfConfirmationVisible(false);
      }}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to delete the pdf file for document &quot;{orderDoc.name}&quot;?</span>
    </Confirmation>}
  </AccordionItem>
}

InkSignDoc.propTypes = {
  id: PropTypes.number.isRequired,
  num: PropTypes.number.isRequired,
  order: PropTypes.object.isRequired,
  refreshListHandler: PropTypes.func.isRequired,
};

export default InkSignDoc;
