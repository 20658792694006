import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col, CardHeader, CardTitle, Table } from "reactstrap";
import CardPreloader from "components/Shared/CardPreloader";
import { getYesNoOptions, showError } from "helpers/utilHelper";
import Credential from "model/notaryCredential";
import { getNotaryCredentials } from "helpers/backendHelper";

const SectionCredentialsDetails = (props) => {

  const { notary, isLoadInProgress } = props

  const [credentials, setCredentials] = useState([]);

  const commissionCertificate = credentials.find(c => c.type === Credential.TYPE_COMMISSION_CERTIFICATE)

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    refreshCredentials();
  }, []);

  /********** OTHER **********/

  /**
   * Fetches credential doc list from API
   */
  const refreshCredentials = () => {
    // make the initial remote call to get the credentials data
    getNotaryCredentials(notary.id)
      .then(response => {
        setCredentials(response.credentials);
      })
      .catch(ex => {
        showError("Unable to get credentials")
      })
  };

  return (<Card className="expand-v">
    <CardHeader className="bg-transparent pt-3 pb-0">
      <Row>
        <Col>
          <CardTitle>Additional Credential Details</CardTitle>
        </Col>
      </Row>
    </CardHeader>
    <CardBody>
      <Table className="section-rows table bt-1">
        <tbody>
          <tr>
            <td className="section-row-label">Can You Print Digital Docs</td>
            <td className="section-row-value ps-4">{getYesNoOptions().find(o => o.value == notary.canPrintDigitalDocs)?.label || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label">EDocs</td>
            <td className="section-row-value ps-4">{getYesNoOptions().find(o => o.value == notary.canSignElectronically)?.label || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label">Commission State</td>
            <td className="section-row-value ps-4">{commissionCertificate?.commissionState || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label">Commission in another state?</td>
            <td className="section-row-value ps-4">{commissionCertificate?.commissionState2 || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label no-border">Counties Covered</td>
            <td className="section-row-value ps-4 no-border">{commissionCertificate?.countiesCovered || "---"}</td>
          </tr>
        </tbody>
      </Table>
    </CardBody>
    {isLoadInProgress && <CardPreloader />}
  </Card>)
}

SectionCredentialsDetails.propTypes = {
  notary: PropTypes.object,
  isLoadInProgress: PropTypes.bool
};

export default SectionCredentialsDetails;