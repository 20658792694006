import React, { useEffect } from "react";
import { Col, Card, CardBody, Row, CardHeader, CardTitle, Alert, CardFooter, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SpinnerChase from "components/Shared/SpinnerChase";
import { doSchedulerChatsCleanup, getSchedulerChats } from "store/actions";
import { route, routes } from "helpers/routeHelper";
import { getInitialsFromName } from "helpers/utilHelper"
import UserAvatar from "components/Shared/UserAvatar";
import Message from "model/message";

const PendingMessages = () => {

  const dispatch = useDispatch();

  const { chats, isLoadInProgress, chatsError } = useSelector(state => state.Dashboard.SchedulerChats);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    dispatch(getSchedulerChats());

    return () => {
      // state cleanup on component unmount
      dispatch(doSchedulerChatsCleanup());
    }
  }, []);

  return <>
    <Card className="messages-card">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <CardTitle>Pending Messages</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="messages-table pt-1">
        <table className="table">
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Customer Name</th>
              <th>Message</th>
              <th>Channel</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {chats?.length > 0 && !isLoadInProgress && !chatsError && chats?.map((entry, index) => (<tr key={index}>
              <td>
                <Link to={route(routes.view_order, entry.orderId)}>{entry.orderId}</Link>
              </td>
              <td>{entry.customerName}</td>
              <td>{entry.content}</td>
              <td>{<UserAvatar initials={getInitialsFromName(Message.getChannelName(entry.channel))} size="sm" className="mt-n2" />}</td>
              <td>
                <Link to={route(routes.view_order_messages, [entry.orderId, entry.channel])}>
                  <span className={`badge badge-lg rounded-pill bg-primary`}>Go to chats</span>
                </Link>
              </td>
            </tr>))}
            {!isLoadInProgress && !chatsError && chats?.length === 0 && <tr>
              <td className="table-empty" colSpan="4">No chats found</td>
            </tr>}
            {isLoadInProgress && <tr>
              <td className="table-empty" colSpan="4">
                <SpinnerChase />
              </td>
            </tr>}
            {chatsError && <tr>
              <td className="table-empty" colSpan="4">
                <Alert color="danger" className="fade show text-center mb-0">
                  <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load chats
                </Alert>
              </td>
            </tr>}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="bg-transparent d-flex justify-content-end pt-0">
        <a href={route(routes.list_messages)}>
          <Button color="light" className="text-primary mt-4">
            View All <i className="bx bx-right-arrow-alt" />
          </Button>
        </a>
      </CardFooter>
    </Card>
  </>
}

export default PendingMessages