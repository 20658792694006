export default class Message {

  static CHANNEL_DEALER_CUSTOMER = 1;
  static CHANNEL_SUPPORT_DEALER = 2;
  static CHANNEL_SUPPORT_DEALER_CUSTOMER = 3;
  static CHANNEL_SCHEDULER_CUSTOMER = 4;
  static CHANNEL_SCHEDULER_NOTARY = 5;
  static CHANNEL_NOTARY_CUSTOMER = 6;
  static CHANNEL_MY_NOTES = 7;
  static CHANNEL_INTERNAL_NOTES = 8;
  static CHANNEL_SCHEDULER_DEALER = 9;

  static CHANNEL_MEMBER_TYPE_USER = 1;
  static CHANNEL_MEMBER_TYPE_SIGNER = 2;

  static CONTENT_TYPE_TEXT = 1
  static CONTENT_TYPE_IMAGE = 2

  // in order to extend the current channels with a new channel
  // we need to add it to the getChannelMap method
  // and edit the permissions for it in the src/context/access.js file
  static getChannelMap() {
    return {
      [this.CHANNEL_DEALER_CUSTOMER]: 'Dealer - Customer',
      [this.CHANNEL_SUPPORT_DEALER]: 'Tech Support - Dealer',
      [this.CHANNEL_SUPPORT_DEALER_CUSTOMER]: 'Tech Support - Dealer - Customer',
      [this.CHANNEL_SCHEDULER_CUSTOMER]: 'Scheduler - Customer',
      [this.CHANNEL_SCHEDULER_NOTARY]: 'Scheduler - Notary',
      [this.CHANNEL_NOTARY_CUSTOMER]: 'Notary - Customer',
      [this.CHANNEL_MY_NOTES]: 'My Notes',
      [this.CHANNEL_INTERNAL_NOTES]: 'Internal Notes',
      [this.CHANNEL_SCHEDULER_DEALER]: 'Scheduler - Dealer',
    };
  }

  static getChannelName(id) {
    return this.getChannelMap()[id];
  }
}