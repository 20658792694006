import {
  GET_CUSTOMER_PERFORMANCE,
  GET_CUSTOMER_PERFORMANCE_ERR,
  GET_CUSTOMER_PERFORMANCE_OK,
  DO_CUSTOMER_PERFORMANCE_CLEANUP,

  GET_LEADERBOARD,
  GET_LEADERBOARD_OK,
  GET_LEADERBOARD_ERR,
  DO_LEADERBOARD_CLEANUP,

  GET_LEADERBOARD_LIST,
  GET_LEADERBOARD_LIST_OK,
  GET_LEADERBOARD_LIST_ERR,
  DO_LEADERBOARD_LIST_CLEANUP,

  GET_SUPPORT_STATS,
  GET_SUPPORT_STATS_OK,
  GET_SUPPORT_STATS_ERR,
  DO_SUPPORT_STATS_CLEANUP,

  GET_SCHEDULER_CHATS,
  GET_SCHEDULER_CHATS_OK,
  GET_SCHEDULER_CHATS_ERR,
  DO_SCHEDULER_CHATS_CLEANUP,

  GET_SCHEDULER_PROGRESS_ORDERS,
  GET_SCHEDULER_PROGRESS_ORDERS_OK,
  GET_SCHEDULER_PROGRESS_ORDERS_ERR,
  DO_SCHEDULER_PROGRESS_ORDERS_CLEANUP,

  GET_APPOINTMENTS_LIST,
  GET_APPOINTMENTS_LIST_OK,
  GET_APPOINTMENTS_LIST_ERR,
  DO_APPOINTMENTS_LIST_CLEANUP,

} from "./actionTypes";


/********** CUSTOMER PERFORMANCE **********/

export const getCustomerPerformance = (unit, customerLocation) => ({
  type: GET_CUSTOMER_PERFORMANCE,
  payload: { unit, customerLocation },
});

export const getCustomerPerformanceOk = response => ({
  type: GET_CUSTOMER_PERFORMANCE_OK,
  payload: { response },
});

export const getCustomerPerformanceErr = error => ({
  type: GET_CUSTOMER_PERFORMANCE_ERR,
  payload: { error },
});

export const doCustomerPerformanceCleanup = () => ({
  type: DO_CUSTOMER_PERFORMANCE_CLEANUP,
});

/********** LEADERBOARD **********/

export const getLeaderboard = params => ({
  type: GET_LEADERBOARD,
  payload: { params },
});

export const getLeaderboardOk = response => ({
  type: GET_LEADERBOARD_OK,
  payload: { response },
});

export const getLeaderboardErr = error => ({
  type: GET_LEADERBOARD_ERR,
  payload: { error },
});

export const doLeaderboardCleanup = () => ({
  type: DO_LEADERBOARD_CLEANUP,
});

/********** LEADERBOARD LIST **********/

export const getLeaderboardList = params => ({
  type: GET_LEADERBOARD_LIST,
  payload: { params },
});

export const getLeaderboardListOk = response => ({
  type: GET_LEADERBOARD_LIST_OK,
  payload: { response },
});

export const getLeaderboardListErr = error => ({
  type: GET_LEADERBOARD_LIST_ERR,
  payload: { error },
});

export const doLeaderboardListCleanup = () => ({
  type: DO_LEADERBOARD_LIST_CLEANUP,
});

/********** ORDER STATS **********/

export const getSupportStats = () => ({
  type: GET_SUPPORT_STATS,
});

export const getSupportStatsOk = response => ({
  type: GET_SUPPORT_STATS_OK,
  payload: { response },
});

export const getSupportStatsErr = error => ({
  type: GET_SUPPORT_STATS_ERR,
  payload: { error },
});

export const doSupportStatsCleanup = () => ({
  type: DO_SUPPORT_STATS_CLEANUP,
});

/********** SCHEDULER CHATS **********/

export const getSchedulerChats = () => ({
  type: GET_SCHEDULER_CHATS,
});

export const getSchedulerChatsOk = response => ({
  type: GET_SCHEDULER_CHATS_OK,
  payload: { response },
});

export const getSchedulerChatsErr = error => ({
  type: GET_SCHEDULER_CHATS_ERR,
  payload: { error },
});

export const doSchedulerChatsCleanup = () => ({
  type: DO_SCHEDULER_CHATS_CLEANUP,
});

/********** SCHEDULER IN PROGRESS ORDERS **********/

export const getSchedulerProgressOrders = params => ({
  type: GET_SCHEDULER_PROGRESS_ORDERS,
  payload: { params }
});

export const getSchedulerProgressOrdersOk = response => ({
  type: GET_SCHEDULER_PROGRESS_ORDERS_OK,
  payload: { response },
});

export const getSchedulerProgressOrdersErr = error => ({
  type: GET_SCHEDULER_PROGRESS_ORDERS_ERR,
  payload: { error },
});

export const doSchedulerProgressOrdersCleanup = () => ({
  type: DO_SCHEDULER_PROGRESS_ORDERS_CLEANUP
})

/********** APPOINTMENTS LIST **********/

export const getAppointmentsList = params => ({
  type: GET_APPOINTMENTS_LIST,
  payload: { params }
});

export const getAppointmentsListOk = response => ({
  type: GET_APPOINTMENTS_LIST_OK,
  payload: { response },
});

export const getAppointmentsListErr = error => ({
  type: GET_APPOINTMENTS_LIST_ERR,
  payload: { error },
});

export const doAppointmentsListCleanup = () => ({
  type: DO_APPOINTMENTS_LIST_CLEANUP
})
