import {

  GET_CRON_JOB_LIST,
  GET_CRON_JOB_LIST_OK,
  GET_CRON_JOB_LIST_ERR,
  DO_CRON_JOB_LIST_CLEANUP,

} from './actionTypes';

/********* LIST *********/

export const getCronJobList = () => ({
  type: GET_CRON_JOB_LIST,
});

export const getCronJobListOk = response => ({
  type: GET_CRON_JOB_LIST_OK,
  payload: { response },
});

export const getCronJobListErr = error => ({
  type: GET_CRON_JOB_LIST_ERR,
  payload: { error },
});

export const doCronJobListCleanup = () => ({
  type: DO_CRON_JOB_LIST_CLEANUP,
});