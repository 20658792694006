import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Card, CardBody, Row, Col, Button, CardHeader, Form, Input, FormFeedback } from "reactstrap";
import { ValidationException } from "helpers/errorHelper";
import { nullsToEmptyStrings, showBriefError, showError, showSuccess } from "helpers/utilHelper";
import Confirmation from "components/Shared/Confirmation";
import { updatePaymentPlanFee } from "helpers/backendHelper";

const FormNew = props => {

	const { defaultValues, id, finishedHandler } = props;

	/********** STATE **********/

		// is the confirmation dialog visible or not
		// used to show/hide the save warning
	const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
	const [isSaveInProgress, setIsSaveInProgress] = useState(false);

	// holds form values after validation and until the user accepts the confirmation
	const [tempFormValues, setTempFormValues] = useState();

	/********** FORM CONFIG **********/

	const formInitialValues = {
		price: '',
		...nullsToEmptyStrings(defaultValues),
	};

	const formik = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,
		initialValues: formInitialValues,
		validationSchema: Yup.object({
			price: Yup.number().required('Field is required').min(0, 'Must be greater than or equal to 0'),
		}),
		onSubmit: values => {
			setTempFormValues(values);
			setIsConfirmationVisible(true);
		},
	});

	/********** EFFECTS **********/

	// runs whenever the validation fails
	useEffect(() => {
		if (!formik.isValid) {
			showBriefError('Form has errors');
		}
	}, [formik.isValid]);

	/********** EVENT HANDLERS **********/

		// focus event handler
		// used to clear field errors
	const onFieldFocused = (e, fieldName) => {
			const name = fieldName || e.target.name;
			const errors = formik.errors;
			delete errors[name];
			formik.setStatus(errors);
		}

	const saveCustomFee = () => {
		setIsSaveInProgress(true);
		updatePaymentPlanFee(tempFormValues, id)
			.then(response => {
				showSuccess(`Additional Fee "${tempFormValues.additionalFeeName}" has been saved`);
				finishedHandler(true);
			})
			.catch(ex => {
				showError('Unable to save additional fee');
				// see if the save failed due to validation
				if (ex instanceof ValidationException) {
					// show an error on each invalid field
					for (const [name, message] of Object.entries(ex.fields)) {
						formik.setFieldError(name, message);
					}
				}
				// enable the save button
				formik.setSubmitting(false);
			})
			.finally(() => {
				setIsSaveInProgress(false);
			});
	}

	return <React.Fragment>
		<Card>
			<CardHeader className="bg-transparent pt-3">
				<Row>
					<Col>
						<div className="text-end">
							<Button type="button" color="primary" className="mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
								{isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
								{!isSaveInProgress && <i className="mdi mdi-check me-1" />}
								Save fee
							</Button>
							<Button type="button" color="secondary" className="ms-2 mb-2" onClick={finishedHandler}>
								<i className="mdi mdi-chevron-left me-1" />Cancel
							</Button>
						</div>
					</Col>
				</Row>
			</CardHeader>
			<CardBody>
				<Row>
					<Col>
						<Form>
							<Row className="align-items-center">
								<Col sm={4}>
									<h5>{defaultValues.additionalFeeName}</h5>
								</Col>
								<Col sm={4}>
									<Input type="number" min={0} className="form-control" placeholder="ex. 20.50" name="price" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.price} invalid={!!formik.errors.price} />
									{!!formik.errors.price && <FormFeedback type="invalid">{formik.errors.price}</FormFeedback>}
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
			</CardBody>
		</Card>
		{isConfirmationVisible && <Confirmation
			confirmBtnText="Save"
			onConfirm={() => {
				setIsConfirmationVisible(false)
				saveCustomFee();
				setTempFormValues(null);
			}}
			onCancel={() => {
				setIsConfirmationVisible(false);
				setTempFormValues(null);
				formik.setSubmitting(false);
			}}>
			<span style={{ color: '#556EE6' }}>Are you sure you want to save &quot;{formik.values.additionalFeeName}&quot;?</span>
		</Confirmation>}
	</React.Fragment>
}

FormNew.propTypes = {
	defaultValues: PropTypes.object,
	finishedHandler: PropTypes.func,
	id: PropTypes.number,
};

export default FormNew;