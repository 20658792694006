import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import SpinnerChase from "components/Shared/SpinnerChase";
import { Link, useParams, } from "react-router-dom"
import { Card, CardBody, Row, Col, Alert, Button, UncontrolledTooltip } from "reactstrap"
import { getMiles, getSharedTableOptions, showError, showSuccess, showWarning } from "helpers/utilHelper"
import {
  awardNotaryBid,
  declineNotaryBid,
  notifyNotaryBid,
  doOrderNotaryBidDtCleanup,
  startNotaryRequeue,
  getOrderNotaryBidDt,
  doNotaryBidCleanup
} from "store/actions";
import { route, routes } from "helpers/routeHelper";
import Confirmation from "components/Shared/Confirmation";
import { ServerErrorException, UNABLE_SEND_ASSIGNED_ORDER_NOTIF } from "helpers/errorHelper";
import classnames from "classnames";
import NotaryBid from "model/notaryBid";
import CountdownTimer from "components/Shared/CountdownTimer";
import config from "config";
import RequeueButton from "components/Shared/RequeueButton";
import assignNotaryIcon from "assets/images/assign-notary-icon.svg";

const DataTableNotaryBids = () => {

  let { id } = useParams();
  id = parseInt(id);

  const dispatch = useDispatch();

  /********** STATE **********/

  const { order } = useSelector(state => state.Order.Single);
  const { notaryBids: rows, notaryBidsError: rowsError, isLoadInProgress } = useSelector(state => state.Order.NotaryBidDt);
  const { awarded, awardError, declined, declineError } = useSelector(state => state.Order.NotaryBid);

  const [bidToAward, setBidToAward] = useState(null);
  const [bidToDecline, setBidToDecline] = useState(null);
  const [bidToNotify, setBidToNotify] = useState(null);
  const [isAwardConfirmationVisible, setIsAwardConfirmationVisible] = useState(false);
  const [isDeclineConfirmationVisible, setIsDeclineConfirmationVisible] = useState(false);
  const [isNotifyConfirmationVisible, setIsNotifyConfirmationVisible] = useState(false);
  const [isRowExpired, setIsRowExpired] = useState(false);

  const isNotarySearchExhausted = order.isNotarySearchExhausted ?
    'No matching notaries found.' :
    <div id='search-in-progress'>
      <span>No notaries to display</span>
    </div>;

  /********** EFFECTS **********/
  // runs once on component mount
  useEffect(() => {
    refreshNotaryBids();
    // runs on component unmount
    return () => {
      dispatch(doOrderNotaryBidDtCleanup());
      dispatch(doNotaryBidCleanup());
    }
  }, []);

  // Refresh isExpired
  useEffect(() => {
    if (isRowExpired) {
      refreshNotaryBids();
      setIsRowExpired(false);
    }
  }, [isRowExpired, setIsRowExpired]);

  useEffect(() => {
    if (awarded === true) {
      showSuccess("Notary has been assigned");
    } else if (awarded === false) {
      if (awardError instanceof ServerErrorException) {
        if (awardError.code == UNABLE_SEND_ASSIGNED_ORDER_NOTIF) {
          // user has been assigned but the notifications could not be sent (at least some of them)
          showWarning("Unable to send notifications");
          return;
        }
      }
      showError("Unable to assign notary");
      setBidToAward(null);
    }
  }, [awarded]);

  useEffect(() => {
    if (declined === true) {
      showSuccess("Notary has been declined");
    } else if (declined === false) {
      if (declineError instanceof ServerErrorException) {
        if (declineError.code == UNABLE_SEND_ASSIGNED_ORDER_NOTIF) {
          // user has been assigned but the notifications could not be sent (at least some of them)
          showWarning("Unable to send notifications");
          return;
        }
      }
      showError("Unable to decline notary");
      setBidToAward(null);
    }
  }, [declined]);

  /********** EVENT HANDLERS **********/

  const confirmAwardBid = bid => {
    setBidToAward(bid);
    setIsAwardConfirmationVisible(true);
  }

  const confirmDeclineBid = bid => {
    setBidToDecline(bid);
    setIsDeclineConfirmationVisible(true);
  }

  const confirmNotifyBid = bid => {
    setBidToNotify(bid);
    setIsNotifyConfirmationVisible(true);
  }

  const awardBid = () => {
    setIsAwardConfirmationVisible(false);
    dispatch(awardNotaryBid(bidToAward.id));
  }

  const declineBid = () => {
    setIsDeclineConfirmationVisible(false);
    dispatch(declineNotaryBid(bidToDecline.id));
  }

  const notifyBid = () => {
    setIsNotifyConfirmationVisible(false);
    dispatch(notifyNotaryBid(bidToNotify.id));
  }

  const requeueBid = user => {
    dispatch(startNotaryRequeue(id, user.notaryId));
  }

  const rowClasses = (row) => {
    if (!row.isExpired) {
      if (row.status === NotaryBid.STATUS_REJECTED_BY_SCHEDULER ||
        row.status === NotaryBid.STATUS_DECLINED_BY_NOTARY ||
        row.status === NotaryBid.STATUS_SELECTED) return '';

      return 'notary-bid-highlight-row';
    } else {
      if (row.status === NotaryBid.STATUS_ACCEPTED_BY_NOTARY ||
        row.status === NotaryBid.STATUS_AWARDED_BY_SCHEDULER) return 'notary-bid-highlight-row';
    }
    return '';
  };

  /********** OTHER **********/

  const refreshNotaryBids = () => dispatch(getOrderNotaryBidDt(order.id));

  return <React.Fragment>
    <Card className="paginated-table-card">
      <CardBody className="pt-3">
        <ToolkitProvider
          keyField="id"
          columns={columns(confirmAwardBid, confirmDeclineBid, confirmNotifyBid, requeueBid, setIsRowExpired)}
          data={rows}
        >
          {toolkitProps => (
            <React.Fragment>
              <Row>
                <Col>
                  <div className="table-responsive">
                    <BootstrapTable
                      {...getSharedTableOptions()}
                      headerWrapperClasses="thead-light hide-expand-all"
                      noDataIndication={!rowsError && isNotarySearchExhausted}
                      expandRow={expandRow}
                      {...toolkitProps.baseProps}
                      rowClasses={rowClasses}
                    />
                  </div>
                  {isLoadInProgress && <SpinnerChase className="sm dtable" />}
                  {!!rowsError && <Alert color="danger" className="fade show text-center">
                    <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load notary bids
                  </Alert>}
                </Col>
              </Row>
            </React.Fragment>
          )}
        </ToolkitProvider>
      </CardBody>
    </Card>
    {isAwardConfirmationVisible && <Confirmation
      confirmBtnText="Assign"
      success
      confirmBtnBsStyle="success"
      style={{ backgroundColor: '#ffffff' }}
      reverseButtons={false}
      onConfirm={() => awardBid()}
      onCancel={() => {
        setBidToAward(null);
        setIsAwardConfirmationVisible(false);
      }}>
      Are you sure you want to assign notary &quot;{bidToAward.notaryUserFullName}&quot; to order #{order.id}?
    </Confirmation>}
    {isDeclineConfirmationVisible && <Confirmation
      confirmBtnText="Decline"
      onConfirm={() => declineBid()}
      onCancel={() => {
        setBidToDecline(null);
        setIsDeclineConfirmationVisible(false);
      }}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to decline notary &quot;{bidToDecline.notaryUserFullName}&quot; to order #{order.id}?</span>
    </Confirmation>}
    {isNotifyConfirmationVisible && <Confirmation
      confirmBtnText="Notify"
      success
      confirmBtnBsStyle="success"
      style={{ backgroundColor: '#ffffff' }}
      reverseButtons={false}
      onConfirm={() => notifyBid()}
      onCancel={() => {
        setBidToNotify(null);
        setIsNotifyConfirmationVisible(false);
      }}>
      Are you sure you want to notify notary &quot;{bidToNotify.notaryUserFullName}&quot; to order #{order.id}?
    </Confirmation>}
  </React.Fragment>
}

const handleSwitch = (row, confirmAwardFC, confirmDeclineFC, confirmNotifyFC) => {
  switch (row.status) {
    case NotaryBid.STATUS_SELECTED: return <div className="d-flex align-items-center">
      <Button type="button" color="link" className="p-0 ms-2" onClick={() => confirmNotifyFC(row)}>
        <i id='scheduled-icon' className="mdi mdi-email-send text-success font-size-19" />
        <UncontrolledTooltip placement="top" target="scheduled-icon">Notify the notary</UncontrolledTooltip>
      </Button>
      <Button type="button" color="link" className="p-0 ms-2 btn btn-link" onClick={() => confirmDeclineFC(row)}>
        <i id='reject-icon' className="mdi mdi-delete delete-btn text-danger font-size-19" />
        <UncontrolledTooltip placement="top" target="reject-icon">Reject the notary</UncontrolledTooltip>
      </Button>
    </div>
    case NotaryBid.STATUS_NOTIFIED:
      return <div className="d-flex align-items-center">
        <i id="pending-icon" className="mdi mdi-account-clock text-warning font-size-19 button-status-p" />
        <UncontrolledTooltip placement="top" target="pending-icon">Waiting for notary action</UncontrolledTooltip>
        <Button type="button" color="link" className="p-0 ms-2" onClick={() => confirmDeclineFC(row)}>
          <i id='reject-icon' className="mdi mdi-delete delete-btn text-danger font-size-19" />
          <UncontrolledTooltip placement="top" target="reject-icon">Reject the notary</UncontrolledTooltip>
        </Button>
      </div>
    case NotaryBid.STATUS_ACCEPTED_BY_NOTARY:
      return <>
        <Button type="button" color="link" className="me-2 m-0" onClick={() => confirmAwardFC(row)}>
          <img id='accepted-icon' alt="assign-notary" src={assignNotaryIcon} />
          <UncontrolledTooltip placement="top" target="accepted-icon">Accepted by notary. Click to award.</UncontrolledTooltip>
        </Button>
        <Button type="button" color="link" className="p-0 ms-2 btn btn-link" onClick={() => confirmDeclineFC(row)}>
          <i id='reject-icon' className="mdi mdi-delete delete-btn text-danger font-size-19" />
          <UncontrolledTooltip placement="top" target="reject-icon">Reject the notary</UncontrolledTooltip>
        </Button>
      </>
    case NotaryBid.STATUS_DECLINED_BY_NOTARY:
    case NotaryBid.STATUS_AWARDED_BY_SCHEDULER:
      return <span className='button-status-p'>--</span>
  }
}

const getBidActionButtons = (row, confirmAwardFC, confirmDeclineFC, confirmNotifyFC, valForTimeUntilExpire, requeueBidFC) => {
  const canRequeue = (row.status == NotaryBid.STATUS_NOTIFIED && row.isExpired)
    || [NotaryBid.STATUS_REJECTED_BY_SCHEDULER, NotaryBid.STATUS_DECLINED_BY_NOTARY, NotaryBid.STATUS_NOT_AWARDED, NotaryBid.STATUS_CLOSED_BY_AWARD].includes(row.status);
  if (canRequeue) {
    return <div className='button-status-p'>
      <RequeueButton targetDate={valForTimeUntilExpire} handleRequeue={() => requeueBidFC(row)} />
    </div>
  }
  return handleSwitch(row, confirmAwardFC, confirmDeclineFC, confirmNotifyFC);
}

const columns = (confirmAwardBid, confirmDeclineBid, confirmNotifyBid, requeueBid, setIsRowExpired) => [{
  // this column is not used but we need it for the default sorting to work
  // if dataField does not match a defined column, it will be ignored
  dataField: "id",
  sort: true,
  hidden: true,
}, {
  dataField: "notifiedTs",
  text: "Timer",
  sort: false,
  classes: "timer-cell",
  // eslint-disable-next-line react/display-name
  formatter: (notifiedTs, row) => {
    const notaryBidExpiry = +config.NOTARY_BID_EXPIRY;
    const timeUntilExpire = (notifiedTs + notaryBidExpiry) * 1000;

    if (row.status === NotaryBid.STATUS_AWARDED_BY_SCHEDULER) return <>
      <i id="awarded-icon" className="mdi mdi-check-decagram text-success font-size-24" />
      <UncontrolledTooltip placement="top" target="awarded-icon">Awarded</UncontrolledTooltip>
    </>

    if (row.status === NotaryBid.STATUS_DECLINED_BY_NOTARY) return <>
      <i id="notary-declined-icon" className="mdi mdi-alert-decagram font-size-24 text-danger" />
      <UncontrolledTooltip placement="top" target="notary-declined-icon">Declined by notary</UncontrolledTooltip>
    </>

    if (row.status === NotaryBid.STATUS_REJECTED_BY_SCHEDULER) return <>
      <i id="scheduler-declined-icon" className="mdi mdi-alert-decagram font-size-24 text-danger" />
      <UncontrolledTooltip placement="top" target="scheduler-declined-icon">Rejected by scheduler</UncontrolledTooltip>
    </>

    if (row.isExpired && row.status === NotaryBid.STATUS_NOTIFIED) return <>
      <i id="expired-icon" className="mdi mdi-clock-alert text-secondary font-size-20" />
      <UncontrolledTooltip placement="top" target="expired-icon">Expired</UncontrolledTooltip>
    </>

    if (row.status === NotaryBid.STATUS_ACCEPTED_BY_NOTARY) return <>
      <i id='awaiting-scheduler' className="mdi mdi-comment-account-outline text-success font-size-19" />
      <UncontrolledTooltip placement="top" target="awaiting-scheduler">Awaiting scheduler confirmation</UncontrolledTooltip>
    </>

    if (row.status === NotaryBid.STATUS_NOTIFIED) return <>
      <CountdownTimer targetDate={timeUntilExpire} stateChanger={setIsRowExpired} />
    </>

    return <i className="bx bxs-time-five text-secondary font-size-20"></i>
  }
}, {
  dataField: "notaryUserFullName",
  text: "Name",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => <Link to={route(routes.view_notary, row.notaryId)}>{cellContent}</Link>
}, {
  dataField: "initialRating",
  text: "Mav1 Rating",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: cellContent => (cellContent ? <>{cellContent} <i className="mdi mdi-star-outline mb-2 rating-star text-warning" /></> : "--")
}, {
  dataField: "notaryRating",
  text: "Rating",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: cellContent => (cellContent ? <>{cellContent} <i className="mdi mdi-star-outline mb-2 rating-star text-warning" /></> : "--")
}, {
  dataField: "notaryTypeName",
  text: "Type",
  sort: false,
}, {
  dataField: "countiesCovered",
  text: "Coverage Area",
  sort: false,
  classes: "additional-details-cell",
  // eslint-disable-next-line react/display-name
  formatter: cellContent => cellContent || "--"
}, {
  dataField: "state",
  text: "State",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: cellContent => cellContent || "--"
}, {
  dataField: "distance",
  text: "Distance",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: cellContent => cellContent ? `${getMiles(cellContent)} miles` : "--"
}, {
  dataField: "scheduleAlert",
  text: "Additional Details",
  sort: false,
  classes: "additional-details-cell",
  // eslint-disable-next-line react/display-name
  formatter: cellContent => cellContent || "--"
}, {
  dataField: "comments",
  text: "Notary Comments",
  classes: "additional-details-cell",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: cellContent => cellContent || "--"
}, {
  dataField: "dummy2",
  text: "Availability",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => {
    if (!row.availability) {
      return '--';
    }
    let availabilityName = [];
    for (const availability of row.availability) {
      availabilityName.push(NotaryBid.getAvailabilityName(availability));
    }
    return availabilityName.join(', ')
  }
}, {
  dataField: "dummy1",
  text: "Actions",
  classes: "actions-cell",
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row, notifiedTs) => {
    const notaryBidExpiry = +config.NOTARY_BID_EXPIRY;
    const timeUntilExpire = (notifiedTs + notaryBidExpiry) * 1000;

    return getBidActionButtons(row, confirmAwardBid, confirmDeclineBid, confirmNotifyBid, timeUntilExpire, requeueBid);
  }
},];

const expandRow = {
  showExpandColumn: true,
  expandColumnPosition: "right",
  expandByColumnOnly: true,
  parentClassName: "expanded-dt-row",
  // eslint-disable-next-line react/display-name, react/prop-types
  expandColumnRenderer: ({ expanded }) => (
    <button
      className={classnames(
        "accordion-button",
        "w-auto",
        "fw-medium",
        { collapsed: !expanded }
      )}
      type="button"
    />
  ),
  // eslint-disable-next-line react/display-name
  renderer: (row) => (
    <div className="d-flex justify-content-around p-3">
      <div>
        <span className="me-2">
          <strong>Rating:</strong>
        </span>
        {row.notaryRating ? <span>
          {row.notaryRating} <i className="mdi mdi-star-outline text-warning font-size-16 ms-1" />
        </span> : "--"}
      </div>
      <div className="vertical-divider" />
      <div>
        <span className="me-2">
          <strong>Phone No:</strong>
        </span>
        {row.phone}
      </div>
      <div className="vertical-divider" />
      <div>
        <span className="me-2">
          <strong>Email:</strong>
        </span>
        {row.notaryEmail}
      </div>
    </div>
  ),
};

export default DataTableNotaryBids;
