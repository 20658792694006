import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import Confirmation from "components/Shared/Confirmation";
import { formats, formatTimestamp } from "helpers/dateHelper";
import { perms, useAccess } from "context/access";

const Comment = ({ comment, removeComment }) => {

  const { iAmGranted } = useAccess();

  // toggle open/close dropdown menu
  const [moreMenu, setMoreMenu] = useState(false);

  // is the confirmation dialog visible or not
  // used to show/hide the delete warning
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  /********** HANDLERS **********/

  // toggles the dropdown menu on and off
  const toggleMore = () => {
    setMoreMenu(current => !current);
  };

  // delete-group event handler
  // make the remote call to delete the comment
  const handleDeleteComment = id => removeComment(id);

  return <div className="py-2">
    <div className="d-flex justify-content-between">
      <div><strong>{comment.userFullName}</strong>, {formatTimestamp(comment.createdTs, formats.DATETIME)}</div>
      {iAmGranted(perms.delete_support_case_comments) && (
        <Dropdown isOpen={moreMenu} toggle={toggleMore}>
          <DropdownToggle className="btn nav-btn pt-0" tag="i">
            <i className="bx bx-dots-horizontal-rounded" />
          </DropdownToggle>
          <DropdownMenu end>
            <DropdownItem onClick={() => { setIsConfirmationVisible(true) }}>Delete</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}
    </div>
    <div>{comment.content}</div>
    {isConfirmationVisible && <Confirmation
      confirmBtnText="Delete"
      onConfirm={() => {
        setIsConfirmationVisible(false);
        handleDeleteComment(comment.id);
      }}
      onCancel={() => setIsConfirmationVisible(false)}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to delete the comment?</span>
    </Confirmation>}
  </div>
}

Comment.propTypes = {
  comment: PropTypes.object,
  removeComment: PropTypes.func,
  refreshHandler: PropTypes.func
}

export default Comment;