import React, { useEffect } from "react";
import SpinnerChase from "components/Shared/SpinnerChase";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import AccessDenied from "pages/Error/AccessDenied";
import { Link, } from "react-router-dom"
import { Card, CardBody, Alert, Container, Row, Col } from "reactstrap"
import { doLeaderboardListCleanup, getLeaderboardList } from "store/actions";
import { route, routes } from "helpers/routeHelper";
import { perms, useAccess } from "context/access";
import { useDispatch, useSelector } from "react-redux";

const List = () => {

  const { iAmGranted, iAmNotGranted } = useAccess();

  const dispatch = useDispatch();

  /********** STATE **********/

  const { leaderboard: rows, leaderboardError: rowsError, isLoadInProgress } = useSelector(state => state.Dashboard.LeaderboardList);

  /********** EFFECTS **********/

  // runs on component mount
  useEffect(() => {
    return () => {
      // state cleanup on component unmount
      dispatch(doLeaderboardListCleanup());
    }
  }, []);

  // runs once on component mount
  useEffect(() => {
    dispatch(getLeaderboardList({ limit: null }));
  }, []);

  return <div className="page-content">
    <MetaTitle>Leaderboard</MetaTitle>
    {iAmGranted(perms.view_leaderboard_list) && <Container fluid>
      <Breadcrumbs title="LEADERBOARD" />
      <Row>
        <Col>
          <Card>
            <CardBody className="pt-3">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Dealer region</th>
                    <th>Dealer group</th>
                    <th>Orders</th>
                  </tr>
                </thead>
                <tbody>
                  {rows?.map((entry, index) => (<tr key={index}>
                    <td>{index + 1}</td>
                    <td><Link to={route(routes.view_dealer_store, entry.id)}>{entry.name}</Link></td>
                    <td><Link to={route(routes.view_dealer_region, entry.dealerRegionId)}>{entry.dealerRegionName}</Link></td>
                    <td><Link to={route(routes.view_dealer_group, entry.dealerGroupId)}>{entry.dealerGroupName}</Link></td>
                    <td>{(entry.orders).toLocaleString()}</td>
                  </tr>))}
                  {rows.length === 0 && !rowsError && !isLoadInProgress && <tr>
                    <td className="table-empty" colSpan="4">No stores found</td>
                  </tr>
                  }
                  {!!rowsError && <tr>
                    <td className="table-empty" colSpan="7">
                      <Alert color="danger" className="fade show text-center mb-0">
                        <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load stores
                      </Alert>
                    </td>
                  </tr>}
                  {isLoadInProgress && <tr>
                    <td className="table-empty" colSpan="7">
                      <SpinnerChase className="sm" />
                    </td>
                  </tr>}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>}
    {iAmNotGranted(perms.view_leaderboard_list) && <AccessDenied />}
  </div>
}

export default List;
