import React from "react";
import PropTypes from "prop-types";
import Statement from "model/statement";
import { UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";

const StoreInformation = ({ statement }) => {
  const { dealerStoreName, dealerStoreAddress, dealerStoreCity, dealerStoreState, dealerStoreZip,
    dealerStoreContactName, dealerStoreContactEmail, dealerGroupName, dealerRegionName, dealerStoreQbCustomerId } = statement;

  const isNotaryStatement = statement.type === Statement.TYPE_DEALER_SINGLE_ORDER;

  return !!statement && <React.Fragment>
    <div className='mb-3'>
      <strong>Store Information</strong>
      {!dealerStoreQbCustomerId && <React.Fragment>
        <i id="customer-warning" className="mdi mdi-alert-circle text-danger ms-2"></i>
        <UncontrolledTooltip placement="top" target="customer-warning">This store is not attached to a QuickBooks customer</UncontrolledTooltip>
      </React.Fragment>}
    </div>
    <div className='font-weight-semibold'>QuickBooks Customer</div>
    <div className='mb-2'>
      {dealerStoreQbCustomerId} -
      <Link to={route(routes.view_dealer_store, statement.dealerStoreId)}> {dealerStoreName || '--'}</Link>
    </div>

    <div className='font-weight-semibold'>Store Address</div>
    <div>{dealerStoreAddress}</div>
    <div className='mb-2'>{`${dealerStoreCity || '--'}, ${dealerStoreState || '--'} ${dealerStoreZip || '--'}`}</div>

    <div className='font-weight-semibold'>Billing Contact</div>
    <div className='mb-2'>{dealerStoreContactName || '--'}</div>

    <div className='font-weight-semibold'>Billing Email</div>
    <div className='mb-2'>{dealerStoreContactEmail || '--'}</div>

    {isNotaryStatement && <>
      <div className='font-weight-semibold'>Region</div>
      <div className='mb-2'>{dealerRegionName || '--'}</div>

      <div className='font-weight-semibold'>Group</div>
      <div className='mb-2'>{dealerGroupName || '--'}</div>
    </>}
  </React.Fragment>
}

StoreInformation.propTypes = {
  statement: PropTypes.object,
};

export default StoreInformation;