import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Label, Input, FormFeedback, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import { useFormik } from "formik";
import { hasNonEmpty, extractDtFiltersFromUrl, dictionaryToSelectOptions, toSelectOptions, getActiveOptions, getYesNoOptions } from "helpers/utilHelper";
import { applyNotaryDtFilters, clearNotaryDtFilters, patchNotaryDtFilters, unsetNotaryDtFilters } from 'store/actions';
import { isEmpty, omit } from 'lodash';
import Notary from 'model/notary';
import Select from 'react-select';
import UsStates from "constants/usState";

const Filters = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const filters = useSelector(state => state.Notary.DtFilters);
  // is the filters form visible or not
  // used to show/hide the filters form
  const [isVisible, setIsVisible] = useState(false);

  /********** FORM CONFIG **********/

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: filters,
    onSubmit: values => applyFilters(values),
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // extract filters from url
    // they will be applied by default as the user enters the screen
    const filters = extractDtFiltersFromUrl();
    // if url filters are not empty
    if (!isEmpty(filters)) {
      // in this case, replace the old filters with the relevant ones
      applyFilters(filters);
    } else {
      // make sure to call this even if there are no filters in the url
      // because we need to switch the '_set' flag
      dispatch(patchNotaryDtFilters(filters));
    }
    return () => {
      // switch back the '_set' flag
      dispatch(unsetNotaryDtFilters());
    }
  }, []);

  /********** EVENT HANDLERS **********/

  // shows/hides the filters form
  const toggleFilters = () => setIsVisible(!isVisible);

  // event handler for the 'apply-filters' button
  const applyFilters = values => dispatch(applyNotaryDtFilters(values));

  // event handler for the 'clear-filters' button
  const clearFilters = () => {
    // reset form fields
    formik.setValues(formik.initialValues);
    // reset state
    dispatch(clearNotaryDtFilters());
  }

  const setTypeOptions = (typeList) => {
    const selectedValues = typeList.map((item) => item.value);
    formik.setFieldValue("type", selectedValues);
  }

  // load state filters into local filters
  // state filters = applied filters that are send to backend
  // local filters = state vars bound to form controls
  // this is fired each time the offcanvas is opened
  // to discard anything the user might have typed in the fields (and not applied) before closing the offcanvas
  const initLocalFilters = () => formik.setValues(filters);

  /********** OTHER **********/

  const hasFilters = () => hasNonEmpty(omit(filters, "_set"));

  const getTypeOptions = () => dictionaryToSelectOptions(Notary.getTypeMap());

  const stateOptions = toSelectOptions(UsStates);

  return <React.Fragment>
    <div className="btn-group ms-2 mb-2" >
      <Button type="button" color="dark" onClick={toggleFilters}>
        <i className="mdi mdi-filter-variant me-1" />Filters
      </Button>
      {hasFilters() && <Button type="button" color="dark" onClick={clearFilters}>
        <i className="mdi mdi-close" />
      </Button>}
    </div>
    <Offcanvas direction="end" isOpen={isVisible} toggle={toggleFilters} onOpened={initLocalFilters}>
      <OffcanvasHeader toggle={toggleFilters}>Filters</OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className="mb-3">
            <Label>Name</Label>
            <Input type="text" className="form-control" name="name" onChange={formik.handleChange} value={formik.values.name} invalid={!!formik.errors.name} />
            {!!formik.errors.name && <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Email</Label>
            <Input type="text" className="form-control" name="email" onChange={formik.handleChange} value={formik.values.email} invalid={!!formik.errors.email} />
            {!!formik.errors.email && <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>}
          </div>
          <div className="mt-2 mb-3">
            <Label>Type</Label>
            <Select
              classNamePrefix="select2-selection"
              name="type"
              isMulti
              options={getTypeOptions()}
              onChange={selected => setTypeOptions(selected)}
              value={getTypeOptions().filter(option => formik.values.type.includes(option.value))}
            />
          </div>
          <div className="mb-3">
            <Label>State</Label>
            <Select
              classNamePrefix="select2-selection"
              name="state"
              options={stateOptions}
              onChange={selected => formik.setFieldValue('state', selected.value)}
              value={stateOptions.find(option => option.value === formik.values.state)}
              className={!!formik.errors.state && 'is-invalid'} />
            {!!formik.errors.state && <FormFeedback type="invalid">{formik.errors.state}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>City</Label>
            <Input type="text" className="form-control" name="city" onChange={formik.handleChange} value={formik.values.city} invalid={!!formik.errors.city} />
            {!!formik.errors.city && <FormFeedback type="invalid">{formik.errors.city}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Zip</Label>
            <Input type="text" className="form-control" name="zip" onChange={formik.handleChange} value={formik.values.zip} invalid={!!formik.errors.zip} />
            {!!formik.errors.zip && <FormFeedback type="invalid">{formik.errors.zip}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Status</Label>
            <Select
              classNamePrefix="select2-selection an-simple-select"
              name="status"
              options={getActiveOptions()}
              onChange={selected => formik.setFieldValue("status", selected.value)}
              value={getActiveOptions().find(option => option.value == formik.values.status)}
              className={!!formik.errors.status && 'is-invalid'} />
          </div>
          <div className="mb-3">
            <Label>QB id</Label>
            <Input type="text" className="form-control" name="qbId" onChange={formik.handleChange} value={formik.values.qbId} invalid={!!formik.errors.qbId} />
            {!!formik.errors.qbId && <FormFeedback type="invalid">{formik.errors.qbId}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Expired Credentials</Label>
            <Select
              classNamePrefix="select2-selection"
              name="expiredNotaryCredential"
              options={getYesNoOptions(true)}
              onChange={selected => formik.setFieldValue('expiredNotaryCredential', selected.value)}
              value={getYesNoOptions(true).find(option => option.value === formik.values.expiredNotaryCredential)}
              className={!!formik.errors.expiredNotaryCredential && 'is-invalid'} />
            {!!formik.errors.expiredNotaryCredential && <FormFeedback type="invalid">{formik.errors.expiredNotaryCredential}</FormFeedback>}
          </div>
          <div className="text-end">
            <Button type="button" color="primary" className="me-2" onClick={formik.handleSubmit}>
              <i className="mdi mdi-filter me-1" />Apply
            </Button>
            <Button type="button" color="warning" onClick={clearFilters}>
              <i className="mdi mdi-eraser me-1" />Clear
            </Button>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  </React.Fragment>
}

export default Filters;