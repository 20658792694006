import React, { useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { route, routes } from "helpers/routeHelper";
import MetaTitle from "components/Shared/MetaTitle";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import Preloader from "components/Shared/Preloader";
import Error from "pages/Error";
import AccessDenied from "pages/Error/AccessDenied";
import { perms, useAccess } from "context/access";
import { doOrderSingleCleanup, getOrderWithCustomerSigners } from "store/actions";
import VidInformation from "../Partial/Seal/VidInformation";
import ESignInformation from "../Partial/Seal/ESignInformation";
import InkSignInformation from "../Partial/Seal/InkSignInformation";
import CustomerInfo from "../Partial/Seal/CustomerInfo";
import ESignDocs from "../Partial/Seal/ESignDocs";
import InkSignDocs from "../Partial/Seal/InkSignDocs";
import Order from "model/order";
import { getBeUrl, orderIsLocked } from "helpers/utilHelper";
import classnames from "classnames";
import SealBadge from "../Partial/Seal/Badge";
import { useSocketOn, useSubscribeToOrder } from "hooks/socket";
import socketEvent from "constants/socketEvent";
import SupportingInformation from "../Partial/Seal/SupportingInformation";

const Seal = () => {

  let { id } = useParams();
  id = parseInt(id);

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  // get redux state from the store
  const { order, orderError, isLoadInProgress } = useSelector(state => state.Order.Single);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the order data
    refreshOrder();
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderSingleCleanup());
    }
  }, [id]);

  /********** OTHER **********/

  const refreshOrder = useCallback(() => dispatch(getOrderWithCustomerSigners(id)), [id]);

  /********** SOCKET **********/

  // start receiving updates about this particular document
  useSubscribeToOrder(order?.id);

  const onOrderChanged = useCallback(data => {
    // this socket client is shared by the entire app
    // and here we are listening for an event that might be triggered by multiple orders
    // therefore we need to check whether this update refers to the right order
    if (data.id == order.id) {
      refreshOrder();
    }
  }, [order?.id, refreshOrder]);

  const condition = useCallback(() => !!order?.id, [order?.id]);

  // listen for changes on order documents
  useSocketOn(socketEvent.orderChanged, onOrderChanged, condition);

  /********** OTHER **********/

  const isLocked = () => orderIsLocked(order.sealStatus);

  const isReadyForSealing = () => order.status == Order.STATUS_COMPLETED && !isLocked();

  const isQueuedForSealing = () => order.sealStatus == Order.SEAL_STATUS_QUEUED;

  const isSealed = () => order.sealStatus == Order.SEAL_STATUS_SEALED;

  const sealFailed = () => order.sealStatus == Order.SEAL_STATUS_FAILED;

  const isAnySealStage = () => isReadyForSealing() || isQueuedForSealing() || sealFailed() || isSealed();

  const getSealExportLink = () => getBeUrl(`/order/${order.id}/seal/view`);

  const downloadSealFile = () => getBeUrl(`/order/${order.id}/seal/download`);

  return <React.Fragment>
    {iAmGranted(perms.view_orders) && <div className="page-content">
      {order && isAnySealStage() && <React.Fragment>
        <MetaTitle>#{order.id} | Orders</MetaTitle>
        <Container fluid>
          <Row>
            <Col>
              <Breadcrumbs breadcrumbItems={breadcrumbs(order)} />
            </Col>
            <SealBadge order={order} />
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody className="order-seal-body">
                  <div className="text-end align-self-end">
                    {iAmGranted(perms.export_order_seal) && isSealed() && <a href={getSealExportLink()} target="_blank" className={classnames('btn btn-success ms-3', { disabled: order.sealStatus != Order.SEAL_STATUS_SEALED })} rel="noreferrer">Preview<i className="mdi mdi-export ms-1"></i></a>}
                    {iAmGranted(perms.export_order_seal) && isSealed() && <a href={downloadSealFile()} target="_blank" className={classnames('btn btn-primary ms-3', { disabled: order.sealStatus != Order.SEAL_STATUS_SEALED })} rel="noreferrer">Download<i className="mdi mdi-download ms-1"></i></a>}
                  </div>
                  <CustomerInfo order={order} />
                  {order.isVidRequired &&
                    order?.signers?.map((signer, index) =>
                      <VidInformation orderId={order.id} signerNum={signer.position} key={signer.position} order={order} />
                    )}
                  {order.isEsignRequired &&
                    <ESignInformation orderId={order.id} />
                  }
                  {order.isInkSignRequired &&
                    <InkSignInformation orderId={order.id} />
                  }
                  {!!(order.isEsignRequired || order.isInkSignRequired) && <>
                    <SupportingInformation orderId={order.id} />
                  </>
                  }
                  {order.isEsignRequired &&
                    <ESignDocs orderId={order.id} />
                  }
                  {order.isInkSignRequired &&
                    <InkSignDocs orderId={order.id} />
                  }
                  <div className="text-end align-self-end mt-4">
                    {iAmGranted(perms.export_order_seal) && isSealed() && <a href={getSealExportLink()} target="_blank" className={classnames('btn btn-success ms-3', { disabled: order.sealStatus != Order.SEAL_STATUS_SEALED })} rel="noreferrer">Preview<i className="mdi mdi-export ms-1"></i></a>}
                    {iAmGranted(perms.export_order_seal) && isSealed() && <a href={downloadSealFile()} target="_blank" className={classnames('btn btn-primary ms-3', { disabled: order.sealStatus != Order.SEAL_STATUS_SEALED })} rel="noreferrer">Download<i className="mdi mdi-download ms-1"></i></a>}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !order && <Preloader className="inner" />}
      {orderError && <Error error={orderError} title404="Order not found" />}
    </div>}
    {iAmNotGranted(perms.view_order_sealed_doc) || (order && !isAnySealStage()) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = order => [{
  title: `${order.signers[0].fullName}`,
  url: route(routes.view_order, order.id),
}, {
  title: 'Seal Deal',
}];

export default Seal;
