import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { Card, CardBody, Row, CardHeader, Button, CardTitle, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from "reactstrap";
import Col from "components/Shared/Col";
import { perms, useAccess } from 'context/access';
import CardPreloader from 'components/Shared/CardPreloader';
import Confirmation from 'components/Shared/Confirmation';
import { showError, showSuccess } from 'helpers/utilHelper';
import { getPaymentPlan, extendDealerStoreFreeTrial, getQbCustomer } from "helpers/backendHelper";
import { NotFoundException, QB_UNABLE_REFRESH_TOKEN } from 'helpers/errorHelper';
import { route, routes } from 'helpers/routeHelper';
import DealerStore from 'model/dealerStore';

const getPayWithCheckLabel = canPayWithCheck => canPayWithCheck ? "Enabled" : "Disabled";

const ViewBilling = props => {

  const { isRefreshing, dealerStore, toggleEditMode, triggerRefresh } = props;

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  /********** STATE **********/

  // is the confirmation dialog visible or not
  // used to show/hide the extend-free-trial warning
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isFreeTrialExtInProgress, setIsFreeTrialExtInProgress] = useState(false);
  const [paymentPlan, setPaymentPlan] = useState(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const [qbCustomer, setQbCustomer] = useState(null);
  const [qbCustomerError, setQbCustomerError] = useState(null);
  const [isQbCustomerLoadInProgress, setIsQbCustomerLoadInProgress] = useState(false);

  /********** EFFECTS **********/

  useEffect(() => {
    refreshPaymentPlan();
    if (!!dealerStore.qbCustomerId) {
      refreshQbCustomer();
    } else {
      setQbCustomerError('Please add a customer');
    }
  }, [dealerStore]);

  /********** OTHER **********/

  const extendFreeTrial = () => {
    setIsFreeTrialExtInProgress(true);
    extendDealerStoreFreeTrial(dealerStore.id)
      .then(response => {
        showSuccess('Free trial has been extended');
        triggerRefresh();
      })
      .catch(ex => {
        showError('Unable to extend free trial');
      })
      .finally(() => {
        setIsFreeTrialExtInProgress(false);
      })
  }

  const refreshPaymentPlan = () => {
    getPaymentPlan(dealerStore.paymentPlanId)
      .then(response => {
        setPaymentPlan(response.paymentPlan)
      })
      .catch(ex => {
        showError('Unable to get dealer plan');
      })
  };

  const refreshQbCustomer = () => {
    setIsQbCustomerLoadInProgress(true);
    getQbCustomer(dealerStore.qbCustomerId)
      .then(response => {
        setQbCustomer(response);
      })
      .catch(ex => {
        if (ex.code == QB_UNABLE_REFRESH_TOKEN) {
          showError('Cannot re-establish QB connection');
          return;
        }
        if (ex instanceof NotFoundException) {
          setQbCustomerError('Customer not found');
          return;
        }
        setQbCustomerError('Unable to load customer');
      })
      .finally(() => {
        setIsQbCustomerLoadInProgress(false);
      });
  }

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <CardTitle>Billing</CardTitle>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              {iAmGranted(perms.edit_dealer_stores) && <Button type="button" color="primary" className="mb-2" onClick={toggleEditMode}>
                <i className="mdi mdi-pencil me-1" />Edit
              </Button>}
              {iAmGranted(perms.extend_free_trials) && paymentPlan?.isFreeTrial && <Dropdown isOpen={menuIsOpen} toggle={() => setMenuIsOpen(!menuIsOpen)} className="d-inline-block align-middle mb-2">
                <DropdownToggle tag="button" className="btn btn-default card-menu-btn">
                  <i className="bx bx-dots-horizontal-rounded" />
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem onClick={() => setIsConfirmationVisible(true)} disabled={isFreeTrialExtInProgress}>Extend free trial</DropdownItem>
                </DropdownMenu>
              </Dropdown>}
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs="6">
            <Row className="mb-3">
              <label className="mb-0">Billing contact</label>
              <div>{dealerStore.billingName || '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Account number</label>
              <div>{dealerStore.billingAccountNo || '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">QuickBooks Customer</label>
              <div>
                {!!dealerStore.qbCustomerId && dealerStore.qbCustomerId}
                {!!qbCustomer && ` - ${qbCustomer.DisplayName}`}
                {isQbCustomerLoadInProgress && <i className="mdi mdi-spin mdi-loading ms-2" />}
                {!!qbCustomerError && <React.Fragment>
                  <i id="invoice-item-warning" className="mdi mdi-alert-circle text-danger ms-1"></i>
                  <UncontrolledTooltip placement="top" target="invoice-item-warning">{qbCustomerError}</UncontrolledTooltip>
                </React.Fragment>}
              </div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Payment plan</label>
              <div>
                <Link to={route(routes.view_payment_plan, dealerStore.paymentPlanId)}>{dealerStore.paymentPlanName}</Link>
              </div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Payment plan days</label>
              <div>{dealerStore.maxPaymentPlanDays || '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Check Payments</label>
              <div>{getPayWithCheckLabel(dealerStore.dealerGroupCanPayWithCheck || dealerStore.canPayWithCheck)}</div>
            </Row>
          </Col>
          <Col xs="6">
            <Row className="mb-3">
              <label className="mb-0">Email</label>
              <div>{dealerStore.billingEmail ? dealerStore.billingEmail.join(', ') : '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Phone</label>
              <div>{dealerStore.billingPhone || '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Alternate phone</label>
              <div>{dealerStore.billingPhone2 || '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Payment method</label>
              <div>{dealerStore.paymentMethodType ? DealerStore.getPaymentMethodName(dealerStore.paymentMethodType) : '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Payment plan orders</label>
              <div>{dealerStore.maxPaymentPlanOrders || '--'}</div>
            </Row>
            {dealerStore.usesCoupa &&
              <div>
                <Row className="mb-3">
                  <label className="mb-0">Coupa Name (Accounting name)</label>
                  <div>{dealerStore.coupaName || '--'}</div>
                </Row>
                <Row className="mb-3">
                  <label className="mb-0">Coupa Account (Default account)</label>
                  <div>{dealerStore.coupaAccount || '--'}</div>
                </Row>
                <Row className="mb-3">
                  <label className="mb-0">Hyperion number (Store ID)</label>
                  <div>{dealerStore.coupaStoreId || '--'}</div>
                </Row>
              </div>
            }
          </Col>
        </Row>
      </CardBody>
      {isRefreshing && <CardPreloader />}
    </Card>
    {isConfirmationVisible && <Confirmation
      confirmBtnText="Extend"
      onConfirm={() => {
        setIsConfirmationVisible(false);
        extendFreeTrial();
      }}
      onCancel={() => setIsConfirmationVisible(false)}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to extend free trial for dealer store &quot;{dealerStore.name}&quot;?</span>
    </Confirmation>}
  </React.Fragment>
}

ViewBilling.propTypes = {
  isRefreshing: PropTypes.bool,
  dealerStore: PropTypes.object,
  toggleEditMode: PropTypes.func,
  triggerRefresh: PropTypes.func,
};

export default ViewBilling;