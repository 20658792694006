import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { Row, Col, Button, Form, Label, Input, FormFeedback, CardTitle, Card, CardBody, textarea } from "reactstrap";
import Select from "react-select";
import TextareaAutosize from "react-textarea-autosize";
import { nullsToEmptyStrings, showBriefError, showError, showSuccess, getYesNoOptions, getActiveOptions } from "helpers/utilHelper";
import { ValidationException } from "helpers/errorHelper";
import classnames from "classnames";
import * as Yup from "yup";
import { useAuth } from "context/auth";
import { dictionaryToSelectOptions } from "helpers/utilHelper";
import Notary from "model/notary";
import { updateNotaryAdditionalInfo } from "helpers/backendHelper";

const FormEditAdditionalInfo = props => {

  const { id, finishedHandler, notary } = props;

  // get user info
  const { user } = useAuth();

  /********** STATE **********/

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  // this is the default form validation schema
  // we store this shema in state because it is meant to be a dynamic schema
  // which we will to update conditionally
  const [validationSchema, setValidationSchema] = useState({
    isActive: Yup.boolean(),
    isVerified: Yup.boolean(),
  });

  /********** FORM CONFIG **********/

  const formInitialValues = {
    scheduleAlert: "",
    hasScheduleAlert: !!notary.scheduleAlert,
    type: Notary.TYPE_UNAPPROVED,
    ...nullsToEmptyStrings(notary),
    internalComments: notary.internalComments || '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => saveNotaryAdditionalInfo({ ...values, verifierId: values.isVerified !== notary.isVerified ? user.id : null }, id),
  });

  // validation rules for the 'schedulerAlert' field
  // this will be added dynamically to the form validation schema
  const schedulerAlertValidation = Yup.string().trim().required('Field is required');

  /********** EFFECTS **********/

  // runs whenever the validation fails
  useEffect(() => {
    if (!formik.isValid) {
      showBriefError("Form has errors");
    }
  }, [formik.isValid]);

  // runs whenever the "hasScheduleAlert" flag changes
  useEffect(() => {
    // reset the comments field
    if (!formik.values.hasScheduleAlert) {
      formik.setFieldValue("scheduleAlert", "");
    }
    setValidationSchema(schema => ({
      ...schema,
      scheduleAlert: formik.values.hasScheduleAlert ? schedulerAlertValidation : null,
    }));
  }, [formik.values.hasScheduleAlert]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  /********** OTHER **********/

  const saveNotaryAdditionalInfo = (values, id) => {
    setIsSaveInProgress(true);
    updateNotaryAdditionalInfo(values, id)
      .then(response => {
        showSuccess(`Notary "${values.userFullName}" has been saved`);
        finishedHandler(true);
      })
      .catch(ex => {
        showError("Unable to save notary");
        // see if the save failed due to validation
        if (ex instanceof ValidationException) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(ex.fields)) {
            formik.setFieldError(name, message);
          }
        }
        // enable the save button
        formik.setSubmitting(false);
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  return <Card className="expand-v">
    <CardBody>
      <div className="pb-4">
        <Row>
          <Col>
            <CardTitle>Additional Info</CardTitle>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              <Button type="button" color="primary" className="ms-2 mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
                {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
                Save
              </Button>
              <Button type="button" color="secondary" className="ms-2 mb-2" onClick={finishedHandler}>
                <i className="mdi mdi-chevron-left me-1" />
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          <Form>
            <Row className="mb-4">
              <Label className="col-sm-4 col-form-label">Status</Label>
              <Col sm={8} className="pt-2">
                <Select
                  classNamePrefix="select2-selection an-simple-select"
                  name="isActive"
                  options={getActiveOptions()}
                  onChange={selected => formik.setFieldValue("isActive", selected ? selected.value : false)}
                  value={getActiveOptions().find(option => option.value == formik.values.isActive)}
                  className={!!formik.errors.isActive && 'is-invalid'} />
              </Col>
            </Row>
            <Row className="mb-4">
              <Label className="col-sm-4 col-form-label">Notary Type</Label>
              <Col sm={8} className="pt-2">
                <Select
                  classNamePrefix="select2-selection an-simple-select"
                  name="type"
                  options={notaryTypeOptions}
                  onChange={selected => formik.setFieldValue("type", selected.value)}
                  value={notaryTypeOptions.find(option => option.value == formik.values.type)}
                  className={!!formik.errors.type && 'is-invalid'} />
              </Col>
            </Row>
            <Row className="mb-4">
              <Label className="col-sm-4 col-form-label">Verified Account</Label>
              <Col sm={8} className="pt-2">
                <Select
                  classNamePrefix="select2-selection an-simple-select"
                  name="isVerified"
                  options={getYesNoOptions()}
                  onChange={selected => formik.setFieldValue('isVerified', !!selected.value)}
                  value={getYesNoOptions().find(option => option.value == formik.values.isVerified)}
                  className={!!formik.errors.isVerified && 'is-invalid'} />
              </Col>
            </Row>
            <Row className="mb-4">
              <Label className="col-sm-4 col-form-label">Schedule Alert</Label>
              <Col sm={8} className="pt-2">
                <Select
                  classNamePrefix="select2-selection an-simple-select"
                  name="hasScheduleAlert"
                  options={getYesNoOptions()}
                  onChange={selected => formik.setFieldValue('hasScheduleAlert', selected.value)}
                  value={getYesNoOptions().find(option => option.value == formik.values.hasScheduleAlert)}
                  className={!!formik.errors.hasScheduleAlert && 'is-invalid'} />
              </Col>
            </Row>
            {!!formik.values.hasScheduleAlert && <Row className="mb-4">
              <Label className="col-sm-4 col-form-label">Alert Comments *</Label>
              <Col sm={8}>
                <TextareaAutosize maxRows={15} className={classnames("form-control", { "is-invalid": !!formik.errors.scheduleAlert })} name="scheduleAlert" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.scheduleAlert} />
                {!!formik.errors.scheduleAlert && <FormFeedback type="invalid">{formik.errors.scheduleAlert}</FormFeedback>}
              </Col>
            </Row>}
            <Row className="mb-4">
              <Label className="col-sm-4 col-form-label">Internal Comments</Label>
              <Col sm={8} className="pt-2">
                <textarea type="text" className="form-control" placeholder="Your message..." name="internalComments" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.internalComments} invalid={(!!formik.errors.internalComments).toString()} />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </CardBody>
  </Card>
}

FormEditAdditionalInfo.propTypes = {
  id: PropTypes.number,
  finishedHandler: PropTypes.func,
  notary: PropTypes.object
};

const notaryTypeOptions = [...dictionaryToSelectOptions(Notary.getTypeMap())];

export default FormEditAdditionalInfo;