import React, { useEffect, useState } from "react";
import { Alert, Button } from "reactstrap"
import PropTypes from "prop-types";
import { getServicedeskArticles } from "helpers/backendHelper";
import SpinnerChase from "components/Shared/SpinnerChase";
import { useDebounce } from "hooks/debounce";

const HelpSidebarContent = ({ searchTerm }) => {

  const [articles, setArticles] = useState([]);
  const [articlesError, setArticlesError] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(false);
  const [isLoadInProgress, setIsLoadInProgress] = useState(true);

  // Debounce the value of the search
  const debouncedAddress = useDebounce(searchTerm, 500);

  useEffect(() => {
    getServicedeskArticles(searchTerm).then(res => {
      setArticles(res.articles?.values)
      setArticlesError(false)
      setSelectedArticle(null);
    }).catch(err => {
      setArticlesError(true)
    })
  }, [debouncedAddress]);

  const resetHelpContent = () => {
    setSelectedArticle(null)
    setIsLoadInProgress(true)
  }

  return (
    <React.Fragment>
      <div id="help_sidebar_list">
        {!articlesError && !selectedArticle &&
          <>{!!articles && !!articles.length ? <>
            <div className="help-title mb-4">Recommended for you</div>
            {articles.map((article, index) => {
              return <div key={index} className="article-item">
                <Button color="none" className="article-title" onClick={() => setSelectedArticle(article)}>{article.title}</Button>
                <i className="mdi mdi-chevron-right font-size-16" />
              </div>
            })}
          </>
            : <div>No articles found</div>
          }</>
        }
        {selectedArticle && <>
          <div className="article-item justify-content-start">
            <Button color="none" className="ps-0" onClick={resetHelpContent}>
              <i className="mdi mdi-chevron-left font-size-16 me-2" />
            </Button>
            <div className="article-header">{selectedArticle.title}</div>
          </div>
          {isLoadInProgress &&
            <SpinnerChase className="sm help-spinner" />
          }
          <iframe className="help-iframe" src={selectedArticle.content?.iframeSrc} onLoad={() => setIsLoadInProgress(false)} />
        </>}
        {!!articlesError && <Alert color="danger" className="fade show text-center">
          <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load articles
        </Alert>}
      </div>
    </React.Fragment>
  )
}

HelpSidebarContent.propTypes = {
  searchTerm: PropTypes.string,
}

export default HelpSidebarContent;