import React, { useEffect } from "react";
import { Button } from "reactstrap";
import Credential from "model/credential";
import UploadCommissionCertificate from "./CommissionCertificate";
import UploadEOInsurance from "./EOInsurance";
import UploadBond from "./Bond";
import UploadDriverLicense from "./DriverLicense";
import UploadBackgroundReport from "./BackgroundReport";
import UploadW9 from "./W9";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { doCredentialFormCleanup } from "store/actions";

const FormNewUpload = () => {

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // consume formik instance from context
  const formik = useFormikContext();

  // get redux state from store
  const { isUploadInProgress } = useSelector(state => state.Credential.Form);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    return () => {
      // state cleanup on component unmount
      dispatch(doCredentialFormCleanup());
    }
  }, []);

  let UploadDocument;

  switch (formik.values.type) {
    case Credential.TYPE_COMMISSION_CERTIFICATE:
      UploadDocument = UploadCommissionCertificate;
      break
    case Credential.TYPE_EO_INSURANCE:
      UploadDocument = UploadEOInsurance;
      break
    case Credential.TYPE_BOND:
      UploadDocument = UploadBond;
      break
    case Credential.TYPE_DRIVER_LICENSE_NUMBER:
      UploadDocument = UploadDriverLicense;
      break
    case Credential.TYPE_BACKGROUND_REPORT:
      UploadDocument = UploadBackgroundReport;
      break
    case Credential.TYPE_W9:
      UploadDocument = UploadW9;
      break
  }

  return <React.Fragment>
    <div className="divider-faded mb-2" />

    <UploadDocument />

    <Button type="button" color="primary" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
      <span>{isUploadInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}Upload</span>
    </Button>

    <div className="divider-faded" />
  </React.Fragment>
}

export default FormNewUpload;