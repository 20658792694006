/********** REMOTE CUSTOMER PERFORMANCE **********/

export const GET_CUSTOMER_PERFORMANCE = "GET_CUSTOMER_PERFORMANCE"

export const GET_CUSTOMER_PERFORMANCE_OK = "GET_CUSTOMER_PERFORMANCE_OK"

export const GET_CUSTOMER_PERFORMANCE_ERR = "GET_CUSTOMER_PERFORMANCE_ERR"

export const DO_CUSTOMER_PERFORMANCE_CLEANUP = "DO_CUSTOMER_PERFORMANCE_CLEANUP"

/********** LEADERBOARD **********/

export const GET_LEADERBOARD = "GET_LEADERBOARD"

export const GET_LEADERBOARD_OK = "GET_LEADERBOARD_OK"

export const GET_LEADERBOARD_ERR = "GET_LEADERBOARD_ERR"

export const DO_LEADERBOARD_CLEANUP = "DO_LEADERBOARD_CLEANUP"

/********** LEADERBOARD LIST **********/

export const GET_LEADERBOARD_LIST = "GET_LEADERBOARD_LIST"

export const GET_LEADERBOARD_LIST_OK = "GET_LEADERBOARD_LIST_OK"

export const GET_LEADERBOARD_LIST_ERR = "GET_LEADERBOARD_LIST_ERR"

export const DO_LEADERBOARD_LIST_CLEANUP = "DO_LEADERBOARD_LIST_CLEANUP"

/********** CUSTOMER SUPPORT STATS **********/

export const GET_SUPPORT_STATS = "GET_SUPPORT_STATS"

export const GET_SUPPORT_STATS_OK = "GET_SUPPORT_STATS_OK"

export const GET_SUPPORT_STATS_ERR = "GET_SUPPORT_STATS_ERR"

export const DO_SUPPORT_STATS_CLEANUP = "DO_SUPPORT_STATS_CLEANUP"

/********** SCHEDULER CHATS **********/

export const GET_SCHEDULER_CHATS = "GET_SCHEDULER_CHATS"

export const GET_SCHEDULER_CHATS_OK = "GET_SCHEDULER_CHATS_OK"

export const GET_SCHEDULER_CHATS_ERR = "GET_SCHEDULER_CHATS_ERR"

export const DO_SCHEDULER_CHATS_CLEANUP = "DO_SCHEDULER_CHATS_CLEANUP"

/********** SCHEDULER IN PROGRESS ORDERS **********/

export const GET_SCHEDULER_PROGRESS_ORDERS = "GET_SCHEDULER_PROGRESS_ORDERS"

export const GET_SCHEDULER_PROGRESS_ORDERS_OK = "GET_SCHEDULER_PROGRESS_ORDERS_OK"

export const GET_SCHEDULER_PROGRESS_ORDERS_ERR = "GET_SCHEDULER_PROGRESS_ORDERS_ERR"

export const DO_SCHEDULER_PROGRESS_ORDERS_CLEANUP = "DO_SCHEDULER_PROGRESS_ORDERS_CLEANUP"


/********** APPOINTMENTS LIST **********/

export const GET_APPOINTMENTS_LIST = "GET_APPOINTMENTS_LIST"

export const GET_APPOINTMENTS_LIST_OK = "GET_APPOINTMENTS_LIST_OK"

export const GET_APPOINTMENTS_LIST_ERR = "GET_APPOINTMENTS_LIST_ERR"

export const DO_APPOINTMENTS_LIST_CLEANUP = "DO_APPOINTMENTS_LIST_CLEANUP"