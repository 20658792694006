import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import UserAvatar from "components/Shared/UserAvatar";
import { Link } from "react-router-dom";
import { getInitialsFromName, showError } from "helpers/utilHelper";
import { formatTimestamp, getMessageDateFormat } from "helpers/dateHelper";
import { route, routes } from "helpers/routeHelper";
import UserHeader from "./UserHeader";
import Message from "model/message";
import { Col } from "reactstrap";
import { useAccess } from "context/access";
import classnames from "classnames";
import Confirmation from 'components/Shared/Confirmation';
import { muteAllChannels, toggleChannelMute, getChannels, unmuteAllChannels } from "helpers/backendHelper";
import mutedChanelIcon from '../../../assets/images/unmute-bell.svg';

// Helper function for sorting channels for schedulers
const sortChannelsForScheduler = (channels, schedulerChannelView) => {
  return channels.sort((a, b) => {
    return schedulerChannelView.indexOf(a.num.toString()) - schedulerChannelView.indexOf(b.num.toString());
  });
};

const Sidebar = ({ id, channelId, messages, channels, hasNotary }) => {

  const { iAmScheduler } = useAccess();

  /********** STATE **********/
  const [muteAllVisible, setMuteAllVisible] = useState(false);
  const [muteChannelVisible, setMuteChannelVisible] = useState(false);
  const [channelsList, setChannelsList] = useState([]);
  const [selectedChannelMuted, setSelectedChannelMuted] = useState(null);

  const isAllMuted = channelsList?.filter(channel => channel.num !== Message.CHANNEL_MY_NOTES).every(channel => channel.isMuted);
  // Custom order for scheduler chat
  const schedulerChannelView = ["5", "6", "4", "9", "1", "8", "3", "2"];

  // UI Constants for Mute|Unmute All and Channel modals
  const muteAllIcon = <div className="mute-icon"><i className={classnames('bx bx-bell', { 'bx-bell-off': isAllMuted })} /></div>;
  const muteAllDescription = isAllMuted
    ? 'Unmuting will notify you about messages sent on this order.'
    : 'Muting will no longer notify you about messages sent on this order.';

  const muteChannelIcon = <div className="mute-icon"><i className={classnames('bx bx-bell', { 'bx-bell-off': selectedChannelMuted })} /></div>;
  const muteChannelDescription = selectedChannelMuted
    ? 'Unmuting will notify you about messages sent on this channel.'
    : 'Muting will no longer notify you about messages sent on this channel.';

  /********** Event Handlers **********/
  const refreshChannelsList = () => {
    getChannels(id)
    .then(resp => {
      // Sort channels if the user is a scheduler
      let sortedChannels = iAmScheduler()
        ? sortChannelsForScheduler(resp.channels, schedulerChannelView)
        : resp.channels;

      // If there is no notary, filter the channels list based on channels present in the "channels" object
      if (!hasNotary) {
        sortedChannels = sortedChannels.filter(channel => channels.hasOwnProperty(channel.num.toString()));
      }

    setChannelsList(sortedChannels);
    })
    .catch(() => showError("Unable to load channels data."));
  };

  const handleMuteAll = () => {
    muteAllChannels(id)
      .then(() => {
        setMuteAllVisible(false);
        refreshChannelsList();
      })
      .catch(() => showError("Unable to mute all messages."));
  };

  const handleUnmuteAll = () => {
    unmuteAllChannels(id)
      .then(() => {
        setMuteAllVisible(false);
        refreshChannelsList();
      })
      .catch(() => showError("Unable to unmute all messages."));
  };

  const handleToggleMute = (channelId) => {
    toggleChannelMute(id, channelId)
      .then(() => {
        setMuteChannelVisible(false);
        refreshChannelsList();
      })
      .catch(() => showError("Unable to mute this channel."));
  };

  const handleMuteChannel = (isMuted) => {
    setSelectedChannelMuted(isMuted);
    setMuteChannelVisible(true);
  };

  useEffect(() => {
    refreshChannelsList();
  }, []);

  return (
    <Col xs={12} lg={4}>
      <div className="me-lg-4">
        <div className="py-2 border-bottom">
          <UserHeader />
        </div>

        <div className="chat-leftsidebar-nav">
          <div className="py-4">
            <div className="d-flex align-items-center justify-content-between mb-5">
              <h5 className="font-size-12 m-0"><strong>CONVERSATIONS</strong></h5>
              <button className="mute-btn position-relative" onClick={() => setMuteAllVisible(true)}>
                <i className={classnames('bx bx-bell', { 'bx-bell-off': isAllMuted })} />
                <div className="mute-message">{isAllMuted ? 'Unmute' : 'Mute'} all channels</div>
              </button>
            </div>

            <ul className="list-unstyled chat-list" id="recent-list">
              <PerfectScrollbar>
                {channelsList?.map((chRow, index) => {
                  const channel = channels[chRow.num];
                  const channelMessages = messages[chRow];
                  const lastMessage = channelMessages?.[channelMessages.length - 1] || '';

                  return (
                    <li key={index} className={channelId === chRow.num ? "active" : ""}>
                      <Link to={route(routes.view_order_messages, [id, chRow.num])}>
                        <div className="d-flex align-items-center">
                          <div className="align-self-center me-3 position-relative">
                            <UserAvatar id={+chRow.num} initials={getInitialsFromName(channel)} size="sm" />
                            {chRow.isMuted && channelId === chRow.num && <img src={mutedChanelIcon} className="muted-chanel" alt="Muted" />}
                          </div>

                          <div className="flex-grow-1 overflow-hidden my-auto">
                            <h5 className="text-truncate font-size-14 mb-1">{channel}</h5>
                            {lastMessage && (
                              <div className="text-truncate">
                                {lastMessage.contentType === Message.CONTENT_TYPE_TEXT && lastMessage.content}
                                {lastMessage.contentType === Message.CONTENT_TYPE_IMAGE && <div className="d-flex"><i className="mdi mdi-camera me-1" /> <span className="font-size-12">Image</span></div>}
                              </div>
                            )}
                          </div>
                          {lastMessage && (
                            <div className="font-size-11">
                              {formatTimestamp(lastMessage.createdTs, getMessageDateFormat(lastMessage.createdTs))}
                            </div>
                          )}
                          {channelId === chRow.num && chRow.num != Message.CHANNEL_MY_NOTES && (
                            <i className={classnames('font-size-18 ms-3 bx bx-bell', { 'bx-bell-off': chRow.isMuted })}
                              onClick={() => handleMuteChannel(chRow.isMuted)}
                              style={{ color: '#556ee6' }}
                            />
                          )}
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </PerfectScrollbar>
            </ul>
          </div>
        </div>
      </div>

      {/* Mute|Unmute All Channels - Modal */}
      {muteAllVisible && (
        <Confirmation
          style={{ backgroundColor: 'white', width: 330 }}
          customIcon={muteAllIcon}
          confirmBtnText="Confirm"
          onConfirm={() => isAllMuted ? handleUnmuteAll() : handleMuteAll()}
          onCancel={() => setMuteAllVisible(false)}
          closeOnClickOutside={false}
          reverseButtons={false}
        >
          <div className="d-flex justify-content-center">
            <Col sm={9}>
              <span style={{ color: '#556EE6', fontSize: 15 }}>{muteAllDescription}</span>
            </Col>
          </div>
        </Confirmation>
      )}

      {/* Mute|Unmute Channel - Modal */}
      {muteChannelVisible && (
        <Confirmation
          style={{ backgroundColor: 'white', width: 330 }}
          customIcon={muteChannelIcon}
          confirmBtnText="Confirm"
          onConfirm={() => handleToggleMute(channelId)}
          onCancel={() => setMuteChannelVisible(false)}
          closeOnClickOutside={false}
          reverseButtons={false}
        >
          <div className="d-flex justify-content-center">
            <Col sm={9}>
              <span style={{ color: '#556EE6', fontSize: 15 }}>{muteChannelDescription}</span>
            </Col>
          </div>
        </Confirmation>
      )}
    </Col>
  );
};

Sidebar.propTypes = {
  id: PropTypes.number,
  channelId: PropTypes.number,
  messages: PropTypes.object,
  channels: PropTypes.object,
  hasNotary: PropTypes.bool,
};

export default Sidebar;