import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col, CardHeader, CardTitle, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Table } from "reactstrap";
import CardPreloader from "components/Shared/CardPreloader";
import { formatTimestamp, formats } from "helpers/dateHelper";
import { perms, useAccess } from "context/access";
import Notary from "model/notary";

const ViewAdditionalInfo = props => {

  const { toggleEditMode, notary, isLoadInProgress } = props;

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (<Card className="expand-v">
    <CardHeader className="bg-transparent pt-3 pb-0">
      <Row>
        <Col>
          <CardTitle>Additional Info</CardTitle>
        </Col>
        {iAmGranted(perms.edit_notaries) && <Col xs="auto" className="text-end">
          <Dropdown isOpen={menuIsOpen} toggle={() => setMenuIsOpen(!menuIsOpen)}>
            <DropdownToggle tag="button" className="btn btn-default card-menu-btn">
              <i className="bx bx-dots-horizontal-rounded" />
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem onClick={toggleEditMode}>Edit</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Col>}
      </Row>
    </CardHeader>
    <CardBody>
      <Table className="section-rows table bt-1">
        <tbody>
          <tr>
            <td className="section-row-label">Status</td>
            <td className="section-row-value ps-4">{notary.isActive ? "Active" : "Disabled"}</td>
          </tr>
          <tr>
            <td className="section-row-label">Notary Type</td>
            <td className="section-row-value ps-4">{Notary.getTypeName(notary.type)}</td>
          </tr>
          <tr>
            <td className="section-row-label">Verified Account</td>
            <td className="section-row-value ps-4">{notary.isVerified ? <span className="text-success">Yes</span> : "No"}</td>
          </tr>
          <tr>
            <td className="section-row-label">{'History of "Verified account"'}</td>
            <td className="ps-4">
              {notary.isVerified ? <div>
                <span className="font-weight-semibold pe-4">{notary.verifierFullName}</span>
                <span className="text-success font-weight-semibold">Yes</span>
                <span className="ps-4">{formatTimestamp(notary.verifiedTs, formats.DATETIME)}</span>
              </div> : '--'}
            </td>
          </tr>
          <tr>
            <td className="section-row-label">Scheduler alert</td>
            <td className="ps-4">{notary.scheduleAlert || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label no-border">Internal Comments</td>
            <td className="ps-4 no-border">{notary.internalComments || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label no-border">Mav1 Rating</td>
            <td className="ps-4 no-border">{notary.initialRating || "---"}</td>
          </tr>
        </tbody>
      </Table>
    </CardBody>
    {isLoadInProgress && <CardPreloader />}
  </Card>)
}

ViewAdditionalInfo.propTypes = {
  toggleEditMode: PropTypes.func,
  notary: PropTypes.object,
  isLoadInProgress: PropTypes.bool
};

export default ViewAdditionalInfo;