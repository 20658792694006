import OrderNotary from "./orderNotary";

export default class Order {

  static STATUS_CANCELLED = -1;
  static STATUS_DRAFT = 0;
  static STATUS_PENDING_VID = 1;
  static STATUS_NEW = 2;
  static STATUS_PICK_NOTARY = 3;
  static STATUS_PENDING_E_SIGN = 4;
  static STATUS_PENDING_INK_SIGN = 5;
  static STATUS_COMPLETED = 6;
  static STATUS_SEALED = 7;

  static INTERNAL_STATUS_ON_HOLD = 1;
  static INTERNAL_STATUS_UNDER_REVIEW = 2;
  static INTERNAL_STATUS_HIGH_RISK = 3;
  static INTERNAL_STATUS_FRAUD = 4;
  static INTERNAL_STATUS_IGNORE = -1;


  static GRANULAR_STATUS_TYPE_INTERNAL = 0;
  static GRANULAR_STATUS_TYPE_ORDER = 1;
  static GRANULAR_STATUS_TYPE_ORDER_NOTARY = 2;

  static CUSTOMER_LOCATION_REMOTE = 1;
  static CUSTOMER_LOCATION_IN_STORE = 2;

  static CONTRACT_OPTION_VID = 1;
  static CONTRACT_OPTION_SIGNING_VID = 2;
  static CONTRACT_OPTION_SIGNING = 3;

  static DOC_DELIVERY_OPTION_UPLOAD = 1;
  static DOC_DELIVERY_OPTION_SHIPPING = 2;

  static SHIPPING_COMPANY_OTHER = 1;
  static SHIPPING_COMPANY_FEDEX = 2;
  static SHIPPING_COMPANY_UPS = 3;

  static SEAL_STATUS_QUEUED = 1;
  static SEAL_STATUS_FAILED = 2;
  static SEAL_STATUS_SEALED = 3;

  static CONTRACT_TYPE_ORIGINAL = 1;
  static CONTRACT_TYPE_RESIGN = 2;

  static ITEM_TO_COLLECT_CORPORATE_RESOLUTION = 1;
  static ITEM_TO_COLLECT_PROOF_OF_INCOME = 2;
  static ITEM_TO_COLLECT_PROOF_OF_INSURANCE = 3;
  static ITEM_TO_COLLECT_DRIVER_LICENSE_COPY = 4;

  static VIRTUAL_STATUS_CANCELLED = "canceled";
  static VIRTUAL_STATUS_COMPLETED = "completed";
  static VIRTUAL_STATUS_DRAFT = "draft";
  static VIRTUAL_STATUS_NEW_ORDER = "new";
  static VIRTUAL_STATUS_COMPLETED_BY_NOTARY = "notaryCompleted";
  static VIRTUAL_STATUS_PENDING_ESIGN = "pendingEsign";
  static VIRTUAL_STATUS_PENDING_INKSIGN = "pendingInksign";
  static VIRTUAL_STATUS_PENDING_VID = "pendingVid";
  static VIRTUAL_STATUS_PICK_NOTARY = "pickNotary";
  static VIRTUAL_STATUS_READY_TO_SEAL = "readyToSeal";
  static VIRTUAL_STATUS_TOTAL = "total";

  static ALERT_FILTER_ON_HOLD = 1;
  static ALERT_FILTER_UNDER_REVIEW = 2;
  static ALERT_FILTER_HIGH_RISK = 3;
  static ALERT_FILTER_FRAUD = 4;
  static ALERT_FILTER_INACTIVE = 5;
  static ALERT_FILTER_RURAL = 6;

  static BILLING_STATUS_NOT_ELIGIBLE = 0;
  static BILLING_STATUS_FEES_UNSETTLED = 1;
  static BILLING_STATUS_FEES_SETTLED = 2;
  static BILLING_STATUS_SENT_TO_QB = 3;
  static BILLING_STATUS_PAID = 4;
  static BILLING_STATUS_IGNORED = 5;

  static ACCOUNTING_STATUS_NOT_SETTLED = 0;
  static ACCOUNTING_STATUS_SETTLED = 1;
  static ACCOUNTING_STATUS_IGNORED = 2;

  static getStatusColor(status) {
    switch (status) {
      case this.STATUS_DRAFT:
        return 'draft';
      case this.STATUS_PENDING_VID:
        return 'pending-vid';
      case this.STATUS_NEW:
        return 'new';
      case this.STATUS_PICK_NOTARY:
        return 'pick';
      case this.STATUS_PENDING_E_SIGN:
        return 'pending-eSign';
      case this.STATUS_PENDING_INK_SIGN:
        return 'pending-inkSign';
      case this.STATUS_COMPLETED:
        return 'completed';
      case this.STATUS_SEALED:
        return 'sealed';
      case this.STATUS_CANCELLED:
        return 'cancelled';
      default:
        return 'dark';
    }
  }

  static getStatusOptionsList = () => ([
    { value: this.STATUS_CANCELLED, label: 'Canceled' },
    { value: this.STATUS_DRAFT, label: 'Draft' },
    { value: this.STATUS_PENDING_VID, label: 'Pending VID' },
    { value: this.STATUS_NEW, label: 'New' },
    { value: this.STATUS_PICK_NOTARY, label: 'Pick Notary' },
    { value: this.STATUS_PENDING_E_SIGN, label: 'Pending E-sign' },
    { value: this.STATUS_PENDING_INK_SIGN, label: 'Pending Ink-sign' },
    { value: this.STATUS_COMPLETED, label: 'Completed' },
    { value: this.STATUS_SEALED, label: 'Sealed' },
  ]);

  static getGranularStatusColor(status) {
    if (!status) return 'dark';

    const [statusType, statusId] = status.split("_").map(id => parseInt(id));

    switch (statusType) {
      case this.GRANULAR_STATUS_TYPE_INTERNAL:
        return 'secondary';
      case this.GRANULAR_STATUS_TYPE_ORDER_NOTARY:
        return OrderNotary.getGranularStatusColor(statusId);
      case this.GRANULAR_STATUS_TYPE_ORDER:
      default:
        return this.getStatusColor(statusId);
    }
  }

  static getBillingStatusMap() {
    return {
      [this.BILLING_STATUS_FEES_UNSETTLED]: 'Unsettled',
      [this.BILLING_STATUS_FEES_SETTLED]: 'Fees Settled',
      [this.BILLING_STATUS_SENT_TO_QB]: 'Sent to QB',
      [this.BILLING_STATUS_PAID]: 'Paid',
      [this.BILLING_STATUS_IGNORED]: 'Ignored',
    };
  }

  static getBillingStatusFiltersMap() {
    return {
      [this.BILLING_STATUS_NOT_ELIGIBLE]: 'Empty',
      [this.BILLING_STATUS_FEES_UNSETTLED]: 'Unsettled',
      [this.BILLING_STATUS_FEES_SETTLED]: 'Fees Settled',
      [this.BILLING_STATUS_SENT_TO_QB]: 'Sent to QB',
      [this.BILLING_STATUS_IGNORED]: 'Ignored',
    };
  }

  static getStatusMap() {
    return {
      [this.STATUS_CANCELLED]: 'Canceled',
      [this.STATUS_DRAFT]: 'Draft',
      [this.STATUS_PENDING_VID]: 'Pending VID',
      [this.STATUS_NEW]: 'New Order',
      [this.STATUS_PICK_NOTARY]: 'Pick Notary',
      [this.STATUS_PENDING_E_SIGN]: 'Pending E-sign',
      [this.STATUS_PENDING_INK_SIGN]: 'Pending Ink-sign',
      [this.STATUS_COMPLETED]: 'Completed',
      [this.STATUS_SEALED]: 'Sealed',
    };
  }

  static getGranularStatusTypeMap() {
    return {
      [this.GRANULAR_STATUS_TYPE_ORDER]: "Order",
      [this.GRANULAR_STATUS_TYPE_ORDER_NOTARY]: "Order Notary",
      [this.GRANULAR_STATUS_TYPE_INTERNAL]: "Internal",
    };
  }

  static getSchedulerChartStatusMap() {
    return {
      [this.VIRTUAL_STATUS_CANCELLED]: 'Canceled',
      [this.VIRTUAL_STATUS_DRAFT]: 'Draft',
      [this.VIRTUAL_STATUS_PENDING_VID]: 'Pending VID',
      [this.VIRTUAL_STATUS_NEW_ORDER]: 'New Assigned Order',
      [this.VIRTUAL_STATUS_PICK_NOTARY]: 'Pick Notary',
      [this.VIRTUAL_STATUS_PENDING_ESIGN]: 'Pending E-sign',
      [this.VIRTUAL_STATUS_PENDING_INKSIGN]: 'Pending Ink-sign',
      [this.VIRTUAL_STATUS_COMPLETED_BY_NOTARY]: 'Completed by Notary',
      [this.VIRTUAL_STATUS_READY_TO_SEAL]: 'Ready to Seal',
      [this.VIRTUAL_STATUS_COMPLETED]: 'Completed',
      [this.VIRTUAL_STATUS_TOTAL]: 'Total orders',
    };
  }

  static getStatusName(id) {
    return this.getStatusMap()[id];
  }

  static getBillingStatusName(id) {
    return this.getBillingStatusMap()[id];
  }

  static getInternalStatusMap() {
    return {
      [this.INTERNAL_STATUS_UNDER_REVIEW]: 'Under Review',
      [this.INTERNAL_STATUS_ON_HOLD]: 'On Hold',
      [this.INTERNAL_STATUS_HIGH_RISK]: 'High Risk',
      [this.INTERNAL_STATUS_FRAUD]: 'Fraud',
      [this.INTERNAL_STATUS_IGNORE]: 'Ignore',
    };
  }

  static getInternalStatusName(id) {
    return this.getInternalStatusMap()[id];
  }

  static getShippingCompanyMap() {
    return {
      [this.SHIPPING_COMPANY_FEDEX]: 'FedEx',
      [this.SHIPPING_COMPANY_UPS]: 'UPS',
      [this.SHIPPING_COMPANY_OTHER]: 'Other'
    };
  }

  static getShippingCompanyName(id) {
    return this.getShippingCompanyMap()[id];
  }

  static getCustomerLocationMap() {
    return {
      [this.CUSTOMER_LOCATION_REMOTE]: 'Remote',
      [this.CUSTOMER_LOCATION_IN_STORE]: 'In store',
    };
  }

  static getCustomerLocationName(id) {
    return this.getCustomerLocationMap()[id];
  }

  static getContractOptions() {
    return {
      [this.CONTRACT_OPTION_VID]: 'Identity Verification only',
      [this.CONTRACT_OPTION_SIGNING_VID]: 'Contract Signing with Identity Verification',
      [this.CONTRACT_OPTION_SIGNING]: 'Contract Signing only',
    };
  }

  static getContractTypeMap() {
    return {
      [this.CONTRACT_TYPE_ORIGINAL]: 'Original - Complete document package for signing',
      [this.CONTRACT_TYPE_RESIGN]: 'Resign - 1 or 2 documents that need resigning',
    };
  }

  static getContractTypeName(id) {
    return this.getContractTypeMap()[id];
  }

  static getItemToCollectMap() {
    return {
      [this.ITEM_TO_COLLECT_CORPORATE_RESOLUTION]: 'Corporate Resolution',
      [this.ITEM_TO_COLLECT_PROOF_OF_INCOME]: 'Proof of Income',
      [this.ITEM_TO_COLLECT_PROOF_OF_INSURANCE]: 'Proof of Insurance',
      [this.ITEM_TO_COLLECT_DRIVER_LICENSE_COPY]: "Copy of driver's license",
    };
  }

  static getItemToCollectName(id) {
    return this.getItemToCollectMap()[id];
  }

  static getDocDeliveryOptions() {
    return {
      [this.DOC_DELIVERY_OPTION_UPLOAD]: 'Upload',
      [this.DOC_DELIVERY_OPTION_SHIPPING]: 'Shipping',
    };
  }

  static getAlertOptions() {
    return {
      [this.ALERT_FILTER_ON_HOLD]: 'On hold',
      [this.ALERT_FILTER_UNDER_REVIEW]: 'Under review',
      [this.ALERT_FILTER_HIGH_RISK]: 'High risk',
      [this.ALERT_FILTER_FRAUD]: 'Fraud',
      [this.ALERT_FILTER_INACTIVE]: 'Inactive',
      [this.ALERT_FILTER_RURAL]: 'Rural',
    };
  }

  static getImmediateReviewOptions() {
    return {
      [this.ALERT_FILTER_ON_HOLD]: 'On hold',
      [this.ALERT_FILTER_UNDER_REVIEW]: 'Under review',
      [this.ALERT_FILTER_HIGH_RISK]: 'High risk',
      [this.ALERT_FILTER_FRAUD]: 'Fraud',
    };
  }
}