
import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';
import Auth from './auth/reducer';
import User from './user/reducer';
import UserRole from './userRole/reducer';
import DealerGroup from './dealerGroup/reducer';
import DealerRegion from './dealerRegion/reducer';
import DealerStore from './dealerStore/reducer';
import PaymentPlan from './paymentPlan/reducer';
import Message from './message/reducer';
import Settings from './settings/reducer';
import Dashboard from './dashboard/reducer';
import Order from './order/reducer';
import OrderDoc from './orderDoc/reducer';
import OrderFees from './orderFees/reducer';
import Notification from './notification/reducer';
import SupportCase from './supportCase/reducer';
import VidRequest from './vidRequest/reducer';
import Notary from './notary/reducer';
import Report from './report/reducer';
import AdditionalFee from './additionalFee/reducer';
import Payment from './payment/reducer';
import DealerStatement from './statement/dealer/reducer';
import NotaryStatement from './statement/notary/reducer';
import Bidding from './bidding/reducer';
import CronJob from './cronJob/reducer';
import Credential from './credential/reducer';

export default combineReducers({
  Layout,
  Auth,
  User,
  UserRole,
  DealerGroup,
  DealerRegion,
  DealerStore,
  PaymentPlan,
  Message,
  Settings,
  Dashboard,
  Order,
  OrderDoc,
  OrderFees,
  Notification,
  SupportCase,
  VidRequest,
  Notary,
  AdditionalFee,
  Report,
  Payment,
  DealerStatement,
  NotaryStatement,
  Bidding,
  CronJob,
  Credential
})
