import React, { useEffect } from "react";
import { Card, CardBody, CardHeader, CardTitle, Progress, Alert } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { doLeaderboardCleanup, getLeaderboard } from "store/actions";
import SpinnerChase from "components/Shared/SpinnerChase";
import { Link } from "react-router-dom";
import { formatDate, formats } from "helpers/dateHelper";
import { route, routes } from "helpers/routeHelper";
import { useAuth } from "context/auth";
import UserRole from 'model/userRole';

const topColors = ['primary', 'info', 'success', 'warning', 'danger']

const Leaderboard = () => {

  const dispatch = useDispatch();
  const { user } = useAuth();

  /********** STATE **********/

  const { leaderboard, leaderboardError, isLoadInProgress } = useSelector(state => state.Dashboard.Leaderboard);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // get regions list
    dispatch(getLeaderboard({ limit: 4 }));
    return () => {
      // state cleanup on component unmount
      dispatch(doLeaderboardCleanup());
    }
  }, []);

  return <Card className="top-card">
    <CardHeader className="bg-transparent pt-3 pb-0">
      <CardTitle>{`STORE PERFORMERS ${formatDate(new Date(), formats.MONTH).toUpperCase()}`}</CardTitle>
    </CardHeader>
    <CardBody className="pt-0">
      <div className="text-center">
        <i className="bx bxs-map-pin font-size-50 text-primary pb-2" />
        <h4>{leaderboard[0]?.orders ? parseInt(leaderboard[0].orders).toLocaleString() : "--"}</h4>
        <h6 className="pb-0">{leaderboard[0]?.name || "--"}</h6>
        <p className="top-store-name">{leaderboard[0]?.dealerStoreName}</p>
      </div>
      <table className="table mb-1 pb-0">
        <tbody>
          {!isLoadInProgress && !leaderboardError && leaderboard.map((store, index) => <tr key={index} className="align-middle">
            <td className="py-2">
              <div className=" d-flex flex-column">
                <p className="top-store-name my-auto">{store.name}</p>
                <h5 className="font-size-12 font-weight-700 ">{store.dealerGroupName}</h5>
              </div>
            </td>
            <td className="py-2">
              <h5 className="my-auto">{parseInt(store.orders).toLocaleString()}</h5>
            </td>
            <td className="progress-col py-2">
              <Progress color={topColors[index]} value={index === 0 ? 100 : store.orders / leaderboard[0].orders * 100}></Progress>
            </td>
          </tr>)}
          {leaderboard.length === 0 && !leaderboardError && !isLoadInProgress && <tr>
            <td className="table-empty" colSpan="3">No stores found</td>
          </tr>}
          {!!leaderboardError && <tr>
            <td className="table-empty" colSpan="3">
              <Alert color="danger" className="fade show text-center mb-0">
                <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load stores
              </Alert>
            </td>
          </tr>}
          {isLoadInProgress && <tr>
            <td className="table-empty" colSpan="3">
              <SpinnerChase className="sm" />
            </td>
          </tr>}
        </tbody>
      </table>
      {user.userRoleId != UserRole.ID_ACCOUNT_MANAGER && <div className="d-flex justify-content-end mt-2">
        <Link to={route(routes.list_leaderboard)}>View all</Link>
      </div>}
    </CardBody>
  </Card>
}

export default Leaderboard;