import React, { useState } from "react";
import PropTypes from 'prop-types';
import { useNavigate, Link } from "react-router-dom";
import { Row, Col, Button, Card, CardBody, CardHeader } from "reactstrap";
import { showSuccess, showError } from "helpers/utilHelper";
import { route, routes } from "helpers/routeHelper";
import Confirmation from "components/Shared/Confirmation";
import { perms, useAccess } from "context/access";
import CardPreloader from "components/Shared/CardPreloader";
import { formatPaymentPlanDescription } from "helpers/jsxHelper";
import { deletePaymentPlan } from "helpers/backendHelper";

const ViewInfo = props => {

  const { isRefreshing, id, paymentPlan, toggleEditMode } = props;

  // router hook that helps redirect
  const navigate = useNavigate();
  // hooks that check permissions
  const { iAmGranted } = useAccess();

  /********** STATE **********/

  // is the confirmation dialog visible or not
  // used to show/hide the delete warning
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

  /********** EVENT HANDLERS **********/

  // delete-plan event handler
  // make the remote call to delete the plan
  const removePaymentPlan = () => {
    setIsDeleteInProgress(true);
    deletePaymentPlan(id)
      .then(response => {
        const name = paymentPlan.name;
        showSuccess(`Plan "${name}" has been deleted`);
        navigate(route(routes.list_payment_plans));
      })
      .catch(ex => {
        showError('Unable to delete plan');
      })
      .finally(() => {
        setIsDeleteInProgress(false);
      });
  };

  return <React.Fragment>
    <Card>
      <CardHeader className="bg-transparent pt-3">
        <Row>
          <Col>
            <h3>{paymentPlan.name} {!paymentPlan.isPublic && <i className="bx bxs-lock-alt ms-1" />}</h3>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              {iAmGranted(perms.edit_payment_plans) && <Button type="button" color="primary" className="ms-2 mb-2" onClick={toggleEditMode}>
                <i className="mdi mdi-pencil me-1" />Edit plan
              </Button>}
              {iAmGranted(perms.delete_payment_plans) && <Button type="button" color="danger" className="ms-2 mb-2" onClick={() => { setIsDeleteConfirmationVisible(true) }} disabled={isDeleteInProgress}>
                {isDeleteInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                {!isDeleteInProgress && <i className="mdi mdi-delete me-1" />}
                Delete plan
              </Button>}
              <Link to={route(routes.list_payment_plans)} className="btn btn-secondary ms-2 mb-2">
                <i className="mdi mdi-chevron-left me-1" />Back
              </Link>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row className="mb-3">
          <Col>
            <label className="mb-0">Subscription Fee</label>
            <div>${paymentPlan.subscriptionPrice}</div>
          </Col>
          <Col>
            <label className="mb-0">Remote Transaction Fee</label>
            <div>${paymentPlan.remotePrice}</div>
          </Col>
          <Col>
            <label className="mb-0">InStore Transaction Fee</label>
            <div>${paymentPlan.instorePrice}</div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <label className="mb-0">Remote Transactions Included</label>
            <div>{paymentPlan.numOfFreeRemote}</div>
          </Col>
          <Col>
            <label className="mb-0">InStore Transactions Included</label>
            <div>{paymentPlan.numOfFreeInstore}</div>
          </Col>
          <Col>
            <label className="mb-0">Is Public</label>
            <div>{paymentPlan.isPublic ? 'Yes' : 'No'}</div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <label className="mb-0">Is Free Trial</label>
            <div>{paymentPlan.isFreeTrial ? 'Yes' : 'No'}</div>
          </Col>
          <Col>
            <label className="mb-2">Max Days</label>
            <div>{paymentPlan.maxDays || '--'}</div>
          </Col>
          <Col>
            <label className="mb-2">Max Orders</label>
            <div>{paymentPlan.maxOrders || '--'}</div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <label className="mb-2">Description</label>
            <div className="payment-plan-description">{formatPaymentPlanDescription(paymentPlan.description)}</div>
          </Col>
          <Col>
            <label className="mb-2">Max Recommended Volume</label>
            <div>{paymentPlan.maxRecommendedVolume || '--'}</div>
          </Col>
          <Col></Col>
        </Row>
      </CardBody>
      {isRefreshing && <CardPreloader />}
    </Card>
    {isDeleteConfirmationVisible && <Confirmation
      confirmBtnText="Delete"
      onConfirm={() => {
        setIsDeleteConfirmationVisible(false);
        removePaymentPlan();
      }}
      onCancel={() => setIsDeleteConfirmationVisible(false)}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to delete plan &quot;{paymentPlan.name}&quot;?</span>
    </Confirmation>}
  </React.Fragment>
}

ViewInfo.propTypes = {
  isRefreshing: PropTypes.bool,
  id: PropTypes.number,
  paymentPlan: PropTypes.object,
  toggleEditMode: PropTypes.func,
};

export default ViewInfo;
