import React, { useCallback, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { Row, Card, CardBody, CardHeader, Table, Accordion, AccordionItem, Collapse, Button, Label, FormFeedback, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import Col from "components/Shared/Col";
import TextareaAutosize from "react-textarea-autosize";
import Confirmation from 'components/Shared/Confirmation';
import skipVidIcon from "assets/images/skip-vid-icon.svg";
import vidSkippedIcon from "assets/images/identity-validation-skipped.svg";
import skippedVidIcon from "assets/images/skipped-VID.svg";
import isolationIcon from "assets/images/isolation-icon.svg";
import { getVidRequestNormListItem } from "store/actions";
import { getBeUrl, showError, showSuccess } from "helpers/utilHelper";
import config from "config";
import NoImg from "components/Shared/NoImg";
import classnames from "classnames";
import VidRequest from "model/vidRequest";
import { formatTimestamp, formats, formatUtcDate } from "helpers/dateHelper";
import { deleteVidRequest, getOrderVidStatus, resubmitVidRequest, skipVid } from "helpers/backendHelper";
import { perms, useAccess } from "context/access"
import VidAlertDesc from "constants/vidAlertDesc";
import { Gallery, Item } from 'react-photoswipe-gallery';
import { VERIF_TYPE_DMV_CHECK } from "constants/mitek";
import { useSocketOn, useSubscribeToVidReq } from "hooks/socket";
import socketEvent from "constants/socketEvent";
import * as Yup from "yup";
import { useFormik } from "formik";
import Order from "model/order";
import { isEmpty } from 'lodash';

const VidReq = props => {

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  const { id, order, refreshListHandler } = props;

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  /********** STATE **********/

  const vidRequest = useSelector(state => state.VidRequest.NormList.vidRequests[id]);

  const [activeAccItem, setActiveAccItem] = useState(null);

  const [vidStatus, setVidStatus] = useState(null);

  const [vidStatusError, setVidStatusError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  const [isSkipConfirmationVisible, setIsSkipConfirmationVisible] = useState(false);
  const [isResubmitConfirmationVisible, setIsResubmitConfirmationVisible] = useState(false);
  const [isResubmitInProgress, setIsResubmitInProgress] = useState(false);
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  const [dropdownMenuIsOpen, setDropdownMenuIsOpen] = useState(false);

  const isInStoreOrder = () => order.customerLocation === Order.CUSTOMER_LOCATION_IN_STORE;

  /********** OTHER **********/

  const refreshVidRequest = useCallback(() => dispatch(getVidRequestNormListItem(vidRequest.id)), [vidRequest.id]);

  // runs once on component mount
  useEffect(() => {
    refreshOrderVidStatus();
  }, [id]);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    skipReason: VidRequest.SKIP_REASON_CUSTOMER_UNCOOPERATIVE,
    skipDetails: '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      skipReason: Yup.number().required("Field is required"),
      skipDetails: Yup.string().nullable(),
    }),
    onSubmit: values => {
      skipVidOrder(values);
    },
  });

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  /********** SOCKET **********/

  // start receiving updates about this particular vid request
  useSubscribeToVidReq(vidRequest.id);

  const onVidRequestChanged = useCallback(data => {
    // this socket client is shared by the entire app
    // and here we are listening for an event that might be triggered by multiple vid requests
    // therefore we need to check whether this update refers to the current vid request
    if (data.id == vidRequest.id) {
      refreshVidRequest();
    }
  }, [vidRequest.id, refreshVidRequest]);

  // listen for changes on order documents
  useSocketOn(socketEvent.vidReqChanged, onVidRequestChanged);

  /********** EVENT HANDLERS **********/

  const toggleAccItem = itemNum => {
    if (activeAccItem == itemNum) {
      setActiveAccItem(null);
    } else {
      setActiveAccItem(itemNum);
    }
  }

  const refreshOrderVidStatus = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the data
    getOrderVidStatus(id)
      .then(response => {
        setVidStatus(response.vid);
      })
      .catch(ex => {
        setVidStatusError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  const skipVidOrder = () => {
    const data = {
      skipReason: formik.values.skipReason,
      skipDetails: formik.values.skipDetails || null,
    };

    skipVid(order.id, data)
      .then(() => {
        showSuccess("ID verification for this order is now skipped");
        setIsSkipConfirmationVisible(false);
        formik.resetForm();
        refreshVidRequest();
      })
      .catch((error) => {
        showError("Unable to skip ID verification");
      });
  };

  const resubmitVid = () => {
    setIsResubmitInProgress(true);
    resubmitVidRequest(vidRequest.id)
      .then(() => {
        showSuccess("ID verification started");
        setIsResubmitConfirmationVisible(false);
      })
      .catch(error => {
        showError("Unable to resubmit vid request for verification");
      })
      .finally(() => {
        setIsResubmitInProgress(false);
      });
  };

  const deleteVid = () => {
    setIsDeleteInProgress(true);
    deleteVidRequest(vidRequest.id)
      .then(() => {
        showSuccess("ID verification attempt deleted");
        refreshListHandler();
      })
      .catch(error => {
        showError("Unable to delete ID verification attempt");
      })
      .finally(() => {
        setIsDeleteInProgress(false);
        setIsDeleteConfirmationVisible(false);
      });
  };


  /********** OTHER **********/

  let sortedVerifications = []

  // move DMV Check verification to the beginning of the list
  if (vidRequest?.autoResponse?.verifications) {
    const { autoResponse: { verifications } } = vidRequest;

    sortedVerifications = verifications.filter(v => v.verificationType !== VERIF_TYPE_DMV_CHECK);

    const dmvCheckVerification = verifications.find(v => v.verificationType === VERIF_TYPE_DMV_CHECK);
    dmvCheckVerification && sortedVerifications.unshift(dmvCheckVerification);
  }

  const isAddSigner = vidRequest.signer.position > 1;

  let customerName = '--',
    customerAddress = '--',
    customerBirthDate = '--',
    customerDocumentNumber = '--',
    customerExpirationDate = '--';
  if (!!vidRequest) {
    customerName = vidRequest.signer.firstName + ' ' + vidRequest.signer.lastName;
    if (vidRequest.autoResponse) {
      const customerInfo = vidRequest.autoResponse.customerInfo;
      if (!!customerInfo && !isEmpty(customerInfo)) {
        customerName = customerInfo.name.fullName;
        customerAddress = `${customerInfo.address.addressLine1}, ${customerInfo.address.city}, ${customerInfo.address.stateProvince}, ${customerInfo.address.postalCode}`;
        // we do not want timezone conversion here so make sure to format in UTC
        customerBirthDate = formatUtcDate(Date.parse(customerInfo.birthDate), formats.US_DATE);
        customerDocumentNumber = customerInfo.documentNumber;
        // we do not want timezone conversion here so make sure to format in UTC
        customerExpirationDate = formatUtcDate(Date.parse(customerInfo.expirationDate), formats.US_DATE);
      }
    }
  }

  const getImageUrl = image => getBeUrl(`vid-request/${vidRequest.id}/image/${image}`);

  const hasAllImages = useCallback(() => {
    if (vidRequest.idType == VidRequest.ID_TYPE_PASSPORT && !vidRequest.hasPassportImage) {
      return false;
    }
    if (vidRequest.idType == VidRequest.ID_TYPE_DRIVER_LICENSE && (!vidRequest.hasFrontImage || !vidRequest.hasBackImage)) {
      return false;
    }
    if (vidRequest.isSelfieRequired && !vidRequest.hasSelfieImage) {
      return false;
    }
    return true;
  }, [vidRequest]);

  // do not skip if vid is passed or already skipped
  const isVidStatusValid = vidRequest?.status != VidRequest.STATUS_PASSED && vidRequest?.status != VidRequest.STATUS_SKIPPED;
  // only pending vid orders can skip vid
  const isOrderStatusValid = order.status == Order.STATUS_PENDING_VID;
  // only certain conditions allow for resubmit
  const canResubmit = iAmGranted(perms.resubmit_vid_requests) && vidRequest.isActive && [VidRequest.STATUS_FAILED, VidRequest.STATUS_MITEK_API_FAIL].includes(vidRequest?.status) && hasAllImages();
  // only certain conditions allow for delete
  const canDelete = iAmGranted(perms.delete_vid_requests) && vidRequest.isActive && vidRequest.status < VidRequest.STATUS_SKIPPED && vidRequest.attemptNum > 1;

  return <React.Fragment>
    {vidRequest && <Row>
      <Col xl="8">
        <Row>
          <Col>
            <Card className={classnames({ blue: isAddSigner })}>
              <CardBody>
                <Gallery options={{ mainClass: 'vid-request-gallery' }}>
                  <Row>
                    {vidRequest.idType != VidRequest.ID_TYPE_PASSPORT && <React.Fragment>
                      <Col>
                        <strong className="vid-image-title mb-3">Front of the ID</strong>
                        {!!vidRequest.hasFrontImage && <div className="vid-image">
                          <Item content={<div className="vid-image-preview"><img src={getImageUrl(config.VID_REQ_IMAGE_FRONT)} /></div>}>
                            {({ ref, open }) => (<img src={getImageUrl(config.VID_REQ_IMAGE_FRONT)} ref={ref} onClick={open} />)}
                          </Item>
                        </div>}
                        {!vidRequest.hasFrontImage && <NoImg />}
                      </Col>
                      <Col>
                        <strong className="vid-image-title mb-3">Back of the ID</strong>
                        {!!vidRequest.hasBackImage && <div className="vid-image">
                          <Item content={<div className="vid-image-preview"><img src={getImageUrl(config.VID_REQ_IMAGE_BACK)} /></div>}>
                            {({ ref, open }) => (<img src={getImageUrl(config.VID_REQ_IMAGE_BACK)} ref={ref} onClick={open} />)}
                          </Item>
                        </div>}
                        {!vidRequest.hasBackImage && <NoImg />}
                      </Col>
                      {!!vidRequest.isSelfieRequired &&
                        <Col>
                          <strong className="vid-image-title mb-3">Selfie</strong>
                          {!!vidRequest.hasSelfieImage && <div className="vid-image">
                            <Item content={<div className="vid-image-preview"><img src={getImageUrl(config.VID_REQ_IMAGE_SELFIE)} /></div>}>
                              {({ ref, open }) => (<img src={getImageUrl(config.VID_REQ_IMAGE_SELFIE)} ref={ref} onClick={open} />)}
                            </Item>
                          </div>}
                          {!vidRequest.hasSelfieImage && <NoImg />}
                        </Col>
                      }
                    </React.Fragment>}
                    {vidRequest.idType == VidRequest.ID_TYPE_PASSPORT && <React.Fragment>
                      <Col>
                        <strong className="vid-image-title mb-3">Passport</strong>
                        {!!vidRequest.hasPassportImage && <div className="vid-image">
                          <Item content={<div className="vid-image-preview"><img src={getImageUrl(config.VID_REQ_IMAGE_PASSPORT)} /></div>}>
                            {({ ref, open }) => (<img src={getImageUrl(config.VID_REQ_IMAGE_PASSPORT)} ref={ref} onClick={open} />)}
                          </Item>
                        </div>}
                        {!vidRequest.hasPassportImage && <NoImg />}
                      </Col>
                      <Col>
                        <strong className="vid-image-title mb-3">Selfie</strong>
                        {!!vidRequest.hasSelfieImage && <div className="vid-image">
                          <Item content={<div className="vid-image-preview"><img src={getImageUrl(config.VID_REQ_IMAGE_SELFIE)} /></div>}>
                            {({ ref, open }) => (<img src={getImageUrl(config.VID_REQ_IMAGE_SELFIE)} ref={ref} onClick={open} />)}
                          </Item>
                        </div>}
                        {!vidRequest.hasSelfieImage && <NoImg />}
                      </Col>
                    </React.Fragment>}
                  </Row>
                </Gallery>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {vidRequest?.status === VidRequest.STATUS_SKIPPED && <Row>
          <Col>
            <Card className="text-center">
              <CardHeader className="bg-transparent pt-3 pb-0">
                <Row>
                  <Col>
                    <div className="card-title my-2">Identity Validation Skipped</div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <img className="mb-2" src={vidSkippedIcon} />
              </CardBody>
            </Card>
          </Col>
        </Row>}
        <Row>
          <Col>
            <Card className={classnames('expand-v', { blue: isAddSigner })}>
              <CardHeader className="bg-transparent pt-3 pb-0">
                <Row>
                  <Col>
                    <div className="card-title mt-2 mb-0">{isAddSigner ? 'Additional signer information' : 'Customer information'}</div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table className="section-rows table bt-1">
                  <tbody>
                    <tr>
                      <td className="section-row-label">Customer</td>
                      <td className="section-row-value">{customerName}</td>
                    </tr>
                    <tr>
                      <td className="section-row-label">Customer address</td>
                      <td className="section-row-value">{customerAddress}</td>
                    </tr>
                    <tr>
                      <td className="section-row-label">Date of birth</td>
                      <td className="section-row-value">{customerBirthDate}</td>
                    </tr>
                    <tr>
                      <td className="section-row-label">Document number</td>
                      <td className="section-row-value">{customerDocumentNumber}</td>
                    </tr>
                    <tr>
                      <td className="section-row-label">Document expiration date</td>
                      <td className="section-row-value">{customerExpirationDate}</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col xl="4">
        <Card className={classnames('vid-response-card', { blue: isAddSigner })}>
          <CardBody>
            <div className="skipBtnParent">
              <p className="vid-status-text"><strong title={'Id: ' + vidRequest.id}>Status:</strong> <strong className={`text-${VidRequest.getStatusColor(vidRequest.status)}`}>{vidRequest.statusName}</strong></p>
              {iAmGranted(perms.skip_vid) && order.isNotaryRequired && order.isVidRequired && isVidStatusValid && isOrderStatusValid &&
                <Button color="primary" className="ms-2" onClick={() => { setIsSkipConfirmationVisible(true) }}>Skip VID <i className="mdi mdi-chevron-double-right ms-1"></i></Button>}
              {(canResubmit || canDelete) && <Dropdown isOpen={dropdownMenuIsOpen} toggle={() => setDropdownMenuIsOpen(value => !value)} className="d-inline-block ms-2 mb-2 align-middle">
                <DropdownToggle tag="button" className="btn btn-default card-menu-btn">
                  <i className="bx bx-dots-horizontal-rounded" />
                </DropdownToggle>
                <DropdownMenu end>
                  {canResubmit &&
                    <DropdownItem onClick={() => setIsResubmitConfirmationVisible(true)} disabled={isResubmitInProgress}>Resubmit</DropdownItem>}
                  {canDelete &&
                    <DropdownItem onClick={() => setIsDeleteConfirmationVisible(true)} disabled={isDeleteInProgress}>Delete</DropdownItem>}
                </DropdownMenu>
              </Dropdown>}
            </div>
            {vidRequest.status == VidRequest.STATUS_SKIPPED &&
              <div className="pt-1 pb-3">
                <span><b>Reason of skipping: </b></span><br />
                <span>{VidRequest.getSkipReason(vidRequest.skipReason) || '--'}</span>
                <br />
                <span><b>Skip Details: </b></span><br />
                <span>{vidRequest.skipDetails || '--'}</span>
              </div>
            }
            <p><strong>Date:</strong> {vidRequest.updatedTs ? formatTimestamp(vidRequest.updatedTs, formats.DATETIME) : '--'}</p>
            {vidRequest.assistStatus > 0 &&
              <div className="vid-accordion-container mb-4">
                <div className="vid-accordion-assist">
                  <span><b>Agent Assist Status: </b></span>
                  <span>{VidRequest.getStatusName(vidRequest.assistStatus)}</span>
                </div>
                {vidRequest.assistResponse && vidRequest.assistResponse.details && <>
                  <div className="vid-accordion-assist">
                    <span><b>Document Authenticity: </b></span>
                    <span>{vidRequest.assistResponse.details.authenticity.result}</span>
                  </div>
                  <div className="vid-accordion-assist">
                    <span><b>Document Originality: </b></span>
                    <span>{vidRequest.assistResponse.details.originality.result}</span>
                  </div>
                  <div className="vid-accordion-assist">
                    <span><b>Document Validity:  </b></span>
                    <span>{vidRequest.assistResponse.details.validity.result}</span>
                  </div>
                </>}
                {vidRequest.assistResponse && vidRequest.assistResponse.result && <>
                  <div className="vid-accordion-assist">
                    <span><b>Assist reason: </b></span>
                    <span>{vidRequest.assistResponse.reasons[0]}</span>
                  </div>
                </>}
              </div>
            }
            {vidRequest.hasSignatureImage &&
              <div className="d-flex justify-content-between align-items-center">
                <p className="font-size-16"><strong>Signature:</strong></p>
                <img className="vid-signature-image" src={getImageUrl(config.VID_REQ_IMAGE_SIGNATURE)} />
              </div>
            }

            {vidRequest?.status === VidRequest.STATUS_SKIPPED && <div className="identity-validation-skipped mt-5">
              <div className="d-flex">
                <img src={isolationIcon} />
                <div className="ms-3">
                  <div className="d-flex align-items-center mb-2">
                    <img src={skippedVidIcon} />
                    <div className="title">Identity Validation Skipped</div>
                  </div>
                  <div>The Identity Validation process was Skipped.</div>
                </div>
              </div>
            </div>}

            {!!vidRequest.autoResponse && <React.Fragment>
              <p className="mb-2"><strong>Verifications alerts:</strong></p>
              <div className="vid-accordion-container">
                <div className="vid-accordion-head">
                  <span>NAME</span>
                  <span>SCORE</span>
                </div>
                <div className="vid-accordion-list">
                  <Accordion flush>
                    {sortedVerifications.map((item, index) => {
                      const score = parseInt(item.probability / 1000 * 100);
                      return <AccordionItem key={index}>
                        <h2 className="accordion-header">
                          <button type="button" className={classnames('accordion-button fw-medium', { collapsed: activeAccItem != index })} onClick={() => toggleAccItem(index)}>
                            <span>{item.name}</span>
                            <strong className={classnames({ 'text-danger': score < 50, 'text-warning': score < 100, 'text-success': score == 100 })} data-probability={item.probability}>{score}%</strong>
                          </button>
                        </h2>
                        <Collapse isOpen={activeAccItem == index} className="accordion-collapse">
                          <div className="accordion-body">
                            {!!VidAlertDesc[item.verificationType] && <p className="vid-alert-desc">{VidAlertDesc[item.verificationType]}</p>}
                            {Object.keys(item.notifications).length > 0 && <div>
                              <p className="mb-1">Notification:</p>
                              <ul className="vid-alert-notifications">
                                {Object.entries(item.notifications).map((notification, index2) => <li key={index + '-' + index2} className="mb-1">({notification[0]}) {notification[1]}</li>)}
                              </ul>
                            </div>}
                          </div>
                        </Collapse>
                      </AccordionItem>
                    })}
                  </Accordion>
                </div>
                <div className="vid-accordion-device">
                  <p><strong>Device:</strong> {vidRequest.device}</p>
                  <p><strong>OS:</strong> {vidRequest.os}</p>
                  <p><strong>Dossier Id:</strong> {vidRequest.rawResponse.dossierMetadata.dossierId}</p>
                  {!isInStoreOrder() && <p><strong>Browser:</strong> {vidRequest.browser}</p>}
                </div>
              </div>
            </React.Fragment>}
          </CardBody>
        </Card>
        {isSkipConfirmationVisible && <Confirmation
          style={{ backgroundColor: 'white', minWidth: 600 }}
          title="Skip VID Reason"
          confirmBtnText="Confirm"
          customIcon={skipVidIcon}
          onConfirm={() => {
            formik.handleSubmit();
          }}
          onCancel={() => {
            setIsSkipConfirmationVisible(false);
            formik.resetForm();
          }}
          closeOnClickOutside={false}
        >
          <div className="my-3 mx-4 text-start">
            <Label className="form-label">Reason:</Label>
            <div className="form-check my-2">
              <input
                className="form-check-input"
                type="radio"
                name="customerUncooperative"
                id="customerUncooperative"
                value={VidRequest.SKIP_REASON_CUSTOMER_UNCOOPERATIVE}
                checked={formik.values.skipReason == VidRequest.SKIP_REASON_CUSTOMER_UNCOOPERATIVE}
                onChange={e => formik.setFieldValue('skipReason', Number(e.target.value))}
              />
              <label className="form-check-label" htmlFor="customerUncooperative">Customer Uncooperative</label>
            </div>
            <div className="form-check my-2">
              <input
                className="form-check-input"
                type="radio"
                name="customerUnableToComplete"
                id="customerUnableToComplete"
                value={VidRequest.SKIP_REASON_CUSTOMER_UNABLE_TO_COMPLETE}
                checked={formik.values.skipReason == VidRequest.SKIP_REASON_CUSTOMER_UNABLE_TO_COMPLETE}
                onChange={e => formik.setFieldValue('skipReason', Number(e.target.value))}
              />
              <label className="form-check-label" htmlFor="customerUnableToComplete">Customer Unable to Complete (tech/ability)</label>
            </div>
            <div className="form-check my-2">
              <input
                className="form-check-input"
                type="radio"
                name="dealershipOverride"
                id="dealershipOverride"
                value={VidRequest.SKIP_REASON_DEALERSHIP_OVERRIDE}
                checked={formik.values.skipReason == VidRequest.SKIP_REASON_DEALERSHIP_OVERRIDE}
                onChange={e => formik.setFieldValue('skipReason', Number(e.target.value))}
              />
              <label className="form-check-label" htmlFor="dealershipOverride">Dealership Override</label>
            </div>
            <div className="form-check my-2">
              <input
                className="form-check-input"
                type="radio"
                name="manualApproval"
                id="manualApproval"
                value={VidRequest.SKIP_REASON_MANUAL_APPROVAL}
                checked={formik.values.skipReason == VidRequest.SKIP_REASON_MANUAL_APPROVAL}
                onChange={e => formik.setFieldValue('skipReason', Number(e.target.value))}
              />
              <label className="form-check-label" htmlFor="manualApproval">Manual Approval (Images provided)</label>
            </div>
          </div>
          <div className="my-4 mx-4 text-start">
            <Label className="form-label">Additional Info:</Label>
            <TextareaAutosize
              minRows={3}
              placeholder='Your message'
              maxRows={7}
              className={classnames("form-control", { "is-invalid": !!formik.errors.skipDetails })}
              name="skipDetails"
              onChange={formik.handleChange}
              onFocus={onFieldFocused}
              value={formik.values.skipDetails}
            />
          </div>
          {!!formik.errors.skipDetails && <FormFeedback type="invalid" className="d-block">{formik.errors.skipDetails}</FormFeedback>}
        </Confirmation>}
        {isResubmitConfirmationVisible && <Confirmation
          confirmBtnText="Resubmit"
          onConfirm={() => {
            setIsResubmitConfirmationVisible(false);
            resubmitVid();
          }}
          onCancel={() => setIsResubmitConfirmationVisible(false)}>
          <span style={{ color: '#556EE6' }}>Are you sure you want to resubmit vid request &quot;{vidRequest.id}&quot;? The same pictures will be sent for Identity Verification. Please wait some moments for the updated result to be displayed</span>
        </Confirmation>}
        {isDeleteConfirmationVisible && <Confirmation
          confirmBtnText="Delete"
          onConfirm={() => {
            setIsDeleteConfirmationVisible(false);
            deleteVid();
          }}
          onCancel={() => setIsDeleteConfirmationVisible(false)}>
          <span style={{ color: '#556EE6' }}>Are you sure you want to delete vid request &quot;{vidRequest.id}&quot;?</span>
        </Confirmation>}
      </Col>
    </Row>
    }
  </React.Fragment >
}

VidReq.propTypes = {
  id: PropTypes.number.isRequired,
  order: PropTypes.object,
  refreshListHandler: PropTypes.func,
};

export default VidReq;
