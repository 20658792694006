import { takeEvery, put, call } from "redux-saga/effects";
import {

  GET_CRON_JOB_LIST,

} from './actionTypes';

import {

  getCronJobListOk,
  getCronJobListErr,

} from './actions';

import {

  getCronJobs,

} from 'helpers/backendHelper'


/********** LIST **********/

function* onGetCronJobList() {
  try {
    const response = yield call(getCronJobs);
    yield put(getCronJobListOk(response));
  } catch (error) {
    yield put(getCronJobListErr(error));
  }
}

function* cronJobSaga() {
  yield takeEvery(GET_CRON_JOB_LIST, onGetCronJobList);
}

export default cronJobSaga;