import {
  getCustomerPerformance,
  getOrderStats,
  getStoreLeaderboard,
  getTotalMessages,
  getSchedulerChats,
  getSchedulerInProgressOrders,
  getAppointments
} from "helpers/backendHelper";

import { takeEvery, put, call } from "redux-saga/effects";

import {
  getCustomerPerformanceErr,
  getCustomerPerformanceOk,

  getLeaderboardOk,
  getLeaderboardErr,

  getLeaderboardListOk,
  getLeaderboardListErr,

  getSupportStatsOk,
  getSupportStatsErr,

  getSchedulerChatsOk,
  getSchedulerChatsErr,

  getSchedulerProgressOrdersOk,
  getSchedulerProgressOrdersErr,

  getAppointmentsListOk,
  getAppointmentsListErr,

} from "./actions";

import {
  GET_LEADERBOARD_LIST,

  GET_CUSTOMER_PERFORMANCE,

  GET_LEADERBOARD,

  GET_SUPPORT_STATS,

  GET_SCHEDULER_CHATS,

  GET_SCHEDULER_PROGRESS_ORDERS,
  GET_APPOINTMENTS_LIST
} from "./actionTypes";


/********** CUSTOMER PERFORMANCE **********/

function* onGetCustomerPerformance({ payload: { unit, customerLocation } }) {
  try {
    const response = yield call(getCustomerPerformance, unit, customerLocation);
    yield put(getCustomerPerformanceOk(response));
  } catch (error) {
    yield put(getCustomerPerformanceErr(error));
  }
}

/********** LEADERBOARD **********/

function* onGetLeaderboard({ payload: { params } }) {
  try {
    const response = yield call(getStoreLeaderboard, params);
    yield put(getLeaderboardOk(response));
  } catch (error) {
    yield put(getLeaderboardErr(error));
  }
}

/********** LEADERBOARD LIST **********/

function* onGetLeaderboardList({ payload: { params } }) {
  try {
    const response = yield call(getStoreLeaderboard, params);
    yield put(getLeaderboardListOk(response));
  } catch (error) {
    yield put(getLeaderboardListErr(error));
  }
}

/********** STATS **********/

function* onGetStats() {
  try {
    const { workInProgress, remoteVid, sealed, remote, instore } = yield call(getOrderStats);
    const { totalMessages } = yield call(getTotalMessages);

    yield put(getSupportStatsOk({ workInProgress, remoteVid, sealed, remote, instore, totalMessages }));
  } catch (error) {
    yield put(getSupportStatsErr(error));
  }
}

/********** SCHEDULER CHATS **********/

function* onGetSchedulerChats() {
  try {
    const response = yield call(getSchedulerChats);
    yield put(getSchedulerChatsOk(response));
  } catch (error) {
    yield put(getSchedulerChatsErr(error));
  }
}

/********** SCHEDULER IN PROGRESS ORDERS **********/

function* onGetSchedulerProgressOrders({ payload: { params } }) {
  try {
    const response = yield call(getSchedulerInProgressOrders, params);
    yield put(getSchedulerProgressOrdersOk(response));
  } catch (error) {
    yield put(getSchedulerProgressOrdersErr(error));
  }
}

/********** APPOINTMENTS LIST **********/

function* onGetAppointmentsList({ payload: { params } }) {
  try {
    const response = yield call(getAppointments, params);
    yield put(getAppointmentsListOk(response));
  } catch (error) {
    yield put(getAppointmentsListErr(error))
  }
}

function* dashboardSaga() {
  yield takeEvery(GET_CUSTOMER_PERFORMANCE, onGetCustomerPerformance);
  yield takeEvery(GET_LEADERBOARD, onGetLeaderboard);
  yield takeEvery(GET_LEADERBOARD_LIST, onGetLeaderboardList);
  yield takeEvery(GET_SUPPORT_STATS, onGetStats);
  yield takeEvery(GET_SCHEDULER_CHATS, onGetSchedulerChats);
  yield takeEvery(GET_SCHEDULER_PROGRESS_ORDERS, onGetSchedulerProgressOrders);
  yield takeEvery(GET_APPOINTMENTS_LIST, onGetAppointmentsList);
}

export default dashboardSaga;