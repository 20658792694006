import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Row, CardHeader, Alert } from "reactstrap";
import Col from "components/Shared/Col";
import PropTypes from "prop-types";
import { route, routes } from "helpers/routeHelper";
import Message from "model/message";
import { formats, formatTimestamp } from "helpers/dateHelper";
import SpinnerChase from "components/Shared/SpinnerChase";
import { useSocketOn, useSubscribeToOrderMessages } from "hooks/socket";
import socketEvent from "constants/socketEvent";
import { getBeUrl } from "helpers/utilHelper";
import { getAllOrderMessages } from "helpers/backendHelper";

const SectionMessages = props => {

  const { id } = props;

  /********** STATE **********/

  const [lastMessage, setLastMessage] = useState(null);
  const [lastMessageError, setLastMessageError] = useState(null);
  const [isLoadMessageInProgress, setIsLoadMessageInProgess] = useState(false);


  /********** SOCKET **********/

  // start receiving messages updates
  useSubscribeToOrderMessages();

  const onMessageReceived = useCallback(() => refreshLastMessage(), [id]);

  // listen for changes on messages
  useSocketOn(socketEvent.messageReceived, onMessageReceived);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the data
    refreshLastMessage()
  }, [id]);

  /********** OTHER **********/

  const refreshLastMessage = () => {
    setIsLoadMessageInProgess(true);
    getAllOrderMessages(id, { page: 1, pageSize: 1, sortBy: 'id', sortDir: 'desc' })
      .then(response => {
        const message = response.messages[0];
        setLastMessage(message);
      })
      .catch(ex => {
        setLastMessageError(ex);
      })
      .finally(() => {
        setIsLoadMessageInProgess(false);
      });
  };

  const getMessageThumbnailUrl = messageId => getBeUrl(`message/${messageId}/thumbnail.png`);

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Messages</div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="pb-4 d-flex flex-column justify-content-between">
        {!lastMessageError && !isLoadMessageInProgress && (!!lastMessage ? <div className="last-message-wrap">
          <div className="conversation-name">
            {lastMessage.senderName}
          </div>
          {lastMessage.contentType == Message.CONTENT_TYPE_IMAGE && <div className="message-image-content">
            <img src={getMessageThumbnailUrl(lastMessage.id)} />
          </div>}
          {lastMessage.contentType == Message.CONTENT_TYPE_TEXT && <p className="message-content">{lastMessage.content}</p>}
          <div className="chat-time mb-0">
            <i className="bx bx-time-five align-middle me-1" />
            {formatTimestamp(lastMessage.createdTs, formats.HOUR)}
          </div>
        </div> : 'No messages')}
        {!!lastMessageError && <div>
          <Alert color="danger" className="fade show text-center">
            <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load message
          </Alert>
        </div>}
        {isLoadMessageInProgress && <SpinnerChase className="sm mt-auto mb-auto" />}
        <div className="text-end mt-4">
          <Link to={route(routes.view_order_messages, [id, lastMessage?.channel || Message.CHANNEL_SUPPORT_DEALER_CUSTOMER])} className='btn btn-primary'>View All Messages <i className="mdi mdi-arrow-right ms-1"></i></Link>
        </div>
      </CardBody>
    </Card>
  </React.Fragment>
}

SectionMessages.propTypes = {
  id: PropTypes.number,
};

export default SectionMessages;