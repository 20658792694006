import { all, fork } from 'redux-saga/effects';

import AuthSaga from './auth/saga';
import LayoutSaga from './layout/saga';
import UserSaga from './user/saga';
import UserRoleSaga from './userRole/saga';
import DealerGroupSaga from './dealerGroup/saga';
import DealerRegionSaga from './dealerRegion/saga';
import DealerStoreSaga from './dealerStore/saga';
import PaymentPlanSaga from './paymentPlan/saga';
import MessageSaga from './message/saga';
import SettingsSaga from './settings/saga';
import DashboardSaga from './dashboard/saga';
import OrderSaga from './order/saga';
import OrderDocSaga from './orderDoc/saga';
import OrderFeesSaga from './orderFees/saga';
import NotificationSaga from './notification/saga';
import SupportCaseSaga from './supportCase/saga';
import VidRequestSaga from './vidRequest/saga';
import NotarySaga from './notary/saga';
import AdditionalFeeSaga from './additionalFee/saga';
import ReportSaga from './report/saga';
import PaymentSaga from './payment/saga';
import DealerStatementSaga from './statement/dealer/saga';
import NotaryStatementSaga from './statement/notary/saga';
import BiddingSaga from './bidding/saga';
import CronJob from './cronJob/saga';
import CredentialSaga from './credential/saga';

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(UserSaga),
    fork(UserRoleSaga),
    fork(DealerGroupSaga),
    fork(DealerRegionSaga),
    fork(DealerStoreSaga),
    fork(PaymentPlanSaga),
    fork(MessageSaga),
    fork(SettingsSaga),
    fork(DashboardSaga),
    fork(OrderSaga),
    fork(OrderDocSaga),
    fork(OrderFeesSaga),
    fork(NotificationSaga),
    fork(SupportCaseSaga),
    fork(VidRequestSaga),
    fork(NotarySaga),
    fork(ReportSaga),
    fork(AdditionalFeeSaga),
    fork(PaymentSaga),
    fork(DealerStatementSaga),
    fork(NotaryStatementSaga),
    fork(BiddingSaga),
    fork(CronJob),
    fork(CredentialSaga),
  ])
}
