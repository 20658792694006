import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Button, Form, Label, FormFeedback, Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap';
import Select from 'react-select';
import { useFormik } from 'formik';
import {
  dictionaryToSelectOptions,
  extractDtFiltersFromUrl,
  getGranularStatusOptions,
  hasNonEmpty,
  removeDtFiltersFromUrl,
  showError,
  toSelectOptions
} from 'helpers/utilHelper';
import { applyOrderDtFilters, clearOrderDtFilters, unsetOrderDtFilters, patchOrderDtFilters, doOrderDtFiltersCleanup, doDealerGroupListCleanup, doDealerStoreListCleanup, getDealerGroupList, getDealerStoreList } from 'store/actions';
import { isEmpty, omit } from 'lodash';
import Order from 'model/order';
import VidRequest from 'model/vidRequest'
import { getNotaryUserList, getSchedulerUserList, getDealerUserList } from "helpers/backendHelper";

const serviceFilterMap = [
  { value: "vid", label: "Identity Verification" },
  { value: "eSign", label: "E-Sign Contracts" },
  { value: "ink", label: "Ink-Sign Contracts" },
  { value: "addSigner", label: "Co-signer Contracts" },
  { value: "rush", label: "Rush order" },
  { value: "notary", label: "Notary service" },
  { value: "preview", label: "Preview Docs" },
];

const DataTableFilters = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /********** STATE **********/

  const filters = useSelector(state => state.Order.DtFilters);
  const { groups, groupsError } = useSelector(state => state.DealerGroup.List);
  const { stores, storesError } = useSelector(state => state.DealerStore.List);
  const [creator, setCreator] = useState([]);
  const [notaries, setNotaries] = useState([]);
  const [schedulers, setSchedulers] = useState([]);

  // is the filters form visible or not
  // used to show/hide the filters form
  const [isVisible, setIsVisible] = useState(false);

  /********** FORM CONFIG **********/

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      ...filters,
      includeService: [],
      includeStatus: [],
      vidStatuses: [],
      incAlert: [],
    },
    onSubmit: values => applyFilters(values),
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    dispatch(getDealerGroupList());
    dispatch(getDealerStoreList());
    return () => {
      // state cleanup on component unmount
      dispatch(doDealerGroupListCleanup());
      dispatch(doDealerStoreListCleanup());
    }
  }, []);

  // runs once on component mount
  useEffect(() => {
    getNotaryListOptions();
    getSchedulerListOptions();
    getCreatorList();
  }, []);

  // runs whenever 'groupsError' changes
  // which may happen after the first remote call
  useEffect(() => {
    if (groupsError) {
      // set an error on the form field
      formik.setFieldError("dealerGroup", "Unable to load groups");
    }
  }, [groupsError]);
  // runs whenever 'storesError' changes
  // which may happen after the first remote call
  useEffect(() => {
    if (storesError) {
      // set an error on the form field
      formik.setFieldError("dealerStore", "Unable to load stores");
    }
  }, [storesError]);

  // runs once on component mount
  useEffect(() => {
    // extract filters from url
    // they will be applied by default as the user enters the screen
    const filters = extractDtFiltersFromUrl();

    // if filters are not empty, it means we are on a `view all` screen
    if (!isEmpty(filters)) {
      // in this case, replace the old filters with the relevant ones
      applyFilters(filters);
    } else {
      // make sure to call this even if there are no filters in the url
      // because we need to switch the '_set' flag
      dispatch(patchOrderDtFilters(filters));
    }

    return () => {
      // this helps us cleanup filters only when on a `view all` filtered screen
      if (!isEmpty(filters)) {
        dispatch(doOrderDtFiltersCleanup());
      } else {
        dispatch(unsetOrderDtFilters()); // persist filters applied by user, but mark them as unset
      }
    }
  }, []);

  /********** EVENT HANDLERS **********/

  // shows/hides the filters form
  const toggleFilters = () => setIsVisible(!isVisible);

  // event handler for the 'apply-filters' button
  const applyFilters = values => {
    dispatch(applyOrderDtFilters(values));
  }

  // event handler for the 'clear-filters' button
  const clearFilters = () => {
    // reset form fields
    formik.setValues({
      ...formik.initialValues,
      includeService: [], // Clear selected values in "Services included"
      includeStatus: [], // Clear selected values in "Status included"
      vidStatuses: [], // Clear selected values in "Vid Status"
      incAlert: [], // Clear selected values in "Alert"
    });
    // reset state
    dispatch(clearOrderDtFilters());
    // clear url filters
    navigate({ search: removeDtFiltersFromUrl() }, { replace: true });
  }

  // load state filters into local filters
  // state filters = applied filters that are send to backend
  // local filters = state vars bound to form controls
  // this is fired each time the offcanvas is opened
  // to discard anything the user might have typed in the fields (and not applied) before closing the offcanvas
  const initLocalFilters = () => formik.setValues(filters);

  const setServicesOptions = (serviceList) => {
    const selectedValues = serviceList.map((serviceItem) => serviceItem.value);
    formik.setFieldValue("includeService", selectedValues);
  }

  const setStatusOptions = (statusList) => {
    const selectedValues = statusList.map((serviceItem) => serviceItem.value);
    formik.setFieldValue("includeStatus", selectedValues);
  }

  const setVidOptions = (statusList) => {
    const selectedValues = statusList.map((serviceItem) => serviceItem.value);
    formik.setFieldValue("vidStatuses", selectedValues);
  }

  const setAlertOptions = (alertList) => {
    const selectedValues = alertList.map((serviceItem) => serviceItem.value);
    formik.setFieldValue("incAlert", selectedValues);
  }

  /********** OTHER **********/

  const getCreatorList = () => {
    getDealerUserList()
      .then((response) => {
        const creatorsList = response.users.map(item => ({ id: item.id, name: item.fullName }))
        setCreator(creatorsList);
      })
      .catch(err => {
        showError('Unable to load data');
      })
  }

  const getNotaryListOptions = () => {
    getNotaryUserList()
      .then((response) => {
        // Transform the response array of notaries
        // to be like {id: notaryId, name: notaryName} for the select component
        const notariesList = response.map(notary => ({ id: notary.id, name: notary.userFullName }));
        setNotaries(notariesList);
      })
      .catch(() => {
        showError("Unable to load data.");
      })
  }

  const getSchedulerListOptions = () => {
    getSchedulerUserList()
      .then(response => {
        // Transform the response array of schedulers
        // to be like {id: schedulerId, name: schedulerName} for the select component
        const schedulersList = response.map(scheduler => ({ id: scheduler.id, name: scheduler.fullName }));
        setSchedulers(schedulersList);
      })
      .catch(() => {
        showError("Unable to load data.");
      });
  };

  const hasFilters = () => hasNonEmpty(omit(filters, "_set"));

  // adds 'All' to the list of groups
  // prepares the list to be used as select options
  const getGroupOptions = () => {
    return [{ label: 'All', value: '' }, ...toSelectOptions(groups)];
  }
  // adds 'All' to the list of stores
  // prepares the list to be used as select options
  const getStoreOptions = () => {
    return [{ label: 'All', value: '' }, ...toSelectOptions(stores)];
  }

  // adds 'All' to the list of creators
  // prepares the list to be used as select options
  const getCreatorOptions = () => {
    return [{ label: 'All', value: '' }, ...toSelectOptions(creator)];
  }

  // adds 'All' to the list of notaries
  // prepares the list to be used as select options
  const getNotaryOptions = () => {
    return [{ label: 'All', value: '' }, ...toSelectOptions(notaries)];
  }

  // adds 'All' to the list of schedulers
  // prepares the list to be used as select options
  const getSchedulerOptions = () => {
    return [{ label: 'All', value: '' }, ...toSelectOptions(schedulers)];
  }

  const statusOptions = getGranularStatusOptions();

  const getVidOptions = () => dictionaryToSelectOptions(VidRequest.getVidStatusesForOrders());

  const getAlertOptions = () => dictionaryToSelectOptions(Order.getImmediateReviewOptions());

  const getCustomerLocationOptions = () => dictionaryToSelectOptions(Order.getCustomerLocationMap());

  const getBillingStatusOptions = () => dictionaryToSelectOptions(Order.getBillingStatusFiltersMap());

  return <React.Fragment>
    <div className="btn-group ms-2 mb-2" >
      <Button type="button" color="dark" onClick={toggleFilters}>
        <i className="mdi mdi-filter-variant me-1" />Filters
      </Button>
      {hasFilters() && <Button type="button" color="dark" onClick={clearFilters}>
        <i className="mdi mdi-close" />
      </Button>}
    </div>
    <Offcanvas direction="end" isOpen={isVisible} toggle={toggleFilters} onOpened={initLocalFilters}>
      <OffcanvasHeader toggle={toggleFilters}>Filters</OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className="mb-3">
            <Label>Scheduler name</Label>
            <Select
              classNamePrefix="select2-selection"
              name="schedulerId"
              options={getSchedulerOptions()}
              onChange={selected => formik.setFieldValue("schedulerId", selected.value)}
              value={getSchedulerOptions().find(option => option.value === formik.values.schedulerId)}
              className={!!formik.errors.schedulerId && "is-invalid"} />
            {!!formik.errors.schedulerId && <FormFeedback type="invalid">{formik.errors.schedulerId}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Notary name</Label>
            <Select
              classNamePrefix="select2-selection"
              name="notaryId"
              options={getNotaryOptions()}
              onChange={selected => formik.setFieldValue("notaryId", selected.value)}
              value={getNotaryOptions().find(option => option.value === formik.values.notaryId)}
              className={!!formik.errors.notaryId && "is-invalid"} />
            {!!formik.errors.notaryId && <FormFeedback type="invalid">{formik.errors.notaryId}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Creator</Label>
            <Select
              classNamePrefix="select2-selection"
              name="userId"
              options={getCreatorOptions()}
              onChange={selected => formik.setFieldValue("userId", selected.value)}
              value={getCreatorOptions().find(option => option.value === formik.values.userId)}
              className={!!formik.errors.userId && "is-invalid"} />
            {!!formik.errors.userId && <FormFeedback type="invalid">{formik.errors.userId}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Group</Label>
            <Select
              classNamePrefix="select2-selection"
              name="dealerGroup"
              options={getGroupOptions()}
              onChange={selected => formik.setFieldValue("dealerGroup", selected.value)}
              value={getGroupOptions().find(option => option.value === formik.values.dealerGroup)}
              className={!!formik.errors.dealerGroup && "is-invalid"} />
            {!!formik.errors.dealerGroup && <FormFeedback type="invalid">{formik.errors.dealerGroup}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Store</Label>
            <Select
              classNamePrefix="select2-selection"
              name="dealerStore"
              options={getStoreOptions()}
              onChange={selected => formik.setFieldValue("dealerStore", selected.value)}
              value={getStoreOptions().find(option => option.value === formik.values.dealerStore)}
              className={!!formik.errors.dealerStore && "is-invalid"} />
            {!!formik.errors.dealerStore && <FormFeedback type="invalid">{formik.errors.dealerStore}</FormFeedback>}
          </div>
          <div className="mt-2 mb-3">
            <Label>Services included</Label>
            <Select
              classNamePrefix="select2-selection"
              name="includeService"
              isMulti
              options={serviceFilterMap}
              onChange={selected => setServicesOptions(selected)}
              value={serviceFilterMap.filter(option => formik.values.includeService.includes(option.value))}
            />
          </div>
          <div className="mt-2 mb-3">
            <Label>Status included</Label>
            <Select
              classNamePrefix="select2-selection"
              name="includeStatus"
              isMulti
              options={statusOptions}
              onChange={selected => setStatusOptions(selected)}
              value={statusOptions.filter(option => formik.values.includeStatus.includes(option.value))}
            />
          </div>
          <div className="mt-2 mb-3">
            <Label>VID status</Label>
            <Select
              classNamePrefix="select2-selection"
              name="vidStatuses"
              isMulti
              options={getVidOptions()}
              onChange={selected => setVidOptions(selected)}
              value={getVidOptions().filter(option => formik.values.vidStatuses.includes(option.value))}
            />
          </div>
          <div className="mt-2 mb-3">
            <Label>Alerts</Label>
            <Select
              classNamePrefix="select2-selection"
              name="incAlert"
              isMulti
              options={getAlertOptions()}
              onChange={selected => setAlertOptions(selected)}
              value={getAlertOptions().filter(option => formik.values.incAlert.includes(option.value))}
            />
          </div>
          <div className="mt-2 mb-3">
            <Label>Order type</Label>
            <Select
              classNamePrefix="select2-selection"
              name="customerLocation"
              options={getCustomerLocationOptions()}
              onChange={selected => formik.setFieldValue("customerLocation", selected.value)}
              value={getCustomerLocationOptions().find(option => option.value === formik.values.customerLocation)}
            />
          </div>
          <div className="mt-2 mb-3">
            <Label>Billing status</Label>
            <Select
              classNamePrefix="select2-selection"
              name="billingStatus"
              options={getBillingStatusOptions()}
              onChange={selected => formik.setFieldValue("billingStatus", selected.value)}
              value={getBillingStatusOptions().find(option => option.value === formik.values.billingStatus)}
            />
          </div>
          <div className="text-end">
            <Button type="button" color="primary" className="me-2" onClick={formik.handleSubmit}>
              <i className="mdi mdi-filter me-1" />Apply
            </Button>
            <Button type="button" color="warning" onClick={clearFilters}>
              <i className="mdi mdi-eraser me-1" />Clear
            </Button>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  </React.Fragment>
}

export default DataTableFilters;