import { combineReducers } from "redux";
import {

  GET_CRON_JOB_LIST,
  GET_CRON_JOB_LIST_OK,
  GET_CRON_JOB_LIST_ERR,
  DO_CRON_JOB_LIST_CLEANUP,

} from './actionTypes';

/********* LIST *********/

const defaultListState = {
  cronJobs: [],
  cronJobsError: null,
  isLoadInProgress: false,
}

const List = (state = defaultListState, action) => {
  switch (action.type) {
    case GET_CRON_JOB_LIST:
      state = {
        ...state,
        cronJobsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_CRON_JOB_LIST_OK:
      state = {
        ...state,
        cronJobs: action.payload.response.cronJobs,
        isLoadInProgress: false,
      };
      break
    case GET_CRON_JOB_LIST_ERR:
      state = {
        ...state,
        cronJobs: [],
        cronJobsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_CRON_JOB_LIST_CLEANUP:
      state = { ...defaultListState };
      break
  }
  return state;
}

export default combineReducers({
  List,
});