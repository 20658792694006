import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
import classnames from "classnames";
import docsDeliveredIcon from "assets/images/order/docs-delivered-icon.svg";
import docsDeliveredIconWhite from "assets/images/order/docs-delivered-icon-white.svg";
import appointmentSetIcon from "assets/images/order/appointment-set-icon.svg";
import appointmentSetIconWhite from "assets/images/order/appointment-set-icon-white.svg";
import beforeSigningIcon from "assets/images/order/before-signing-icon.svg";
import beforeSigningIconWhite from "assets/images/order/before-signing-icon-white.svg";
import duringSigningIcon from "assets/images/order/during-signing-icon.svg";
import duringSigningIconWhite from "assets/images/order/during-signing-icon-white.svg";
import returnToDealershipIcon from "assets/images/order/return-to-dealership-icon.svg";
import returnToDealershipIconWhite from "assets/images/order/return-to-dealership-icon-white.svg";
import StepDocsDelivered from "./Step/DocsDelivered";
import StepSetAppointment from "./Step/SetAppointment";
import StepBeforeSigning from "./Step/BeforeSigning";
import StepDuringSigning from "./Step/DuringSigning";
import StepReturnToDealership from "./Step/ReturnToDealership";
import OrderNotary from "model/orderNotary";
import { min } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { doOrderInkSignDocsCleanup, getOrderInkSignDocs } from "store/actions";

const OrderFlowAccordion = ({ order, orderKey }) => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const [open, setOpen] = useState();

  const { docs: inkSignDocs } = useSelector(state => state.Order.InkSignDocs);

  const isDocsCompleted = inkSignDocs?.every(item => item.returnedByNotaryTs);

  const accordionItems = [
    {
      id: 1,
      status: [OrderNotary.STATUS_ASSIGNED, OrderNotary.STATUS_VIEWED], // statuses this step is available for; the other statuses are either completed or blocked
      title: "Docs are delivered",
      icon: docsDeliveredIcon,
      successIcon: docsDeliveredIconWhite,
      content: <StepDocsDelivered />
    },
    {
      id: 2,
      status: [OrderNotary.STATUS_DOCS_RECEIVED, OrderNotary.STATUS_APPOINTMENT_SET, OrderNotary.STATUS_APPOINTMENT_DECLINED],
      title: "Set the appointment",
      icon: appointmentSetIcon,
      successIcon: appointmentSetIconWhite,
      content: <StepSetAppointment orderKey={orderKey} />
    },
    {
      id: 3,
      status: [OrderNotary.STATUS_APPOINTMENT_ACCEPTED],
      title: "Before the signing",
      icon: beforeSigningIcon,
      successIcon: beforeSigningIconWhite,
      content: <StepBeforeSigning />
    },
    {
      id: 4,
      status: [OrderNotary.STATUS_DOCS_PRINTED],
      title: "During & After the signing",
      icon: duringSigningIcon,
      successIcon: duringSigningIconWhite,
      content: <StepDuringSigning />
    },
    {
      id: 5,
      status: [OrderNotary.STATUS_DOCS_UPLOADED],
      title: "Return docs to dealership",
      icon: returnToDealershipIcon,
      successIcon: returnToDealershipIconWhite,
      content: <StepReturnToDealership />
    }
  ];

  /********** EFFECTS **********/

  useEffect(() => {
    dispatch(getOrderInkSignDocs(order.id));
    return () => {
      dispatch(doOrderInkSignDocsCleanup());
    }
  }, []);

  /********** HANDLERS **********/

  const toggle = id => {
    if (open == id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <Accordion className="order-flow-accordion" open={open} toggle={toggle}>
      {accordionItems.map(item => {
        const currentStep = item.status.includes(order.notaries[0].status);

        const stepNotReady = min(item.status) > order.notaries[0].status;
        const stepCompleted = (!currentStep && !stepNotReady) || isDocsCompleted;

        return (
          <AccordionItem key={item.id}>
            <AccordionHeader
              className={classnames({ "accordion-item-success": stepCompleted })}
              targetId={item.id.toString()}
            >
              <img alt="icon" src={stepCompleted ? item.successIcon : item.icon} />
              <h6 className="accordion-title">{item.title}</h6>
            </AccordionHeader>
            <AccordionBody accordionId={item.id.toString()}>
              {item.content}
            </AccordionBody>
          </AccordionItem>
        )
      })}
    </Accordion>
  )
};

OrderFlowAccordion.propTypes = {
  order: PropTypes.object.isRequired,
  orderKey: PropTypes.number,
};

export default OrderFlowAccordion;