export default class Credential {

  static TYPE_COMMISSION_CERTIFICATE = 1;
  static TYPE_EO_INSURANCE = 2;
  static TYPE_BOND = 3;
  static TYPE_DRIVER_LICENSE_NUMBER = 4;
  static TYPE_BACKGROUND_REPORT = 5;
  static TYPE_W9 = 6;

  static getTypeMap() {
    return {
      [this.TYPE_COMMISSION_CERTIFICATE]: "Commission Certificate",
      [this.TYPE_EO_INSURANCE]: "E&O Insurance",
      [this.TYPE_BOND]: "Bond",
      [this.TYPE_DRIVER_LICENSE_NUMBER]: "Driver License Number",
      [this.TYPE_BACKGROUND_REPORT]: "Background Report",
      [this.TYPE_W9]: "W-9",
    };
  };

  static getTypeName(id) {
    return this.getTypeName()[id];
  };

  static getFileNameMap() {
    return {
      [this.TYPE_COMMISSION_CERTIFICATE]: "Commission Certificate",
      [this.TYPE_EO_INSURANCE]: "E&O Insurance",
      [this.TYPE_BOND]: "Bond",
      [this.TYPE_DRIVER_LICENSE_NUMBER]: "DL",
      [this.TYPE_BACKGROUND_REPORT]: "Background Report",
      [this.TYPE_W9]: "W9",
    };
  };

  static getFileName(id) {
    return this.getFileNameMap()[id];
  };

  static getRequiredCredentials() {
    return [this.TYPE_DRIVER_LICENSE_NUMBER];
  }

  static isRequired = typeId => this.getRequiredCredentials().includes(+typeId);
}