import React from "react";
import User from 'model/user';
import { Store } from 'react-notifications-component';
import moment from "moment-timezone";
import config from 'config';
import UserRole from 'model/userRole';
import randomBytes from 'randombytes';
import Order from 'model/order';
import DOMPurify from 'dompurify';
import { subtractHoursFromNow } from './dateHelper';
import { routes } from './routeHelper';
import OrderNotary from 'model/orderNotary';
import DealerStore from "../model/dealerStore";
import regx from 'constants/regx';
import { UncontrolledTooltip } from "reactstrap";
import pendingVidIcon from "../assets/images/pending-VID.svg";
import skippedVidIcon from "../assets/images/skipped-VID.svg";
import failedVid from "../assets/images/failed-VID.svg";
import passedVid from "../assets/images/passed-VID.svg";
import VidRequest from "../model/vidRequest";

export const sleep = time => new Promise((resolve) => setTimeout(resolve, time));

export const getSharedPaginationOptions = () => ({
  custom: true,
  hidePageListOnlyOnePage: true,
  paginationSize: 10,
  sizePerPageList: [
    { text: '1', value: 1 },
    { text: '10', value: 10 },
    { text: '50', value: 50 },
    { text: '100', value: 100 },
    { text: '200', value: 200 },
  ],
});

export const getSharedTableOptions = () => ({
  keyField: "id",
  bordered: false,
  striped: false,
  defaultSortDirection: "asc",
  selectRow: {
    mode: 'radio',
    hideSelectColumn: true,
  },
  classes: "table align-middle table-nowrap",
  headerWrapperClasses: "thead-light",
  responsive: true,
  remote: true,
});

export const valueIsDefined = value => value !== undefined && value !== null;

export const valueIsEmpty = value => {
  if (typeof value === 'string') {
    value = value.trim();
  }

  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  // allow 0 and FALSE
  return value !== 0 && value !== false && !value;
}

export const mapNonEmpty = obj => Object.entries(obj).reduce((a, [k, v]) => (!valueIsEmpty(v) ? (a[k] = v, a) : a), {});

export const hasNonEmpty = obj => Object.keys(mapNonEmpty(obj)).length > 0;

// axios serializes only plain objects
// so any nested objects will end up as json
// but we need the filters as a query string array
// so here we do the transformation
export const flattenFilters = params => {
  // clone the object to avoid changing the original
  const newParams = { ...params };
  if (newParams.filters) {
    // loop through all filters
    for (const [key, value] of Object.entries(newParams.filters)) {
      // if the filter value is not empty
      if (!valueIsEmpty(value)) {
        // add the filter under the root object
        // change the key so it will be decoded properly on the backend
        newParams[`filters[${key}]`] = value;
      }
    }
    // remove the filters key since it is no longer needed
    delete newParams.filters;
  }
  return newParams;
}

// the Select control expects a list of {value, label} objects as options
// most of the time api response is different than what the Select control expects
// so here we do the transformation
export const toSelectOptions = (list, valueProp = 'id', labelProp = 'name') => {
  return list.map(item => ({
    label: item[labelProp],
    value: item[valueProp],
  }));
}

export const getYesNoOptions = (includeAll = false) => {
  const options = [{
    label: "Yes",
    value: true,
  }, {
    label: "No",
    value: false,
  }];
  if (includeAll) {
    options.unshift({
      label: "All",
      value: '',
    });
  }
  return options;
}

export const getYesNoOptionsAsNumbers = (includeAll = false) => {
  const options = [{
    label: "Yes",
    value: 1,
  }, {
    label: "No",
    value: 0,
  }];
  if (includeAll) {
    options.unshift({
      label: "All",
      value: '',
    });
  }
  return options;
}

export const getActiveOptions = () => {
  const options = [{
    label: "Active",
    value: true,
  }, {
    label: "Disabled",
    value: false
  }];
  return options;
}

export const distanceServedOptions = [
  {
    value: 10,
    label: "10 miles radius"
  },
  {
    value: 20,
    label: "20 miles radius"
  },
  {
    value: 30,
    label: "30 miles radius"
  },
  {
    value: 40,
    label: "40 miles radius"
  },
  {
    value: 50,
    label: "50 miles radius"
  },
];

export const canPayWithCheckOptionsForGroups = [
  { value: true, label: "Enable check payments for the stores within this group" },
  { value: false, label: "Do not enable check payments for the stores within this group" },
];

export const canPayWithCheckOptionsForStores = [
  { value: true, label: "Enable check payments for this store" },
  { value: false, label: "Do not enable check payments for this store" },
];

export const isObject = obj => typeof obj === 'object' && obj !== null;

export const showNotification = (type, message, options) => {
  const defaultOptions = {
    title: '',
    insert: 'top',
    container: 'top-center',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 6000,
      onScreen: true,
      pauseOnHover: true,
      showIcon: true,
    },
    width: 400,
  }
  return Store.addNotification({
    ...defaultOptions,
    ...options,
    type: type,
    message: message,
  });
}

export const showBriefNotification = (type, message, options) => {
  const defaultOptions = {
    title: '',
    insert: 'top',
    container: 'top-center',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 2000,
      onScreen: true,
      pauseOnHover: true,
      showIcon: true,
    },
    width: 400,
  }
  return Store.addNotification({
    ...defaultOptions,
    ...options,
    type: type,
    message: message,
  });
}

export const showSuccess = (message, options) => showNotification('success', message, options);

export const showBriefSuccess = (message, options) => showBriefNotification('success', message, options);

export const showMessage = (message, options) => showNotification('info', message, options);

export const showWarning = (message, options) => showNotification('warning', message, options);

export const showError = (message, options) => showNotification('danger', message, options);

export const showBriefError = (message, options) => showBriefNotification('danger', message, options);

export const removeNotification = id => Store.removeNotification(id);

export const getChangeEventData = (e, meta) => {
  let name, value;
  if (e.target) {
    name = e.target.name;
    value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
  } else {
    // meta is only available for Select
    name = meta.name;
    // the Select component only accepts an object as value
    // so here we return the entire selected option
    value = e;
  }
  return [name, value];
}

export const trim = (str, char) => {
  char = char.replace(/[-/^$*+?.()|[]{}]/g, '$&');
  return str.replace(new RegExp(
    "^[" + char + "]+|[" + char + "]+$", "g"
  ), "");
}

export const ltrim = (s, c) => {
  c = c.replace(/[-/\^$*+?.()|[]{}]/g, '\$&');
  return s.replace(new RegExp(
    "^[" + c + "]+", "g"
  ), "");
}

export const rtrim = (s, c) => {
  c = c.replace(/[-/\^$*+?.()|[]{}]/g, '\$&');
  return s.replace(new RegExp(
    "[" + c + "]+$", "g"
  ), "");
}

export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

export const buildUrl = (base, fragment) => rtrim(base, '/') + (fragment ? '/' + ltrim(fragment, '/') : '');

export const getBeUrl = fragment => buildUrl(config.API_BE_URL, fragment);

export const getSsoAppUrl = fragment => buildUrl(config.SSO_URL, fragment);

export const getDealerAppUrl = fragment => buildUrl(config.DEALER_URL, fragment);

export const getNotaryAppUrl = fragment => buildUrl(config.NOTARY_URL, fragment);

export const nullsToEmptyStrings = (obj, recursive = false, excludeKeys = []) => {
  if (!obj) {
    return;
  }
  if (Array.isArray(obj)) {
    return obj.map((val, idx) => {
      if (excludeKeys.includes(idx)) {
        return val;
      }
      if (val === null) {
        return '';
      } else if (typeof val === 'object' && recursive) {
        return nullsToEmptyStrings(val, recursive, excludeKeys);
      }
      return val;
    });
  } else {
    return Object.fromEntries(Object.entries(obj).map(([key, val]) => {
      if (excludeKeys.includes(key)) {
        return [key, val];
      }
      if (val === null) {
        return [key, ''];
      } else if (typeof val === 'object' && recursive) {
        return [key, nullsToEmptyStrings(val, recursive, excludeKeys)];
      }
      return [key, val];
    }));
  }
}

export const isNotAllOrNone = value => value != '' && value != -1;

export const toUserModel = user => Object.assign(new User(), user);

export const getTimezoneOptions = () => moment.tz.zonesForCountry('US').map(zone => ({
  label: `${zone} (${moment.tz(zone).zoneAbbr()})`,
  value: zone,
}));

export const getTimezoneAbbr = zone => moment.tz(zone).zoneAbbr();

export const getUserOwner = user => {
  const owner = {
    name: null,
    id: null,
    route: null,
    type: null,
  };
  if (UserRole.isAdminType(user.userRoleId)) {
    owner.name = config.APP_TITLE;
    owner.type = 'mav';
  } else if (UserRole.isDealerGroupManager(user.userRoleId)) {
    owner.name = user.dealerGroupName;
    owner.id = user.dealerGroupId;
    owner.route = routes.view_dealer_group;
    owner.type = 'dealerGroup';
  } else if (UserRole.isDealerRegionalManager(user.userRoleId)) {
    owner.name = user.dealerRegionName;
    owner.id = user.dealerRegionId;
    owner.route = routes.view_dealer_region;
    owner.type = 'dealerRegion';
    owner.parentName = user.dealerGroupName;
    owner.parentId = user.dealerGroupId;
    owner.parentRoute = routes.view_dealer_group;
  } else if (UserRole.isDealerType(user.userRoleId)) {
    owner.name = user.dealerStoreName;
    owner.id = user.dealerStoreId;
    owner.route = routes.view_dealer_store;
    owner.type = 'dealerStore';
  } else if (UserRole.isNotaryType(user.userRoleId)) {
    owner.name = user.fullName;
    owner.id = user.notaryId;
    owner.route = routes.view_notary;
    owner.type = 'notary';
  }
  return owner;
}

export const withAuth = fragment => buildUrl(config.AUTH_PATH_PREFIX, fragment);

export const randomString = len => new Promise((resolve, reject) => {
  randomBytes(len, function (ex, buffer) {
    if (ex) {
      reject(null);
    }
    resolve(buffer.toString('hex'));
  });
});

export const randomStringSync = len => randomBytes(len).toString('hex');

export const mergeRefs = (...refs) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return inst => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};

export const dictionaryToSelectOptions = dict => Object.entries(dict).map(entry => ({ label: entry[1], value: +entry[0] }));

export const orderIsLocked = sealStatus => [Order.SEAL_STATUS_SEALED, Order.SEAL_STATUS_QUEUED].includes(sealStatus);

export const formatPhone = phone => {
  if (!phone) {
    return;
  }
  const clean = phone.replace(/\D/g, '');
  const match = clean.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (!match) {
    return clean;
  }
  let result = '';
  if (!!match[1]) {
    result += match[1];
  }
  if (!!match[2]) {
    result += '-' + match[2];
  }
  if (!!match[3]) {
    result += '-' + match[3];
  }
  return result;
}

export const openInNewWindow = url => {
  // Safari is blocking any call to open a new window which is made inside an async call, treating it as a pop-up.
  // setTimeout code runs on the main thread, instead of the asynchronous one
  setTimeout(() => {
    const a = document.createElement('a');
    a.target = '_blank';
    a.href = url;
    a.click();
  })
}

export const sanitizeHtml = html => DOMPurify.sanitize(html);

export const stripTags = value => value.replace(/(<([^>]+)>)/gi, "");

export const getOrderFieldLabel = field => {
  switch (field) {
    case 'dmsDealId':
      return 'DMS Deal ID';
    case 'stockNum':
      return 'Stock Number';
    case 'vin':
      return 'V.I.N. #';
    default:
      return field;
  }
}

export const getOrderSignerFieldLabel = field => {
  switch (field) {
    case 'firstName':
      return 'First name';
    case 'lastName':
      return 'Last name';
    case 'email':
      return 'Email';
    case 'phone':
      return 'Phone';
    default:
      return field;
  }
}

export const getInitialsFromName = name => {
  if (!name) {
    return null;
  }
  return name.match(/\b(\w)/g).join('');
}

export const getLogAuthorFullName = author => {
  if (!author || !isObject(author)) {
    return null;
  }
  if (!!author.fullName) {
    return author.fullName;
  }
  const nameParts = [];
  if (!!author.firstName) {
    nameParts.push(author.firstName);
  }
  if (!!author.lastName) {
    nameParts.push(author.lastName);
  }
  if (nameParts.length > 0) {
    return nameParts.join(' ');
  }
}

export const filterObjectKeys = (obj, keys) => {
  if (!obj) {
    return null;
  }
  return Object.fromEntries(Object.entries(obj).filter(([key]) => keys.includes(key)));
}

export const extractDtFiltersFromUrl = () => {
  const query = new URLSearchParams(window.location.search);
  const filters = {};
  for (const [key, value] of query.entries()) {
    const match = key.match(/filters\[(.+)\]/);
    if (!!match && !!match[1]) {
      filters[match[1]] = value;
    }
  }
  return filters;
}

export const removeDtFiltersFromUrl = () => {
  const query = new URLSearchParams(window.location.search);
  const filters = {};
  for (const [key, value] of query.entries()) {
    const match = key.match(/filters\[(.+)\]/);
    if (!match) {
      filters[key] = value;
    }
  }
  return new URLSearchParams(filters).toString();
}

export const getInactiveOrdersFilters = () => ({
  inactiveSince: subtractHoursFromNow(6),
  excStatus: [Order.STATUS_CANCELLED, Order.STATUS_DRAFT, Order.STATUS_COMPLETED].join(','),
});

export const getImmediateReviewOrdersFilters = () => ({
  alert: [Order.ALERT_FILTER_FRAUD, Order.ALERT_FILTER_HIGH_RISK, Order.ALERT_FILTER_ON_HOLD, Order.ALERT_FILTER_UNDER_REVIEW].join(','),
});


export const bytesToSize = bytes => {
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return 'n/a'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  if (i === 0) return `${bytes} ${sizes[i]})`
  return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
}

export const getAddressComponents = place => {
  const addressComponents = {
    address: "",
    city: "",
    state: "",
    zip: "",
  };
  // get each component of the address from the place details,
  // and then fill-in the corresponding field on the form.
  // place.address_components are google.maps.GeocoderAddressComponent objects
  // which are documented at http://goo.gle/3l5i5Mr
  for (const component of place.address_components) {
    const componentType = component.types[0];
    switch (componentType) {
      case "street_number": {
        addressComponents.address = `${component.long_name} ${addressComponents.address}`;
        break;
      }
      case "route": {
        addressComponents.address += component.short_name;
        break;
      }
      case "postal_code": {
        addressComponents.zip = component.long_name;
        break;
      }
      case "locality":
        addressComponents.city = component.long_name;
        break;
      case "administrative_area_level_1": {
        addressComponents.state = component.short_name;
        break;
      }
    }
  }
  return addressComponents;
}

export const getMiles = meters => (meters * 0.000621371192).toFixed(2);

export const decimalToDMS = (decimal, isLatitude) => {
  const degrees = Math.floor(Math.abs(decimal));
  const decimalMinutes = (Math.abs(decimal) - degrees) * 60;
  const minutes = Math.floor(decimalMinutes);
  const seconds = (decimalMinutes - minutes) * 60;

  let direction = "";
  if (isLatitude) {
    direction = decimal >= 0 ? 'N' : 'S';
  } else {
    direction = decimal >= 0 ? 'E' : 'W';
  }

  return `${degrees}° ${minutes}' ${seconds.toFixed(2)}" ${direction}`;
}

export const getGranularStatusName = status => {
  if (!status) return

  const [statusType, statusId] = status.split("_").map(id => parseInt(id));

  let statusMap;

  switch (statusType) {
    case Order.GRANULAR_STATUS_TYPE_INTERNAL:
      statusMap = Order.getInternalStatusMap();
      break;
    case Order.GRANULAR_STATUS_TYPE_ORDER_NOTARY:
      statusMap = OrderNotary.getStatusMap();
      break;
    case Order.GRANULAR_STATUS_TYPE_ORDER:
    default:
      statusMap = Order.getStatusMap();
      break;
  }

  return statusMap[statusId];
}

export const getGranularStatusOption = (type, status) => {
  const granularStatus = `${type}_${status}`;
  return ({ value: granularStatus, label: getGranularStatusName(granularStatus) });
};

export const getGranularStatusOptions = () => ([
  ...Object.keys(Order.getStatusMap()).map(status => getGranularStatusOption(Order.GRANULAR_STATUS_TYPE_ORDER, status)),
  ...Object.keys(OrderNotary.getStatusMap()).map(status => getGranularStatusOption(Order.GRANULAR_STATUS_TYPE_ORDER_NOTARY, status)),
]);

export const getNotarizationRequired = status => {
  switch (status) {
    case 0:
      return 'No'
    case 1:
      return 'Yes'
  }
}

export const newLineTextFormat = (lines) => {
  // Add each line of text on new line
  // and add bullet symbol at the beginning
  let formattedText = lines?.map(function (line) {
    return '\u2022' + ' ' + line;
  });
  return formattedText;
}

export const getPaymentMethod = type => {
  switch (type) {
    case DealerStore.PAYMENT_METHOD_TYPE_CARD:
      return 'Credit Card'
    case DealerStore.PAYMENT_METHOD_TYPE_BANK_ACCOUNT:
      return 'Back Account'
    case DealerStore.PAYMENT_METHOD_TYPE_CHECK:
      return 'Check'
  }
}

export const getTrendColor = percentage => {
  if (percentage > 0) return "success";
  if (percentage < 0) return "danger";
  if (percentage === 0) return "warning";
  return "secondary";
};

export const getDealerFeeTotalCost = (fees, comp = 0, addServ = 0, transact = 0) => {
  if (!fees) return addServ - comp;
  let sum;
  // fees received can either be an array or an object, we must cover both cases
  if (Array.isArray(fees)) {
    sum = fees.reduce((accum, val) => accum + (Number(val.price) * Number(val.count ?? 1)), 0);
  } else {
    sum = Object.keys(fees).reduce((accum, val) => accum + fees[val], 0);
  }
  // add compensation, additional service and transaction fee to the total amount
  sum += addServ + transact - comp;
  return sum;
};

// styles for smaller size Select
export const smallSelectStyles = {
  control: (base) => ({
    ...base,
    minHeight: 32,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  clearIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

export const getSigningFeeServiceDesc = customerLocation => {
  switch (customerLocation) {
    case Order.CUSTOMER_LOCATION_REMOTE:
      return 'Remote Transaction Fee';
    case Order.CUSTOMER_LOCATION_IN_STORE:
      return 'In-Store Transaction Fee';
    default:
      return '--';
  }
}

export const phoneHasNoOfDigits = (value) => {
  return !!value && value.replace(regx.phoneChars, '').length == config.PHONE_NR_OF_DIGITS;
}

export const getVIDStatusImage = (vidStatus, row) => {
  if (vidStatus === VidRequest.STATUS_CREATED ||
    vidStatus === VidRequest.STATUS_PENDING ||
    vidStatus === VidRequest.STATUS_ESCALATED ||
    vidStatus === null)
    return <><img id={row && `verify-id${row.id}`} src={pendingVidIcon} className="me-2" />{row && <UncontrolledTooltip placement="top" target={`verify-id${row.id}`}>Pending Verify ID</UncontrolledTooltip>}</>

  if (vidStatus === VidRequest.STATUS_SKIPPED)
    return <><img id={row && `verify-id${row.id}`} src={skippedVidIcon} className="me-2" />{row && <UncontrolledTooltip placement="top" target={`verify-id${row.id}`}>Skipped Verify ID</UncontrolledTooltip>}</>

  if (vidStatus === VidRequest.STATUS_ERROR ||
    vidStatus === VidRequest.STATUS_MITEK_API_FAIL ||
    vidStatus === VidRequest.STATUS_FAILED)
    return <><img id={row && `verify-id${row.id}`} src={failedVid} className="me-2" />{row && <UncontrolledTooltip placement="top" target={`verify-id${row.id}`}>Failed Verify ID</UncontrolledTooltip>}</>

  if (vidStatus === VidRequest.STATUS_PASSED)
    return <><img id={row && `verify-id${row.id}`} src={passedVid} className="me-2" />{row && <UncontrolledTooltip placement="top" target={`verify-id${row.id}`}>Verify ID</UncontrolledTooltip>}</>
}

export const getDtFooterRowCount = (paginationProps, numOfVisibleRows, totalNumOfRows) => {
  const startNum = ((paginationProps.page - 1) * paginationProps.sizePerPage) + 1;
  const endNum = ((paginationProps.page - 1) * paginationProps.sizePerPage) + numOfVisibleRows;
  return <>
    Showing <span id="dt-rowcount">{numOfVisibleRows}</span> of {totalNumOfRows} items
    <UncontrolledTooltip placement="top" target="dt-rowcount">{startNum}-{endNum}</UncontrolledTooltip>
  </>;
}

export const getBounceReason = (reason) => {
  switch (reason) {
    case User.EMAIL_ALERT_GENERAL:
      return <span>It seems the email address associated with your account does not exist. Please update it in your profile.</span>;
    case User.EMAIL_ALERT_MAIL_BOX_FULL:
      return <span>It seems your inbox is full and cannot receive any more emails. Please clear it out.</span>;
    case User.EMAIL_ALERT_ON_ACCOUNT_SUPPRESION_LIST:
      return <span>It seems the Mavsign email address is blocked/marked as spam by you. Please adjust that in your email platform.</span>;
    default:
      return <span>There seems to be an unknown issue with your email. Please check your email settings or contact support.</span>;
  }
};

export const checkReasonType = (user) => {
  // check if the user email has bounceSubtype or complaintSubtype
  // based on this, display the according email bounce reason message from above
  const subtype = user.bounceSubtype || user.complaintSubtype;
  return getBounceReason(subtype);
}