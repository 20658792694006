import React, { useState } from "react";
import PropTypes from 'prop-types';
import { useNavigate, Link } from "react-router-dom";
import { Row, Col, CardSubtitle, Button, Card, CardBody, CardHeader, Dropdown, DropdownToggle, DropdownItem, DropdownMenu, UncontrolledTooltip } from "reactstrap";
import classnames from "classnames";
import { showSuccess, showError, getTimezoneAbbr, checkReasonType } from "helpers/utilHelper";
import { route, routes } from "helpers/routeHelper";
import { formats, formatTimestamp } from "helpers/dateHelper";
import Confirmation from "components/Shared/Confirmation";
import { perms, useAccess } from "context/access";
import CardPreloader from "components/Shared/CardPreloader";
import { useAuth } from "context/auth";
import UserActivityStatus from "components/Shared/UserActivityStatus";
import { deleteUser, checkEmailAlert, getPasswordResetLink, getVerificationLink, impersonateUser, sendVerificationEmail, unlockAccount } from "helpers/backendHelper";
import { deleteNotary } from "helpers/backendHelper";
import { USER_ACCOUNT_NOT_LOCKED, USER_EMAIL_ALERT_NOT_FOUND } from "helpers/errorHelper";
// @ts-ignore
import warningIcon from "assets/images/warning-red-icon.svg";



const ViewInfo = props => {

  const { isRefreshing, id, user, toggleEditMode, refreshHandler } = props;

  // router hook that helps redirect
  const navigate = useNavigate();
  // hooks that check permissions
  const { iAmGranted } = useAccess();
  // authenticated user
  const { user: authUser } = useAuth();

  /********** STATE **********/

  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  const [isSendInProgress, setIsSendInProgress] = useState(false);
  const [isImpersInProgress, setIsImpersInProgress] = useState(false);

  const isNotary = user.userRoleId === 300;

  // is the confirmation dialog visible or not
  // used to show/hide the delete warning
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
  const [isResendConfirmationVisible, setIsResendConfirmationVisible] = useState(false);
  const [dropdownMenuIsOpen, setDropdownMenuIsOpen] = useState(false);
  const [isCheckEmailAlertsVisible, setIsCheckEmailAlertsVisible] = useState(false);

  /********** EVENT HANDLERS **********/

  // delete-user event handler
  // make the remote call to delete the user
  const removeUser = () => {
    setIsDeleteInProgress(true);
    if (isNotary) {
      deleteNotary(user.notaryId)
        .then(response => {
          showSuccess(`User "${user.fullName}" has been deleted`);
          navigate(route(routes.list_users));
        })
        .catch(ex => {
          showError('Unable to delete user');
        })
        .finally(() => {
          setIsDeleteInProgress(false);
        });
    } else {
      deleteUser(id)
        .then(response => {
          showSuccess(`User "${user.fullName}" has been deleted`);
          navigate(route(routes.list_users));
        })
        .catch(ex => {
          showError('Unable to delete user');
        })
        .finally(() => {
          setIsDeleteInProgress(false);
        });
    }
  };

  // impersonate-user event handler
  const masqueradeUser = () => {
    setIsImpersInProgress(true);
    impersonateUser(id)
      .then(response => {
        // redirect to homepage
        // this will check the authenticated user
        // and redirect to the appropriate app
        window.location.href = '/';
      })
      .catch(ex => {
        showError('Unable to impersonate user');
      })
      .finally(() => {
        setIsImpersInProgress(false);
      });
  };

  const startEmailVerification = () => {
    setIsSendInProgress(true);
    sendVerificationEmail(id)
      .then(response => {
        showSuccess(`Verification email sent to "${user.email}"`);
      })
      .catch(ex => {
        showError('Unable to send verification email');
      })
      .finally(() => {
        setIsSendInProgress(false);
      });
  };

  const copyVerificationLink = () => {
    getVerificationLink(id)
      .then(response => {
        navigator.clipboard.writeText(response.url);
        showSuccess('Verification link copied to clipboard');
      })
      .catch(ex => {
        showError('Unable to fetch verification link');
      });
  }

  const copyPasswordResetLink = () => {
    getPasswordResetLink(id)
      .then(response => {
        navigator.clipboard.writeText(response.url);
        showSuccess('Password reset link copied to clipboard');
      })
      .catch(ex => {
        showError('Unable to fetch password reset link');
      });
  }

  const doUnlockAccount = () => {
    unlockAccount(id)
      .then(response => {
        showSuccess(`User "${user.fullName}" has been unlocked`);
        refreshHandler();
      })
      .catch(error => {
        if (error.code == USER_ACCOUNT_NOT_LOCKED) {
          showError('User account is not locked');
          return;
        }
        showError('Unable to unlock the user account');
      });
  }

  // check for email alerts event handler
  const doEmailAlertCheck = () => {
    checkEmailAlert(id)
      .then(response => {
        showSuccess('A test email has been triggered.');
        refreshHandler();
      })
      .catch(error => {
        if (error.code == USER_EMAIL_ALERT_NOT_FOUND) {
          showError('User email has no alert.');
          return;
        }
      showError('Unable to trigger email alert.');
    });
  }

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className={classnames(user.isActive ? 'bg-transparent' : 'bg-warning bg-soft', 'pt-3')}>
        <Row>
          <Col>
            <h3 className="d-flex align-items-start"><span>{user.fullName}</span>
              {!!user.hasEmailAlert && <>
              <img className="ms-2" id={`bounce-email-${user.id}`} src={warningIcon} alt="warning-icon" />
              <UncontrolledTooltip placement="top" target={`bounce-email-${user.id}`}>{checkReasonType(user)}</UncontrolledTooltip></>}
            </h3>
            <CardSubtitle className="text-muted">
              {user.isActive && <UserActivityStatus id={user.id} status={user.activityStatus} lastSeen={user.lastActiveTs} autoUpdate={true} size="md" className="me-2" />}
              {!user.isEmailVerified ? 'Pending Verification' : (!user.isActive && 'Deactivated')}
            </CardSubtitle>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              {iAmGranted(perms.impersonate_users) && iAmGranted(perms.impersonate_user, user) && <Button type="button" color="outline-dark" className="mb-2 impersonate-user" onClick={masqueradeUser}>
                {isImpersInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                {!isImpersInProgress && <i className="mdi mdi-account-arrow-left me-1" />}
                Impersonate user
              </Button>}
              {iAmGranted(perms.edit_users) && iAmGranted(perms.edit_user, user) && <Button type="button" color="primary" className="ms-2 mb-2" onClick={toggleEditMode}>
                <i className="mdi mdi-pencil me-1" />Edit user
              </Button>}
              {iAmGranted(perms.delete_users) && iAmGranted(perms.delete_user, { userRoleId: user.userRoleId }) && user.id != authUser.id && <Button type="button" color="danger" className="ms-2 mb-2" onClick={() => { setIsDeleteConfirmationVisible(true) }} disabled={isDeleteInProgress}>
                {isDeleteInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                {!isDeleteInProgress && <i className="mdi mdi-delete me-1" />}
                Delete user
              </Button>}
              <Link to={route(routes.list_users)} className="btn btn-secondary ms-2 mb-2">
                <i className="mdi mdi-chevron-left me-1" />Back
              </Link>
              <Dropdown isOpen={dropdownMenuIsOpen} toggle={() => setDropdownMenuIsOpen(value => !value)} className="d-inline-block ms-2 mb-2 align-middle">
                <DropdownToggle tag="button" className="btn btn-default card-menu-btn">
                  <i className="bx bx-dots-horizontal-rounded" />
                </DropdownToggle>
                <DropdownMenu end>
                  {(iAmGranted(perms.check_user_email_alert) && !!user.hasEmailAlert) && <DropdownItem onClick={() => setIsCheckEmailAlertsVisible(true)}>Check for Email alerts</DropdownItem>}
                  {iAmGranted(perms.verify_users) && !user.isEmailVerified && <React.Fragment>
                    <DropdownItem onClick={() => setIsResendConfirmationVisible(true)} disabled={isSendInProgress}>Resend verification link</DropdownItem>
                    <DropdownItem onClick={copyVerificationLink}>Copy verification link</DropdownItem>
                  </React.Fragment>}
                  <DropdownItem onClick={copyPasswordResetLink}>Copy password reset link</DropdownItem>
                  {(iAmGranted(perms.unlock_account) && user.isLocked) && <DropdownItem onClick={doUnlockAccount}>Unlock account</DropdownItem>}
                </DropdownMenu>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs="12" xl="4">
            <Row className="mb-3">
              <label className="mb-0">Title</label>
              <div>{user.title ?? '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Role</label>
              <div>{user.userRoleName}</div>
            </Row>
          </Col>
          <Col xs="12" xl="4">
            <Row className="mb-3">
              <label className="mb-0">Email</label>
              <div>{user.email}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Phone</label>
              <div>{user.phone ?? '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Timezone</label>
              <div>{user.timezone} ({getTimezoneAbbr(user.timezone)})</div>
            </Row>
          </Col>
          {user.isDealerType() && <Col xs="12" xl="4">
            <Row className="mb-3">
              <label className="mb-0">Group</label>
              <div>{user.dealerGroupName ? <Link to={route(routes.view_dealer_group, user.dealerGroupId)}>{user.dealerGroupName}</Link> : '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Region</label>
              <div>{user.dealerRegionName ? <Link to={route(routes.view_dealer_region, user.dealerRegionId)}>{user.dealerRegionName}</Link> : '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Store</label>
              <div>{user.dealerStoreName ? <Link to={route(routes.view_dealer_store, user.dealerStoreId)}>{user.dealerStoreName}</Link> : '--'}</div>
            </Row>
          </Col>}
          {user.isNotaryType() && <Col xs="12" xl="4">
            <Row className="mb-3">
              <label className="mb-0">Notary</label>
              <div>{user.notaryId ? <Link to={route(routes.view_notary, user.notaryId)}>{user.fullName}</Link> : '--'}</div>
            </Row>
          </Col>}
        </Row>
        <Row className="mt-4">
          <Col xs="4">
            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary"></i> Created</h5>
            <p className="text-muted mb-0">{formatTimestamp(user.createdTs, formats.DATETIME)}</p>
          </Col>
          <Col xs="4">
            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary"></i> Updated</h5>
            <p className="text-muted mb-0">{formatTimestamp(user.updatedTs, formats.DATETIME)}</p>
          </Col>
          <Col xs="4">
            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary"></i> Last login</h5>
            <p className="text-muted mb-0">{user.lastAuthTs ? formatTimestamp(user.lastAuthTs, formats.DATETIME) : '--'}</p>
          </Col>
        </Row>
      </CardBody>
      {isRefreshing && <CardPreloader />}
    </Card>
    {isDeleteConfirmationVisible && <Confirmation
      confirmBtnText="Delete"
      onConfirm={() => {
        setIsDeleteConfirmationVisible(false);
        removeUser();
      }}
      onCancel={() => setIsDeleteConfirmationVisible(false)}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to delete user &quot;{user.fullName}&quot;?</span>
    </Confirmation>}
    {isResendConfirmationVisible && <Confirmation
      confirmBtnText="Send"
      onConfirm={() => {
        setIsResendConfirmationVisible(false);
        startEmailVerification();
      }}
      onCancel={() => setIsResendConfirmationVisible(false)}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to resend the verification email to &quot;{user.email}&quot;?</span>
    </Confirmation>}

    {/* Check for email alerts modal */}
    {isCheckEmailAlertsVisible && <Confirmation
      confirmBtnText="Confirm"
      onConfirm={() => {
        setIsCheckEmailAlertsVisible(false);
        doEmailAlertCheck();
      }}
      onCancel={() => setIsCheckEmailAlertsVisible(false)}>
      <span style={{ color: '#556EE6' }}> A test email will be sent to this email address, to make sure the emails go through. This process can take up to a couple of hours.
        If the email address works correctly the user will receive a test email. If not, the email will be marked as Bounce again.</span>
    </Confirmation>}
  </React.Fragment>
}

ViewInfo.propTypes = {
  isRefreshing: PropTypes.bool,
  id: PropTypes.number,
  user: PropTypes.object,
  toggleEditMode: PropTypes.func,
  refreshHandler: PropTypes.func,
};

export default ViewInfo;
