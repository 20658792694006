import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FieldArray, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { doDealerFormCleanup, updateDealerAccounting } from "store/orderFees/actions";
import { getDealerFeeTotalCost, showError, showSuccess } from "helpers/utilHelper";
import { ValidationException } from "helpers/errorHelper";
import TotalCosts from "pages/Order/Partial/Section/Accounting/Components/TotalCosts";
import AdditionalFee from "model/additionalFee";
import Order from "model/order";
import classnames from "classnames";
import verifyIdIcon from "assets/images/order/supervised-user-circle.svg";
import additionalVehicleIcon from "assets/images/order/directions-car.svg";

/**
 * The logic in this component is a bit hard to follow, since there are multiple types of fees that need to be rendered in different areas.
 * In the UI, we need to display:
 * A. Basic fees
 *  They include:
 *    1. Transaction fee -> this is read-only, but we need it here in order to correctly reflect the costs of the order
 *    2. Order based fees -> fees that depend on the services added to the order (e.g. if the order has VID, rush services and so on)
 * B. Additional fees
 *  They include:
 *    1. All notary surcharge fees
 *    2. Some other fees that are related to the order (additional services amount, compensation amount)
 *  The default values for all these fees (except for compensation and additional services) are coming from the master plan.
 *  If order to easily search and access their values, we are creating a price catalogue (`pricesDictionary`), where we load the default fees
 * as soon as we receive them from the backend. (see `loadFeesInPricesDictionary` function)
 *  However, anytime the user edits a fee, we need to update the catalogue with the new amount, as that is the true value of the fee now.
 */

// these fees are fields of the order itself and not part of the order fees
// we need some ids to identify them in the logic
const ID_ADDITIONAL_SERVICES_AMOUNT = "additionalServicesAmount";
const ID_COMP_AMOUNT = "compensationAmount";

// helps us distinguish between the fees that are part of the order and the ones that are part of the order fees
const getIsFeeItem = feeId => ![ID_ADDITIONAL_SERVICES_AMOUNT, ID_COMP_AMOUNT].includes(feeId);

const FormDealerEdit = ({
  order,
  orderFees,
  refreshHandler,
}) => {

  let { id } = useParams();
  id = parseInt(id);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /********** STATE **********/

  const { isSaveInProgress, savedDealer, saveDealerError } = useSelector(state => state.OrderFees.Form);
  const { availableFees } = useSelector(state => state.OrderFees.List);

  // collapse/expand accordion
  const [open, setOpen] = useState();
  // flag that helps us reset checkboxes
  const [nonce, setNonce] = useState(0);

  // catalogue of fee ids and their prices
  const [pricesDictionary, setPricesDictionary] = useState({});

  // array of fees that depend on the services added to the order (e.g. if the order has VID, rush services and so on)
  const orderBasedFees = orderFees?.filter(orderBasedFee => [
    AdditionalFee.ID_VERIFY_ID,
    AdditionalFee.ID_INSTORE_VERIFY_ID,
    AdditionalFee.ID_DEALER_NOTARY,
    AdditionalFee.ID_INK_SIGN,
    AdditionalFee.ID_E_SIGN,
    AdditionalFee.ID_RUSH].includes(orderBasedFee.additionalFeeId));

  // Get Additional Vehicle Fee Count number to multiply with in the total amount
  const additionalVehicleCount = orderFees?.find(item => item.additionalFeeId === AdditionalFee.ID_ADDITIONAL_VEHICLE)?.count;

  // dictionary of the fees that have already been saved for the order
  // we are using this map as initial values for the fees in the form
  // as well as to update the prices dictionary with the values added by the user
  const orderFeesMap = orderFees?.reduce((accum, val) => {
    let { additionalFeeId, price } = val;
    // skip this step for transaction fee, as we don't want it to be part of our form values
    if (additionalFeeId === AdditionalFee.ID_TRANSACTION_FEE) {
      return accum;
    }
    accum[additionalFeeId] = Number(price);
    return accum;
  }, {});

  const transactionFeeAmount = Number(order?.transactionPrice) || 0;

  /********** FORM CONFIG **********/

  const formInitialValues = {
    dealerAccountingNotes: order?.dealerAccountingNotes || '',
    // include `additionalServicesAmount` and `compensationAmount` in the form initial values
    // only if they exist on order
    ...(!!order?.additionalServicesAmount && { additionalServicesAmount: order?.additionalServicesAmount }),
    ...(!!order?.compensationAmount && { compensationAmount: order?.compensationAmount }),
    // in the beginning, fees in just an empty object
    // after the fees data is fetched, it will be populated with key-value pairs consisting of the fee id and its price
    fees: {},
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      dealerAccountingNotes: Yup.string().trim(),
      additionalServicesAmount: Yup.number().min(0, "Must be greater than or equal to 0"),
      compensationAmount: Yup.number().min(0, "Must be greater than or equal to 0"),
      fees: Yup.lazy(value => {
        const feesShape = {};
        // in the form, `fees` is an object and we don't know its shape from the start
        // we need to build the shape dynamically here in order to validate that each fee amount is greater than or equal to 0
        Object.keys(value).forEach(key => {
          feesShape[key] = Yup.number().required("Field is required").min(0, "Must be greater than or equal to 0")
        });
        return Yup.object().shape(feesShape);
      }),
    }),
    onSubmit: values => {
      // in the form, we keep the fee data in a map, so that we can access it faster
      // before saving, we need to transform it into an array of objects that contain id and price
      const transformedData = {
        dealerAccountingNotes: values.dealerAccountingNotes,
        additionalServicesAmount: values.additionalServicesAmount || 0,
        compensationAmount: values.compensationAmount || 0,
        fees: Object.keys(values.fees).map(feeId => ({
          id: Number(feeId),
          price: pricesDictionary[feeId],
        }))
      };
      dispatch(updateDealerAccounting(transformedData, id));
    }
  });

  /********** EFFECTS **********/

  // runs on component unmount
  // to cleanup form
  useEffect(() => {
    return () => {
      dispatch(doDealerFormCleanup());
    }
  }, []);

  // runs whenever the order fees (meaning fees that have been saved for this particular order) change
  // to update the form values
  useEffect(() => {
    // if order fees have been fetched
    if (orderFees) {
      // pre-fill fees with already saved values
      formik.setFieldValue("fees", orderFeesMap);
    }
  }, [orderFees]);

  // runs whenever the available fees (meaning master plan fees) change
  // to update the price catalogue
  useEffect(() => {
    if (availableFees) {
      loadFeesInPricesDictionary();
    }
  }, [availableFees, order]);

  // runs after a save attempt
  useEffect(() => {
    if (savedDealer === true) {
      showSuccess("Dealer fee has been saved");
      formik.setSubmitting(false);
      refreshHandler();
    } else if (savedDealer === false) {
      showError("Unable to save dealer fee.");
      // see if the save failed due to validation
      if (saveDealerError instanceof ValidationException) {
        // show an error on each invalid field
        for (const [name, message] of Object.entries(saveDealerError.fields)) {
          formik.setFieldError(name, message);
        }
      }
      // enable the save button
      formik.setSubmitting(false);
    }
  }, [savedDealer]);

  /********** HANDLERS **********/

  // every time a fee input changes, update the price dictionary
  const handleChangePriceDictionary = feeId => e => {
    const newPrice = e.target.value ? Number(e.target.value) : "";
    // save the new price in the prices dictionary
    setPricesDictionary(prev => ({ ...prev, [feeId]: newPrice }));
    const isFeeItem = getIsFeeItem(feeId);
    const isChecked = isFeeItem ? formik.values.fees.hasOwnProperty(feeId) : formik.values.hasOwnProperty(feeId);
    const isOrderBasedFee = orderBasedFees.includes(feeId);
    // if fee is already checked or is one of the order based fees, update its value in the form
    if (isChecked || isOrderBasedFee) {
      const propName = isFeeItem ? `fees.${feeId}` : feeId;
      formik.setFieldValue(propName, newPrice);
    }
  };

  const handleCheckFee = feeId => e => {
    const checked = e.target.checked;
    const isFeeItem = getIsFeeItem(feeId);
    // at check, save the fee value in the form
    if (checked) {
      const propName = isFeeItem ? `fees.${feeId}` : feeId;
      formik.setFieldValue(propName, pricesDictionary[feeId] || 0);
    } else {
      // at uncheck, delete the fee from the form
      isFeeItem ? delete formik.values.fees[feeId] : delete formik.values[feeId];
      // this is needed to force the checkbox to re-render with the new value
      setNonce(n => n + 1);
    }
  };

  const handleToggle = id => open === id ? setOpen() : setOpen(id);

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  // focus event handler
  // used to clear field errors
  const onArrayFieldFocused = (arrayName, key) => () => {
    const errors = formik.errors;
    if (errors[arrayName] && errors[arrayName][key]) {
      delete errors[arrayName][key];
      formik.setStatus(errors);
    }
  };

  // key down event handler that disables the default behavior of number inputs of increasing/decreasing the value when arrow keys are pressed
  const onFieldKeyDown = e => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();
    }
  }

  // key down event handler that disables the default behavior of number inputs of increasing/decreasing the value when scrolling
  const onFieldWheel = e => {
    e.target.blur()
  };

  /********** OTHER **********/
  const getDealerFeeTotalCost = (fees, compAmount, additionalServicesAmount, transactionFeeAmount, orderBasedFees, additionalVehicleCount) => {
    if (!fees) return additionalServicesAmount - compAmount;

    let total = transactionFeeAmount;
    let additionalVehicleAmount;

    // Iterate through the fees object and sum up the fees
    Object.entries(fees).forEach(([feeId, feeAmount]) => {

      // Check for additional vehicle fee and multiply with his count(if has one)
      if(additionalVehicleCount && parseInt(feeId) === AdditionalFee.ID_ADDITIONAL_VEHICLE) {

        additionalVehicleAmount = feeAmount * additionalVehicleCount;

        return additionalVehicleAmount;
      }

      const count = orderBasedFees.find(item => item.additionalFeeId === parseInt(feeId))?.count || 1;

      total += feeAmount * count;
    });

    // Add additional vehicle amount
    total += additionalVehicleAmount || 0;

    // Add additional services amount
    total += additionalServicesAmount || 0;

    // Add compensation amount
    total -= compAmount || 0;

    return total;
  };

  const loadFeesInPricesDictionary = () => {
    // build a map of fee ids and their prices
    const map = availableFees.reduce((accum, val) => {
      let { id, price, additionalFeeId } = val;
      // skip this step for transaction fee, as we don't want it to be part of our form values
      if (additionalFeeId === AdditionalFee.ID_TRANSACTION_FEE) return accum;
      // for the rest of the fees, no custom price has been saved yet, default to the master plan fee values
      // if a fee has the value set to 0, display that value otherwise, display the value from the master plan (price value)
      price = Number(price);
      return { ...accum, [id]: orderFeesMap?.[id] ?? price };
    }, {});
    // default comp and additional service prices to 0 if they weren't set yet
    map[ID_COMP_AMOUNT] = order?.[ID_COMP_AMOUNT] || 0;
    map[ID_ADDITIONAL_SERVICES_AMOUNT] = order?.[ID_ADDITIONAL_SERVICES_AMOUNT] || 0;
    // load prices dictionary with the default prices
    setPricesDictionary(map);
  };

  return <React.Fragment>
    <div className="mb-2 col-sm-7">
      <Label className="col-form-label">{AdditionalFee.getAdditionalFeeName(AdditionalFee.ID_TRANSACTION_FEE)}</Label>
      <Input
        type="text"
        readOnly
        className="form-control"
        value={transactionFeeAmount}
      />
    </div>
    <Form>
      <FormikProvider value={formik}>
        <FieldArray name="fees" render={() => <React.Fragment>
          {orderBasedFees?.map(item => (
            <div key={item.additionalFeeId} className="mb-2 col-sm-7">
              <Label className="col-form-label">{AdditionalFee.getAdditionalFeeName(item.additionalFeeId)}</Label>
              <div className='d-flex'>
                {item?.count === 2 && <div className='multiple-times-label d-flex'><img src={verifyIdIcon} />{item?.count}x Signers</div>}
                <Input
                  type="number"
                  className="form-control disable-input-arrows"
                  name={`fees.${item?.additionalFeeId}`}
                  placeholder="Enter Fee Amount"
                  onChange={handleChangePriceDictionary(item.additionalFeeId)}
                  onFocus={onArrayFieldFocused("fees", item.additionalFeeId)}
                  onKeyDown={onFieldKeyDown}
                  onWheel={onFieldWheel}
                  value={pricesDictionary[item.additionalFeeId] ?? ""}
                  invalid={!!formik.errors.fees?.[item.additionalFeeId]}
              />
              </div>
              {!!formik.errors.fees?.[item.additionalFeeId] && <FormFeedback type="invalid">{formik.errors.fees?.[item.additionalFeeId]}</FormFeedback>}
            </div>
          ))}
        </React.Fragment>} />

        <div className="mb-2 col-sm-7">
          <Label className="col-form-label">Dealer Notes For Accounting</Label>
          <textarea
            className={classnames("form-control", { "is-invalid": !!formik.errors.dealerAccountingNotes })}
            placeholder="Your message..."
            name="dealerAccountingNotes"
            onChange={formik.handleChange}
            onFocus={onFieldFocused}
            value={formik.values.dealerAccountingNotes}
          />
          {!!formik.errors.dealerAccountingNotes && <FormFeedback type="invalid">{formik.errors.dealerAccountingNotes}</FormFeedback>}
        </div>

        <Accordion className="additional-costs-accordion col-sm-7 mt-4" open={open} toggle={handleToggle}>
          <AccordionItem>
            <AccordionHeader targetId="1">
              <h6 className="accordion-title">Additional Fees</h6>
            </AccordionHeader>
            <AccordionBody accordionId="1">
              {AdditionalFee.getNotarySurchargeFees().map(feeId => (
                <div key={feeId} className="additional-costs-row">
                  <Row className="justify-content-center">
                    <Col xs={7} className="d-flex align-items-center">
                      <Input
                        type="checkbox"
                        className="me-2 mt-0"
                        key={`${feeId}-${nonce}`}
                        onChange={handleCheckFee(feeId)}
                        checked={!!formik.values.fees?.hasOwnProperty(feeId)}
                      />
                      <Label>{AdditionalFee.getAdditionalFeeName(feeId)}</Label>
                      {/* Additional Vehicles Label */}
                      {feeId === AdditionalFee.ID_ADDITIONAL_VEHICLE && orderFees?.find(item => item.additionalFeeId === AdditionalFee.ID_ADDITIONAL_VEHICLE)?.count > 1 && (
                        <div className='multiple-times-label d-flex'>
                          <img src={additionalVehicleIcon} />
                          {orderFees?.find(item => item.additionalFeeId === AdditionalFee.ID_ADDITIONAL_VEHICLE)?.count}x Vehicles
                        </div>
                      )}
                    </Col>
                    <Col xs={5}>
                      <Input
                        type="number"
                        className="form-control disable-input-arrows"
                        placeholder="Enter Fee Amount"
                        onChange={handleChangePriceDictionary(feeId)}
                        onFocus={onArrayFieldFocused("fees", feeId)}
                        onKeyDown={onFieldKeyDown}
                        onWheel={onFieldWheel}
                        value={pricesDictionary[feeId] ?? ""}
                        invalid={!!formik.errors.fees?.[feeId]}
                      />
                      {!!formik.errors.fees?.[feeId] && <FormFeedback type="invalid">{formik.errors.fees[feeId]}</FormFeedback>}
                    </Col>
                  </Row>
                </div>
              ))}
              <div className="mb-2">
                <div className="additional-costs-row">
                  <Row className="justify-content-center">
                    <Col xs={7} className="d-flex align-self-center">
                      <Input
                        type="checkbox"
                        name="additionalServicesAmount"
                        key={`${ID_ADDITIONAL_SERVICES_AMOUNT}-${nonce}`}
                        className="me-2"
                        onChange={handleCheckFee(ID_ADDITIONAL_SERVICES_AMOUNT)}
                        checked={formik.values.hasOwnProperty("additionalServicesAmount")}
                      />
                      <Label>Additional Service</Label>
                    </Col>
                    <Col xs={5}>
                      <Input
                        type="number"
                        className="form-control disable-input-arrows"
                        name="additionalServicesAmount"
                        placeholder="Enter Fee Amount"
                        onChange={handleChangePriceDictionary(ID_ADDITIONAL_SERVICES_AMOUNT)}
                        onFocus={onFieldFocused}
                        onKeyDown={onFieldKeyDown}
                        onWheel={onFieldWheel}
                        value={pricesDictionary[ID_ADDITIONAL_SERVICES_AMOUNT] ?? ""}
                        invalid={!!formik.errors.additionalServicesAmount}
                      />
                      {!!formik.errors.additionalServicesAmount && <FormFeedback type="invalid"> {formik.errors.additionalServicesAmount}</FormFeedback>}
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="mb-2">
                <div className="additional-costs-row">
                  <Row className="justify-content-center">
                    <Col xs={7} className="d-flex align-self-center">
                      <Input
                        type="checkbox"
                        name="compensationAmount"
                        key={`${ID_COMP_AMOUNT}-${nonce}`}
                        className="me-2"
                        onChange={handleCheckFee(ID_COMP_AMOUNT)}
                        checked={formik.values.hasOwnProperty("compensationAmount")}
                      />
                      <Label>Comp</Label>
                    </Col>
                    <Col xs={5}>
                      <Input
                        type="number"
                        className="form-control disable-input-arrows"
                        placeholder="Enter Fee Amount"
                        name="compensationAmount"
                        onChange={handleChangePriceDictionary(ID_COMP_AMOUNT)}
                        onFocus={onFieldFocused}
                        onKeyDown={onFieldKeyDown}
                        onWheel={onFieldWheel}
                        value={pricesDictionary[ID_COMP_AMOUNT] ?? ""}
                        invalid={!!formik.errors.compensationAmount}
                      />
                      {!!formik.errors.compensationAmount && <FormFeedback type="invalid">{formik.errors.compensationAmount}</FormFeedback>}
                    </Col>
                  </Row>
                </div>
              </div>
            </AccordionBody>
          </AccordionItem>
        </Accordion>

        <TotalCosts
          label="Dealer Total Cost"
          value={getDealerFeeTotalCost(
            formik.values.fees,
            formik.values.compensationAmount,
            formik.values.additionalServicesAmount,
            transactionFeeAmount,
            orderBasedFees,
            additionalVehicleCount
          )}
          className="col-sm-7 mt-4"
        />

        <Col xs="auto col-sm-7 mt-4">
          <div className="text-end">
            <Button type="button" color="secondary" className="ms-2 mb-2" onClick={() => navigate(route(routes.view_order, id))}>
              Cancel
            </Button>
            {order.canAmendDealerAmount && <Button type="button" color="primary" className="ms-2 mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
              {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              Save
            </Button>}
          </div>
        </Col>
      </FormikProvider>
    </Form>
  </React.Fragment>
}

FormDealerEdit.propTypes = {
  order: PropTypes.object,
  orderFees: PropTypes.array,
  refreshHandler: PropTypes.func,
};

export default FormDealerEdit;