import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { Alert, Card, CardBody, Row, UncontrolledTooltip, Label, FormFeedback, Button, ButtonGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from "reactstrap";
import TextareaAutosize from "react-textarea-autosize";
import classnames from "classnames";
import Order from 'model/order';
import OrderDoc from 'model/orderDoc';
import VidRequest from 'model/vidRequest';
import { useDispatch, useSelector } from 'react-redux';
import { getGranularStatusName, getVIDStatusImage, showError, showSuccess } from 'helpers/utilHelper';
import { route, routes } from 'helpers/routeHelper';
import { deleteOrder, doOrderDocNormListCleanup, getFullOrder } from 'store/actions';
import Confirmation from 'components/Shared/Confirmation';
import inkSignServiceIcon from 'assets/images/ink-sign-service-icon.svg';
import eSignServiceIcon from 'assets/images/e-sign-service-icon.svg';
import notaryServiceIcon from 'assets/images/notary-service-icon.svg';
import rushOrderServiceIcon from 'assets/images/rush-order-icon.svg';
import multipleSignersIcon from 'assets/images/multiple-signers.svg';
import mustUploadDocsIcon from 'assets/images/notary-must-upload-docs.svg';
import docDeliveryShippingIcon from 'assets/images/delivery-shipping.svg';
import docDeliveryUploadIcon from 'assets/images/delivery-upload.svg';
import editIcon from "assets/images/edit-icon.svg";
import editServiceIcon from "assets/images/edit-services-icon.svg";
import fraudIcon from "assets/images/fraud-white-icon.svg";
import warningIcon from "assets/images/warning-white-icon.svg";
import passedVid from "assets/images/passed-VID.svg";
import noThumbprintIcon from 'assets/images/no-thumbprint-icon.svg';
import { getOrderInkSignStatus, getOrderESignStatus, getOrderVidStatus, cancelOrder, toggleRushService, addVIDService, removeVIDService, reviewOrder, uncancelOrder, getESignOrderDocs, addESign, removeESign } from "helpers/backendHelper";
import { perms, useAccess } from "context/access";
import Col from "components/Shared/Col";
import inactiveIcon from "assets/images/inactive-order.svg";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NOT_GRANTED, ORDER_ACCOUNTING_STATUS_INVALID_FOR_UNCANCEL, ORDER_CANNOT_REMOVE_ESIGN_SERVICE_WITH_DOCS, ORDER_CANNOT_REMOVE_THE_ONLY_SERVICE, ORDER_ESIGN_SERVICE_ALREADY_ADDED, ORDER_ESIGN_SERVICE_NOT_ADDED, ORDER_INTERNAL_STATUS_INVALID_FOR_REVIEW_MARKING, ORDER_INTERNAL_STATUS_INVALID_FOR_REVIEW_RESOLVE, ORDER_INVALID_STATUS_FOR_RUSH_OPERATIONS, ORDER_INVALID_STATUS_FOR_VID_OPERATIONS, ORDER_STATUS_INVALID_FOR_ADD_REMOVE_ESIGN } from 'helpers/errorHelper';

const SectionOrderActions = ({ order, id, refreshHandler }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { iAmGranted } = useAccess();

  /********** STATE **********/
  const [inkSignStatus, setInkSignStatus] = useState(null);
  const [inkSignStatusError, setInkSignStatusError] = useState(null);

  const [eSignStatus, setESignStatus] = useState(null);
  const [eSignStatusError, setESignStatusError] = useState(null);

  const [vidStatus, setVidStatus] = useState(null);
  const [vidStatusError, setVidStatusError] = useState(null);

  const [eSignOrderDocs, setESignOrderDocs] = useState([]);

  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [reviewConfirmationVisible, setReviewConfirmationVisible] = useState(false);
  const [highRiskConfirmation, setHighRiskConfirmation] = useState(false);
  const [fraudConfirmation, setFraudConfirmation] = useState(false);

  const [editServicesVisible, setEditServicesVisible] = useState(false);

  //  Services modals
  const [confirmOrderServicesVisible, setConfirmOrderServicesVisible] = useState(false);
  const [confirmAddEsignServicesVisible, setConfirmAddEsignServicesVisible] = useState(false);
  const [confirmRemoveEsignServicesVisible, setConfirmRemoveEsignServicesVisible] = useState(false);
  const [confirmDeleteDocumentsFirstVisible, setConfirmDeleteDocumentsFirstVisible] = useState(false);

  const { deleteInProgress, deleted } = useSelector(state => state.Order.Single);

  const [cancelConfirmationVisible, setCancelConfirmationVisible] = useState(false);
  const [uncancelConfirmationVisible, setUncancelConfirmationVisible] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  // Order Services - RUSH, ADD/REMOVE VID, ESIGN
  const [isToggleRushChanged, setIsToggleRushChanged] = useState(false);

  const [isAddVidChanged, setIsAddVidChanged] = useState(false);
  const [isRemoveVidChanged, setIsRemoveVidChanged] = useState(false);
  const [isVidServiceChanged, setIsVidServiceChanged] = useState(false);

  const [isAddEsignChanged, setIsAddEsignChanged] = useState(false);
  const [isRemoveEsignChanged, setIsRemoveEsignChanged] = useState(false);
  const [isEsignServiceChanged, setIsEsignServiceChanged] = useState(false);

  const [isEsignChecked, setIsEsignChecked] = useState(order.isEsignRequired);

  const refreshOrder = useCallback(() => dispatch(getFullOrder(id)), [id]);

  const isOrderCancelled = () => order.status === Order.STATUS_CANCELLED;

  const isOrderDraft = () => order.status === Order.STATUS_DRAFT;

  const isVidOnly = () => !order.isEsignRequired && !order.isInkSignRequired;

  const isEsignOnly = () => !order.isVidRequired && !order.isInkSignRequired;

  const isOrderCompleted = () => order.status === Order.STATUS_COMPLETED;

  const isOrderSeald = () => order.status === Order.STATUS_SEALED;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const isEditServicesVisible = () => iAmGranted(perms.add_order_services) && !isOrderCompleted() && !isOrderSeald();

  // check if can edit RUSH service
  // only if an order have a notary and the fees have not been set
  const canEditRushService = () => iAmGranted(perms.toggle_rush_service_for_orders) &&
    order.isNotaryRequired && order.accountingStatus == Order.ACCOUNTING_STATUS_NOT_SETTLED;

  // Can Add/Remove VID for:
  // order with notary and before reaching the Pick Notary status
  // order without notary - with ink sign - and while is in Pending Ink-Sign
  // order without notary - with esign - and while is in Pending E-sign
  // and it can be removed only if no action was performed

  const canAddVIDService = () => (order.isNotaryRequired && !order.isVidRequired && (order.status == Order.STATUS_PENDING_VID || order.status === Order.STATUS_NEW)) ||
    ([Order.STATUS_PENDING_INK_SIGN, Order.STATUS_PENDING_E_SIGN].includes(order.status) && !order.isNotaryRequired);

  const canRemoveVIDService = () => (order.status == Order.STATUS_PENDING_VID && (order.vidStatus === VidRequest.STATUS_CREATED || order.vidStatus === VidRequest.STATUS_PENDING));

  // Can Add Esign on order whenever it is not completed or cancelled
  const canAddEsignService = () => !order.isEsignRequired && ![Order.STATUS_CANCELLED, Order.STATUS_COMPLETED].includes(order.status);

  // Esign can be removed from the order as long as there are no documents added in the e-sign section
  // is not cancelled or completed and is not the only service on the order.
  const canRemoveEsignService = () => !isEsignOnly() && ![Order.STATUS_CANCELLED, Order.STATUS_COMPLETED].includes(order.status);

  const isEditServiceDisabled = () => !canEditRushService() &&
    !(canAddVIDService() || canRemoveVIDService()) &&
    !(canAddEsignService() || canRemoveEsignService());

  const canDoImmediateReview = () => iAmGranted(perms.mark_orders_for_immediate_review) && !order.internalStatus;

  /********** FORM CONFIG **********/

  const formImmediateReviewInitialValues = {
    escalationReason: '',
    internalStatus: Order.INTERNAL_STATUS_ON_HOLD,
  };

  const formik1 = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formImmediateReviewInitialValues,
    validationSchema: Yup.object({
      escalationReason: Yup.string().trim().required("Field is required"),
      internalStatus: Yup.number().required("Field is required"),
    }),
    onSubmit: values => {
      markAsImmediateReview(values);
    },
  });

  const formHighRiskInitialValues = {
    escalationReason: '',
    internalStatus: Order.INTERNAL_STATUS_HIGH_RISK,
  };

  const formik2 = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formHighRiskInitialValues,
    validationSchema: Yup.object({
      escalationReason: Yup.string().trim().required("Field is required"),
    }),
    onSubmit: values => {
      markOrderAsHighRisk(values);
    },
  });

  const formFraudInitialValues = {
    escalationReason: '',
    internalStatus: Order.INTERNAL_STATUS_FRAUD,
  };

  const formik3 = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formFraudInitialValues,
    validationSchema: Yup.object({
      escalationReason: Yup.string().trim().required("Field is required"),
    }),
    onSubmit: values => {
      markOrderAsFraud(values);
    },
  });

  /********** EFFECTS **********/

  useEffect(() => {
    if (deleted === true) {
      showSuccess("Order has been deleted");
      navigate(route(routes.list_orders));
    } else if (deleted === false) {
      showError("Unable to delete order");
    }
  }, [deleted, navigate]);

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the data
    if (order.isInkSignRequired)
      refreshOrderInkSignStatus();
  }, [id]);

  useEffect(() => {
    if (order.isEsignRequired)
      refreshOrderESignStatus();
  }, [id]);

  useEffect(() => {
    if (order.isVidRequired);
    refreshOrderVidStatus();
  }, [id]);

  useEffect(() => {
    window.addEventListener('resize', updateScreenWidth);
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, [screenWidth]);

  useEffect(() => {
    // make the initial remote call to get the data
    refreshESignOrderDocs();
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderDocNormListCleanup());
    }
  }, [order.id]);

  /********** OTHER **********/

  const refreshOrderInkSignStatus = () => {
    getOrderInkSignStatus(id)
      .then(response => {
        setInkSignStatus(response.inkSign);
      })
      .catch(ex => {
        setInkSignStatusError(ex);
      })
  };

  const refreshOrderESignStatus = () => {
    getOrderESignStatus(id)
      .then(response => {
        setESignStatus(response.eSign);
      })
      .catch(ex => {
        setESignStatusError(ex);
      })
  };

  const refreshOrderVidStatus = () => {
    // make the initial remote call to get the data
    getOrderVidStatus(id)
      .then(response => {
        setVidStatus(response.vid);
      })
      .catch(ex => {
        setVidStatusError(ex);
      })
  };

  const refreshESignOrderDocs = () => {
    getESignOrderDocs(id)
      .then(response => {
        setESignOrderDocs(response.orderDocs);
      })
      .catch(err => {
        console.log('error')
      })
  };

  //  Review order - under review/ high risk/ fraud
  const markAsImmediateReview = data => {
    reviewOrder(order.id, data)
      .then(() => {
        showSuccess("Order is now under review");
        formik1.resetForm();
        setReviewConfirmationVisible(false);
        refreshOrder();
      })
      .catch((err) => {
        if (err.code == ORDER_INTERNAL_STATUS_INVALID_FOR_REVIEW_RESOLVE) {
          showError("Internal status invalid for resolve the review.");
        }

        if (err.code == ORDER_INTERNAL_STATUS_INVALID_FOR_REVIEW_MARKING) {
          showError("Internal status invalid for making review");
        }

        if (err.code == NOT_GRANTED) {
          showError("You do not have access for this action");
        }
        showError("Unable to review order");
      })
  };

  const markOrderAsHighRisk = data => {
    reviewOrder(order.id, data)
      .then(() => {
        showSuccess("Order is now mark as high risk");
        formik2.resetForm();
        setHighRiskConfirmation(false);
        refreshOrder();
      })
      .catch(() => {
        showError("Unable to mark order as high risk");
      })
  };

  const markOrderAsFraud = data => {
    reviewOrder(order.id, data)
      .then(() => {
        showSuccess("Order is now mark as fraud");
        formik3.resetForm();
        setFraudConfirmation(false);
        refreshOrder();
      })
      .catch(() => {
        showError("Unable to mark order as fraud");
      })
  };

  //  Ignore order status - under review/ high risk/ fraud, on hold
  const ignoreReviewOrder = async () => {
    try {
      await reviewOrder(order.id, { escalationReason: '', internalStatus: Order.INTERNAL_STATUS_IGNORE });
      showSuccess("Order is now ignored for review");
      setReviewConfirmationVisible(false);
      refreshOrder();
    } catch (err) {
      showError("Unable to ignore order");
    }
  };

  const resetServices = () => {
    // Reset services state
    setIsToggleRushChanged(false);

    // VID Services
    setIsVidServiceChanged(false);
    setIsAddVidChanged(false);
    setIsRemoveVidChanged(false);

    // E-sign Services
    setIsEsignServiceChanged(false);
    setIsAddEsignChanged(false);
    setIsRemoveEsignChanged(false);

    // Close confirm services modal
    setConfirmOrderServicesVisible(false);
  }

  const getVidServiceStatus = (addVIDService) => {
    if (addVIDService) {
      setIsAddVidChanged(true);
      setIsRemoveVidChanged(false);
    } else {
      setIsRemoveVidChanged(true);
      setIsAddVidChanged(false);
    }
    setIsVidServiceChanged(true);
    refreshOrderVidStatus();
  };

  const getEsignServiceStatus = (eSignService) => {
    // Close edit services modal
    setEditServicesVisible(false);

    if (!eSignService) {
      // If there are documents in the Esgin section and the user wants to remove the esign service
      // display a message to remove the documents first
      if (eSignOrderDocs?.length > 0) setConfirmDeleteDocumentsFirstVisible(true);

      // If there are no documents in the Esgin section and the user wants to remove the esign service
      // Open confirm Remove Esign service modal
      if (eSignOrderDocs?.length === 0) setConfirmRemoveEsignServicesVisible(true);
    }

    if (eSignService) {
      // Open confirm Add Esign service modal
      setConfirmAddEsignServicesVisible(true);
    }

    setIsEsignServiceChanged(true);
  }

  // Add / Remove RUSH Service
  const handleToggleRushService = () => {
    toggleRushService(id)
      .then(() => {
        showSuccess("Rush service modified with success.");
        refreshOrder();
        refreshHandler();
      })
      .catch((error) => {
        if (error.code == ORDER_INVALID_STATUS_FOR_RUSH_OPERATIONS) {
          showError("Invalid status for rush operations.");
        } else showError("Unable to modify Rush service.");
      })
  }

  //  Add VID Service
  const handleAddVIDService = () => {
    addVIDService(id)
      .then(() => {
        showSuccess("Verify ID service added with success.");
        refreshOrder();
        refreshHandler();
      })
      .catch((error) => {
        if (error.code == ORDER_INVALID_STATUS_FOR_VID_OPERATIONS) {
          showError("Invalid status for adding Verify ID service.");
        } else {
          showError("Unable to modify Verify ID service.")
        }
      })
  }

  //  Remove VID Service
  const handleRemoveVIDService = () => {
    removeVIDService(id)
      .then(() => {
        showSuccess("Verify ID service removed with success.");
        refreshOrder();
        refreshHandler();
      })
      .catch((error) => {
        if (error.code === 1266) {
          showError("Invalid status for removing Verify ID service.");
        } else {
          showError("Unable to modify Verify ID service.")
        }
      })
  }

  //  Add Esign Service
  const handleAddEsignService = () => {
    addESign(id)
      .then(() => {
        showSuccess("E-sign service added with success.");
        refreshOrder();
        refreshOrderESignStatus();
        refreshESignOrderDocs();
        refreshHandler();
      })
      .catch((error) => {
        switch (error.code) {
          case ORDER_ESIGN_SERVICE_ALREADY_ADDED:
            showError("E-sign service already added");
            break;
          case ORDER_STATUS_INVALID_FOR_ADD_REMOVE_ESIGN:
            showError("Service cannot be added in this order status");
            break;
          default:
            showError("Unable to add e-sign service");
            break;
        }
      });
  }

  //  Remove Esign Service
  const handleRemoveEsignService = () => {
    removeESign(id)
      .then(() => {
        showSuccess("E-sign service removed with success.");
        refreshOrder();
        refreshOrderESignStatus();
        refreshESignOrderDocs();
        refreshHandler();
      })
      .catch((error) => {
        switch (error.code) {
          case ORDER_ESIGN_SERVICE_NOT_ADDED:
            showError("E-sign service not present");
            break;
          case ORDER_CANNOT_REMOVE_ESIGN_SERVICE_WITH_DOCS:
            showError("Please remove all e-sign documents before removing the service");
            break;
          case ORDER_STATUS_INVALID_FOR_ADD_REMOVE_ESIGN:
            showError("Service cannot be removed in this order status");
            break;
          case ORDER_CANNOT_REMOVE_THE_ONLY_SERVICE:
            showError("Cannot remove the only service");
            break;
          default:
            showError("Unable to remove e-sign service");
            break;
        }
      })
  }

  // Confirm order services - RUSH, ADD/REMOVE VID, ADD/REMOVE E-SIGN
  const handleConfirmOrderServices = () => {
    if (isToggleRushChanged) {
      handleToggleRushService();
    }

    if (isVidServiceChanged) {
      if (isAddVidChanged) handleAddVIDService();

      if (isRemoveVidChanged) handleRemoveVIDService();
    }

    if (isEsignServiceChanged) {
      if (isAddEsignChanged) handleAddEsignService();

      if (isRemoveEsignChanged) handleRemoveEsignService();
    }

    // Reset services state
    resetServices();
  }

  const color = Order.getGranularStatusColor(order.granularStatus);

  const removeOrder = () => dispatch(deleteOrder(order.id));

  // Update screen width state variable
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  // Calculate the time difference in hours
  const timePassedInHours = Math.floor((Date.now() - order.updatedTs * 1000) / 3600000);

  const handleCancelOrder = () => {
    cancelOrder(id)
      .then(() => {
        refreshOrder();
        showSuccess("Order have been canceled.");
        refreshHandler()
      })
      .catch(() => {
        showError("Unable to cancel order.");
      })
  }

  const handleUncancelOrder = () => {
    uncancelOrder(id)
      .then(() => {
        refreshOrder();
        showSuccess("Order have been uncancelled.");
        refreshHandler()
      })
      .catch(ex => {
        if (ex.code == ORDER_ACCOUNTING_STATUS_INVALID_FOR_UNCANCEL) {
          showError("Unable to uncancel order after fees have been settled");
          return;
        }
        showError("Unable to uncancel order.");
      })
  }

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik1.errors;
    delete errors[name];
    formik1.setStatus(errors);
  }

  const onFieldFocused2 = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik2.errors;
    delete errors[name];
    formik2.setStatus(errors);
  }

  const onFieldFocused3 = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik3.errors;
    delete errors[name];
    formik3.setStatus(errors);
  }

  const canUncancelOrder = () => isOrderCancelled() && order.accountingStatus == Order.ACCOUNTING_STATUS_NOT_SETTLED;

  const renderActionButtons = () => {
    return (<div className="col-5 d-flex align-items-center justify-content-end display-action-buttons">
      {/* Edit services - Vid and Rush */}
      {isEditServicesVisible() && <ButtonGroup className="pe-2">
        <Button color="primary" className="square-icon-btn" onClick={() => setEditServicesVisible(true)}>
          <img src={editIcon} className="mdi mdi-close font-size-18 mt-1" alt="edit-icon" />
        </Button>
        <Button color="primary" className="btn py-1" onClick={() => setEditServicesVisible(true)}>
          Edit Services
        </Button>
      </ButtonGroup>}

      {/* Immediate Review */}
      {canDoImmediateReview() && <div className="pe-2"><Button color="primary" onClick={() => { setReviewConfirmationVisible(true) }}>Immediate Review</Button></div>}

      <div className="pe-2">
        {/* Actions made only by the Admin and Master Admin */}
        {iAmGranted(perms.mark_orders_for_under_review) && <>
          {/* Under Review - Dropdown for High Risk/Fraud/Ignore */}
          {order.internalStatus === Order.INTERNAL_STATUS_UNDER_REVIEW &&
            <Dropdown className="d-inline-block under-review-dropdown" isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret color="primary">
                Under Review
                <i className="fas fa-caret-down ms-3" />
              </DropdownToggle>
              <DropdownMenu className="review-dropdown">
                <DropdownItem onClick={() => { setHighRiskConfirmation(true) }}>High Risk </DropdownItem>
                <DropdownItem onClick={() => { setFraudConfirmation(true) }}>Fraud </DropdownItem>
                <DropdownItem onClick={() => { ignoreReviewOrder() }}>Ignore</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          }

          {/* High Risk Order - Button to Ignore */}
          {order.internalStatus === Order.INTERNAL_STATUS_HIGH_RISK &&
            <Dropdown className="d-inline-block under-review-dropdown" isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret color="danger">
                <img className="ps-2" src={warningIcon} alt="fraud" />
                <span className='d-inline mx-3'>High Risk</span>
                <i className="fas fa-caret-down ms-2" />
              </DropdownToggle>
              <DropdownMenu className="review-dropdown">
                <DropdownItem onClick={() => { ignoreReviewOrder() }}>Clear status</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          }

          {/* Fraud Order - Button to Ignore */}
          {order.internalStatus === Order.INTERNAL_STATUS_FRAUD &&
            <Dropdown className="d-inline-block under-review-dropdown" isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret color="dark">
                <img className="ps-2" src={fraudIcon} alt="fraud" />
                <span className='d-inline mx-3'>Fraud</span>
                <i className="fas fa-caret-down" />
              </DropdownToggle>
              <DropdownMenu className="review-dropdown">
                <DropdownItem onClick={() => { ignoreReviewOrder() }}>Clear status</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          }
        </>}

        {/* On Hold Order - Button to Ignore */}
        {order.internalStatus === Order.INTERNAL_STATUS_ON_HOLD &&
          <Dropdown className="d-inline-block" isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret color="primary">
              On Hold
              <i className="fas fa-caret-down mx-2" />
            </DropdownToggle>
            <DropdownMenu className="review-dropdown">
              <DropdownItem onClick={() => { ignoreReviewOrder() }}>Clear status</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        }
      </div>

      {/* Cancel Order */}
      {!isOrderCompleted() && !isOrderDraft() && !isOrderCancelled() &&
        <ButtonGroup>
          <Button color="danger" className="square-icon-btn" onClick={() => setCancelConfirmationVisible(true)}>
            <i className="mdi mdi-close font-size-18 mt-1" />
          </Button>
          <Button color="danger" className="btn py-1" onClick={() => setCancelConfirmationVisible(true)}>
            Cancel Order
          </Button>
        </ButtonGroup>
      }

      {/* Uncancel Order */}
      {canUncancelOrder() &&
        <ButtonGroup>
          <Button color="dark" className="square-icon-btn" onClick={() => setUncancelConfirmationVisible(true)}>
            <i className="mdi mdi-backup-restore font-size-18 mt-1" />
          </Button>
          <Button color="dark" className="btn py-1" onClick={() => setUncancelConfirmationVisible(true)}>
            Uncancel Order
          </Button>
        </ButtonGroup>
      }

      {/* Delete Order */}
      {iAmGranted(perms.delete_order) && isOrderDraft() &&
        <ButtonGroup>
          <Button color="danger" className="square-icon-btn" onClick={() => setDeleteConfirmationVisible(true)} disabled={deleteInProgress}>
            <i className="bx bx-trash font-size-18 mt-1" />
          </Button>
          <Button color="danger" className="btn py-1" onClick={() => setDeleteConfirmationVisible(true)} disabled={deleteInProgress}>
            Delete Order
          </Button>
        </ButtonGroup>
      }
    </div>)
  }

  const renderDesktopView = () => {
    return (<div className='order-actions'>
      <div className='d-flex align-items-center mb-3'>
        <span className='header-title font-weight-semibold'>Order ID {order.id}</span>
        {order.isInactive && order.status != Order.STATUS_CANCELLED && (<span className='ms-5'>
          <img src={inactiveIcon} />
          <span className="ms-2">Inactive({timePassedInHours}h)</span>
        </span>)}
      </div>
      <div className='row'>
        <div className="col-2 pe-0">
          <div className='mb-2'>
            <p className='d-inline font-weight-normal header-stats'>Status:</p>
            <span className={`badge header-badge rounded-pill bg-${color} mx-2`}>{getGranularStatusName(order.granularStatus)}</span>
          </div>
          <div>
            {order.isNotaryRequired && <><img id='notary-service' src={notaryServiceIcon} className="me-2" /><UncontrolledTooltip placement="top" target='notary-service'>Notary service</UncontrolledTooltip></>}
            {order.isRushRequired && <><img id='rush-order' src={rushOrderServiceIcon} className="me-2" /><UncontrolledTooltip placement="top" target='rush-order'>Rush order</UncontrolledTooltip></>}
            {order.docDeliveryOption == Order.DOC_DELIVERY_OPTION_SHIPPING &&
              <><img id={`doc-delivery-shipping`} src={docDeliveryShippingIcon} className="me-2" /><UncontrolledTooltip placement="top" target={`doc-delivery-shipping`}>Documents shipping</UncontrolledTooltip></>
            }
            {order.docDeliveryOption == Order.DOC_DELIVERY_OPTION_UPLOAD &&
              <><img id={`doc-delivery-upload`} src={docDeliveryUploadIcon} className="me-2" /><UncontrolledTooltip placement="top" target={`doc-delivery-upload`}>Documents upload</UncontrolledTooltip></>
            }
            {order?.signers?.length > 1 && <><img id='multiple-signers' src={multipleSignersIcon} className='me-2' /><UncontrolledTooltip placement="top" target='multiple-signers'>Multiple signers</UncontrolledTooltip></>}
            {order.notaryMustUploadDocs && <><img id='must-upload-docs' src={mustUploadDocsIcon} className='me-2 must-upload-icon' /><UncontrolledTooltip placement="top" target='must-upload-docs'>Notary must upload docs</UncontrolledTooltip></>}
            {!order.isAoiThumbprintRequired && order.isNotaryRequired && <><img id='no-thumbprint-aoi' src={noThumbprintIcon} className='me-2' /><UncontrolledTooltip placement="top" target='no-thumbprint-aoi'>No Thumbprint AOI</UncontrolledTooltip></>}
          </div>
        </div>
        {!isOrderDraft() && <div className="col-5 d-flex display-status">
          {order.isVidRequired && !!vidStatus && <>
            <div className="divider me-2"></div>
            <div className='d-flex align-items-start'>
              {getVIDStatusImage(order.vidStatus)}
              <div className='d-flex flex-column'>
                <span className='d-inline header-stats'>Identity Validation Overview</span>
                {!!vidStatusError ? <div className='badge header-badge rounded-pill mt-2 bg-dark'>{'Unable to load status'}</div> :
                  <div className={`badge header-badge rounded-pill mt-2 bg-${VidRequest.getStatusColor(vidStatus.status)}`}>{vidStatus.statusName}</div>
                }
              </div>
            </div>
          </>}
          {order.isEsignRequired && !!eSignStatus && <>
            <div className="divider me-1 ms-1"></div>
            <div className='d-flex align-items-start'>
              <img id='e-sign' src={eSignServiceIcon} className="me-2" />
              <div className='d-flex flex-column'>
                <span className='d-inline header-stats'>e-Signed Contract</span>
                {!!eSignStatusError ? <div className='badge header-badge rounded-pill mt-2 bg-dark'>{'Unable to load status'}</div> :
                  <div className={`badge header-badge rounded-pill mt-2 bg-${OrderDoc.getStatusColor(eSignStatus.status)}`}>{eSignStatus.statusName}</div>}
              </div>
            </div>
          </>}
          {order.isInkSignRequired && !!inkSignStatus && <>
            <div className="divider me-1 ms-1"></div>
            <div className='d-flex align-items-start'>
              <img id='ink-sign' src={inkSignServiceIcon} className="me-2" />
              <div className='d-flex flex-column'>
                <span className='d-inline header-stats'>Ink Signed Contract</span>
                {!!inkSignStatusError ? <div className='badge header-badge rounded-pill mt-2 bg-dark'>{'Unable to load status'}</div> :
                  <div className={`badge header-badge rounded-pill mt-2 bg-${OrderDoc.getStatusColor(inkSignStatus.status)}`}>{inkSignStatus.statusName}</div>}
              </div>
            </div>
          </>}
          <div className="divider me-2 ms-2"></div>
        </div>}
        {/* render buttons for order actions */}
        {renderActionButtons()}
      </div>
    </div>)
  }

  const renderTabletView = () => {
    return (<div className='order-actions'>
      <Row className='mb-4'>
        <Col sm={12} xl={6}>
          <span>
            <div className="row mb-2">
              <div className='col-6 header-title font-weight-semibold'>Order ID {order.id}</div>
              {order.isInactive && order.status != Order.STATUS_CANCELLED && (<div className='col-6 d-flex align-items-center justify-content-end'>
                <img src={inactiveIcon} />
                <span className="ms-2">Inactive({timePassedInHours}h)</span>
              </div>)}
            </div>
            <div>
              <p className='d-inline font-weight-normal header-stats'>Status:</p>
              <span className={`badge header-badge rounded-pill bg-${color} mx-2`}>{getGranularStatusName(order.granularStatus)}</span>
              <span>
                {order.isNotaryRequired && <><img id='notary-service' src={notaryServiceIcon} className="me-2" /><UncontrolledTooltip placement="top" target='notary-service'>Notary service</UncontrolledTooltip></>}
                {order.isRushRequired && <><img id='rush-order' src={rushOrderServiceIcon} className="me-2" /><UncontrolledTooltip placement="top" target='rush-order'>Rush order</UncontrolledTooltip></>}
                {order?.signers?.length > 1 && <><img id='multiple-signers' src={multipleSignersIcon} className='me-2' /><UncontrolledTooltip placement="top" target='multiple-signers'>Multiple signers</UncontrolledTooltip></>}
              </span>
            </div>
          </span>
        </Col>
        <Col sm={12} xl={6}>
          <div className="d-flex">
            {renderActionButtons()}
          </div>
        </Col>
      </Row>
      <Row>
        {!isOrderDraft() && <div className="d-flex display-status">
          {order.isVidRequired && !!vidStatus && <>
            <div className='d-flex align-items-start'>
              {getVIDStatusImage(order.vidStatus)}
              <div className='d-flex flex-column'>
                <span className='d-inline header-stats'>Identity Validation Overview:</span>
                {!!vidStatusError ? <div className='badge header-badge rounded-pill mt-2 bg-dark'>{'Unable to load status'}</div> :
                  <div className={`badge header-badge rounded-pill mt-2 bg-${VidRequest.getStatusColor(vidStatus.status)}`}>{vidStatus.statusName}</div>
                }
              </div>
            </div>
            <div className="divider me-1 ms-1"></div>
          </>}
          {order.isEsignRequired && !!eSignStatus && <>
            <div className='d-flex align-items-start'>
              <img id='e-sign' src={eSignServiceIcon} className="me-2" />
              <div className='d-flex flex-column'>
                <span className='d-inline header-stats'>e-Signed Contract</span>
                {!!eSignStatusError ? <div className='badge header-badge rounded-pill mt-2 bg-dark'>{'Unable to load status'}</div> :
                  <div className={`badge header-badge rounded-pill mt-2 bg-${OrderDoc.getStatusColor(eSignStatus.status)}`}>{eSignStatus.statusName}</div>}
              </div>
            </div>
            <div className="divider me-1 ms-1"></div>
          </>}
          {order.isInkSignRequired && !!inkSignStatus && <>
            <div className='d-flex align-items-start'>
              <img id='ink-sign' src={inkSignServiceIcon} className="me-2" />
              <div className='d-flex flex-column'>
                <span className='d-inline header-stats'>Ink Signed Contract</span>
                {!!inkSignStatusError ? <div className='badge header-badge rounded-pill mt-2 bg-dark'>{'Unable to load status'}</div> :
                  <div className={`badge header-badge rounded-pill mt-2 bg-${OrderDoc.getStatusColor(inkSignStatus.status)}`}>{inkSignStatus.statusName}</div>}
              </div>
            </div>
          </>}
        </div>}
      </Row>
    </div>)
  }

  const renderView = () => {
    if (screenWidth >= 1200) {
      return renderDesktopView();
    } else {
      return renderTabletView();
    }
  };

  return <React.Fragment>
    {isOrderCancelled() && <Alert color="danger">
      <i className="bx bx-block label-icon ms-2"></i> This order has been Canceled
    </Alert>}

    <div>
      <Card>
        <CardBody className="px-0 pb-0">
          <div className={classnames('card-section pb-3')}>
            {renderView()}
          </div>
        </CardBody>
      </Card>
    </div>

    {/* Delete Order Modal */}
    {deleteConfirmationVisible && <Confirmation
      confirmBtnText="Delete"
      cancelBtnText="Close"
      style={{ backgroundColor: '#fff' }}
      reverseButtons={false}
      onConfirm={() => {
        setDeleteConfirmationVisible(false);
        removeOrder();
      }}
      onCancel={() => { setDeleteConfirmationVisible(false) }}
    >
      Are you sure you want to delete order &quot;{order.id}&quot;?
    </Confirmation>}

    {/* Review Order Modal */}
    {reviewConfirmationVisible && <Confirmation
      style={{ backgroundColor: 'white', minWidth: 600 }}
      title="Immediate Review"
      confirmBtnText="Confirm"
      cancelBtnText="Close"
      onConfirm={formik1.handleSubmit}
      onCancel={() => { setReviewConfirmationVisible(false); formik1.resetForm(); }}
      closeOnClickOutside={false}
    >
      <div className="my-3 mx-4">
        <div className="d-flex justify-content-center mb-3">
          <div className="form-check mx-2">
            <input
              className="form-check-input"
              type="radio"
              name="onHold"
              id="onHold"
              value={Order.INTERNAL_STATUS_ON_HOLD}
              checked={formik1.values.internalStatus == Order.INTERNAL_STATUS_ON_HOLD}
              onChange={e => formik1.setFieldValue("internalStatus", Number(e.target.value))}
            />
            <label className="form-check-label" htmlFor="onHold">On hold</label>
          </div>
          <div className="form-check mx-2">
            <input
              className="form-check-input"
              type="radio"
              name="underReview"
              id="underReview"
              value={Order.INTERNAL_STATUS_UNDER_REVIEW}
              checked={formik1.values.internalStatus == Order.INTERNAL_STATUS_UNDER_REVIEW}
              onChange={e => formik1.setFieldValue("internalStatus", Number(e.target.value))}
            />
            <label className="form-check-label" htmlFor="underReview">Under review</label>
          </div>
        </div>
        <div className="text-start">
          <Label className="form-label">Reason of request *</Label>
          <TextareaAutosize
            minRows={3}
            maxRows={7}
            className={classnames("form-control", { "is-invalid": !!formik1.errors.escalationReason })}
            name="escalationReason"
            onChange={formik1.handleChange}
            onFocus={onFieldFocused}
            value={formik1.values.escalationReason}
          />
        </div>
        {!!formik1.errors.escalationReason && <FormFeedback type="invalid" className="d-block">{formik1.errors.escalationReason}</FormFeedback>}
      </div>
    </Confirmation>}

    {/* High Risk Order Modal */}
    {highRiskConfirmation && <Confirmation
      style={{ backgroundColor: 'white', minWidth: 600 }}
      title="High Risk"
      confirmBtnText="Confirm"
      cancelBtnText="Close"
      onConfirm={formik2.handleSubmit}
      onCancel={() => { setHighRiskConfirmation(false); formik2.resetForm(); }}
      closeOnClickOutside={false}
    >
      <div className="my-3 mx-4">
        <div className="text-start">
          <Label className="form-label">Reason of request</Label>
          <TextareaAutosize
            minRows={3}
            maxRows={7}
            className={classnames("form-control", { "is-invalid": !!formik2.errors.escalationReason })}
            name="escalationReason"
            onChange={formik2.handleChange}
            onFocus={onFieldFocused2}
            value={formik2.values.escalationReason}
          />
        </div>
        {!!formik2.errors.escalationReason && <FormFeedback type="invalid" className="d-block">{formik2.errors.escalationReason}</FormFeedback>}
      </div>
    </Confirmation>}

    {/* Fraud Order Modal */}
    {fraudConfirmation && <Confirmation
      style={{ backgroundColor: 'white', minWidth: 600 }}
      title="Fraud"
      confirmBtnText="Confirm"
      cancelBtnText="Close"
      onConfirm={formik3.handleSubmit}
      onCancel={() => { setFraudConfirmation(false); formik3.resetForm(); }}
      closeOnClickOutside={false}
    >
      <div className="my-3 mx-4">
        <div className="text-start">
          <Label className="form-label">Reason of request</Label>
          <TextareaAutosize
            minRows={3}
            maxRows={7}
            className={classnames("form-control", { "is-invalid": !!formik3.errors.escalationReason })}
            name="escalationReason"
            onChange={formik3.handleChange}
            onFocus={onFieldFocused3}
            value={formik3.values.escalationReason}
          />
        </div>
        {!!formik3.errors.escalationReason && <FormFeedback type="invalid" className="d-block">{formik3.errors.escalationReason}</FormFeedback>}
      </div>
    </Confirmation>}

    {/* Cancel Order Modal */}
    {cancelConfirmationVisible && <Confirmation
      confirmBtnText="Yes"
      cancelBtnText="Close"
      style={{ backgroundColor: '#fff' }}
      reverseButtons={false}
      onConfirm={() => {
        setCancelConfirmationVisible(false);
        handleCancelOrder();
      }}
      onCancel={() => { setCancelConfirmationVisible(false) }}
    >
      <span style={{ color: '#556EE6' }}>Are you sure you want to cancel this order ?</span>
    </Confirmation>}

    {/* Uncancel Order Modal */}
    {uncancelConfirmationVisible && <Confirmation
      confirmBtnText="Yes"
      cancelBtnText="Close"
      style={{ backgroundColor: '#fff' }}
      reverseButtons={false}
      onConfirm={() => {
        setUncancelConfirmationVisible(false);
        handleUncancelOrder();
      }}
      onCancel={() => { setUncancelConfirmationVisible(false) }}
    >
      <span style={{ color: '#556EE6' }}>Are you sure you want to uncancel this order ?</span>
    </Confirmation>}

    {/* Services Modal */}
    {editServicesVisible && <Confirmation
      style={{ backgroundColor: 'white', minWidth: 600, paddingBottom: 35 }}
      customIcon={editServiceIcon}
      title="Edit Services"
      confirmBtnText="Confirm"
      cancelBtnText="Close"
      onConfirm={() => {
        setEditServicesVisible(false);
        setConfirmOrderServicesVisible(true);
      }}
      onCancel={() => {
        setIsToggleRushChanged(false);
        setIsVidServiceChanged(false);
        setIsEsignServiceChanged(false);

        setEditServicesVisible(false);
        // reset the check value for eSign with the value from the order
        setIsEsignChecked(order.isEsignRequired);
      }}
      closeOnClickOutside={false}
      reverseButtons={false}
      disabled={isEditServiceDisabled()}
    >
      <div className="d-flex justify-content-center align-items-center my-3">
        <Col sm={8}>
          {!isVidOnly() && <React.Fragment>
            {/* Verify ID - Service */}
            <Row>
              <Col sm={6}>
                <div className="d-flex align-items-center">
                  <img src={passedVid} className="me-2" />
                  <div className="font-size-13 font-weight-normal">Verify ID</div>
                </div>
              </Col>
              <Col sm={6}>
                <div className="form-check form-switch form-switch-lg d-inline-block">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="verifyID"
                    onChange={e => getVidServiceStatus(e.target.checked)}
                    defaultChecked={order.isVidRequired}
                    disabled={!(canAddVIDService() || canRemoveVIDService())}
                  />
                  <Label className="form-check-label" htmlFor="verifyID" />
                </div>
              </Col>
            </Row>

            {/* Rush order - Service */}
            <Row className="mt-2">
              <Col sm={6}>
                <div className="d-flex align-items-center">
                  <img src={rushOrderServiceIcon} className="me-2" />
                  <div className="font-size-13 font-weight-normal">Rush order</div>
                </div>
              </Col>
              <Col sm={6}>
                <div className="form-check form-switch form-switch-lg d-inline-block">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="rush"
                    onChange={() => setIsToggleRushChanged(true)}
                    defaultChecked={order.isRushRequired}
                    disabled={!canEditRushService()}
                  />
                  <Label className="form-check-label" htmlFor="rush" />
                </div>
              </Col>
            </Row>
          </React.Fragment>}

          {!isEsignOnly() && <React.Fragment>
            {/* E-sign - Service */}
            <Row className="mt-2">
              <Col sm={6}>
                <div className="d-flex align-items-center">
                  <img src={eSignServiceIcon} className="me-2" />
                  <div className="font-size-13 font-weight-normal">E-sign</div>
                </div>
              </Col>
              <Col sm={6}>
                <div className="form-check form-switch form-switch-lg d-inline-block">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="esign"
                    onChange={e => {
                      getEsignServiceStatus(e.target.checked)
                    }}
                    checked={isEsignChecked}
                    disabled={!(canAddEsignService() || canRemoveEsignService())}
                  />
                  <Label className="form-check-label" htmlFor="esign" />
                </div>
              </Col>
            </Row>
          </React.Fragment>}
        </Col>
      </div>
    </Confirmation>}

    {/* Confirm Add Order Services Modal */}
    {confirmOrderServicesVisible && <Confirmation
      style={{ backgroundColor: 'white', minWidth: 350, paddingBottom: 35 }}
      customIcon={editServiceIcon}
      onConfirm={() => handleConfirmOrderServices()}
      onCancel={() => resetServices()}
      closeOnClickOutside={false}
      reverseButtons={false}
    >
      <div className="d-flex justify-content-center align-items-center">
        <Col sm={8}>
          <span style={{ color: '#556EE6', fontSize: 15 }}>Changing the settings here will impact the progress of the order and may entail additional fees!</span>
        </Col>
      </div>
    </Confirmation>}

    {/* ADD E-sign - Modal */}
    {confirmAddEsignServicesVisible && <Confirmation
      style={{ backgroundColor: 'white', minWidth: 350, paddingBottom: 35 }}
      customIcon={editServiceIcon}
      onConfirm={() => {
        setIsAddEsignChanged(true);
        // see in the UI the e-sign service checked
        setIsEsignChecked(true);
        setIsRemoveEsignChanged(false);

        // close add esign modal
        setConfirmAddEsignServicesVisible(false);
        // open edit services modal
        setEditServicesVisible(true);
      }}
      onCancel={() => {
        setIsAddEsignChanged(false);

        // close add e-sign message(modal)
        setConfirmAddEsignServicesVisible(false);
        // open edit services modal
        setEditServicesVisible(true);
      }}
      closeOnClickOutside={false}
      reverseButtons={false}
    >
      <div className="d-flex justify-content-center">
        <Col sm={10}>
          <span style={{ color: '#556EE6', fontSize: 15, textAlign: 'left' }}>Electronic Signature will be added as a service on this order. The documents to be signed need to be uploaded in the <strong>E-Signed Documents</strong>. After the signature placement, the Customer will be notified to Electronically Sign the documents.</span>
        </Col>
      </div>
    </Confirmation>}

    {/* REMOVE E-sign - Modal */}
    {confirmRemoveEsignServicesVisible && <Confirmation
      style={{ backgroundColor: 'white', minWidth: 350, paddingBottom: 35 }}
      customIcon={editServiceIcon}
      onConfirm={() => {
        setIsRemoveEsignChanged(true);
        // see in the UI the e-sign service unchecked
        setIsEsignChecked(false);
        setIsAddEsignChanged(false);

        // close confirm remove e-sign message(modal)
        setConfirmRemoveEsignServicesVisible(false);
        // open edit service modal
        setEditServicesVisible(true);
      }}
      onCancel={() => {
        setIsRemoveEsignChanged(false);

        // close confirm remove e-sign message(modal)
        setConfirmRemoveEsignServicesVisible(false);
        // open edit service modal
        setEditServicesVisible(true);
      }}
      closeOnClickOutside={false}
      reverseButtons={false}
    >
      <div className="d-flex justify-content-center">
        <Col sm={10}>
          <span style={{ color: '#556EE6', fontSize: 15, textAlign: 'left' }}>Electronic Signature will be removed. The service will no longer be available for this order.</span>
        </Col>
      </div>
    </Confirmation>}

    {/* Confirm Delete E-sign Documents First */}
    {confirmDeleteDocumentsFirstVisible && <Confirmation
      style={{ backgroundColor: 'white', minWidth: 350, paddingBottom: 35 }}
      customIcon={editServiceIcon}
      showCancel={false}
      onConfirm={() => {
        // close confirm remove e-sign documents message(modal)
        setConfirmDeleteDocumentsFirstVisible(false);
        // open edit service modal
        setEditServicesVisible(true);
      }}
      closeOnClickOutside={false}
    >
      <div className="d-flex justify-content-center">
        <Col sm={10}>
          <span style={{ color: '#556EE6', fontSize: 15, textAlign: 'left' }}>There are Documents added in the <strong>E-Signed Documents</strong> section, so the E-sign service cannot be removed. First delete the documents, and then remove the E-sign service from the order.</span>
        </Col>
      </div>
    </Confirmation>}
  </React.Fragment>
}

SectionOrderActions.propTypes = {
  order: PropTypes.object,
  id: PropTypes.number,
  refreshHandler: PropTypes.func,
};

export default SectionOrderActions;
