import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import SpinnerChase from "components/Shared/SpinnerChase";
import { useDispatch, useSelector } from "react-redux";
import { deleteNotification, doNotificationSingleCleanup, getNotification, getNotificationList, markNotificationRead, markNotificationUnread } from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import { sanitizeHtml, showError, showSuccess } from "helpers/utilHelper";
import { route, routes } from "helpers/routeHelper";
import Confirmation from "components/Shared/Confirmation";
import { formats, formatTimestamp } from "helpers/dateHelper";

const View = () => {
  let { id } = useParams();
  id = parseInt(id);

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // router hook that helps redirect
  const navigate = useNavigate();

  /********** STATE **********/

  const { notification, notificationError, isLoadInProgress, isMarkInProgress, marked, deleted, isDeleteInProgress } = useSelector(state => state.Notification.Single);

  const [moreMenu, setMoreMenu] = useState(false);

  // is the confirmation dialog visible or not
  // used to show/hide the delete warning
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  /********** HANDLERS **********/

  const handleMarkRead = () => {
    dispatch(markNotificationRead(id));
  };

  const handleMarkUnread = () => {
    dispatch(markNotificationUnread(id));
  };

  const removeNotification = () => {
    dispatch(deleteNotification(id));
  }

  /********** EFFECTS **********/

  // runs at component mount and whenever the id changes
  useEffect(() => {
    // get notification
    dispatch(getNotification(id));
    return () => {
      // state cleanup on component unmount
      dispatch(doNotificationSingleCleanup());
    }
  }, [id]);

  // marks the notification as `read` if it isn't already
  useEffect(() => {
    if (notification?.isRead === false) {
      dispatch(markNotificationRead(id))
    }
  }, [notification])

  // runs whenever the `deleted` flag changes
  // which happens after a delete-notification attempt
  useEffect(() => {
    if (deleted === true) {
      showSuccess("Notification has been deleted");
      navigate(route(routes.list_notifications));
    } else if (deleted === false) {
      showError("Unable to delete notifications");
    }
  }, [deleted]);

  // runs whenever the `marked` flag changes
  // which happens after a mark-notification attempt
  useEffect(() => {
    if (marked === true) {
      dispatch(getNotificationList()); // refresh the list in the header
    } else if (marked === false) {
      showError("Unable to mark notification");
    }
  }, [marked]);

  return <div className="page-content">
    <MetaTitle>{notification?.subject || 'Notification'}</MetaTitle>
    <Container fluid>
      <Breadcrumbs breadcrumbItems={breadcrumbs(notification)} />
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <div className="btn-toolbar p-3" role="toolbar">
                <div className="btn-group me-2 mb-2 mb-sm-0">
                  <Button type="button" color="primary" onClick={() => { setIsConfirmationVisible(true) }} disabled={isDeleteInProgress}>
                    {isDeleteInProgress ? <i className="mdi mdi-spin mdi-loading me-1" /> : <i className="far fa-trash-alt" />}
                  </Button>
                </div>
                <Dropdown isOpen={moreMenu} toggle={() => { setMoreMenu(!moreMenu); }} className="btn-group me-2 mb-2 mb-sm-0">
                  <DropdownToggle className="btn btn-primary dropdown-toggle" tag="i">
                    {isMarkInProgress ? <i className="mdi mdi-spin mdi-loading me-1" /> : <span className="font-size-13" style={{ fontStyle: "normal" }}>More</span>}
                    <i className="mdi mdi-chevron-down ms-1" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem to="#" onClick={handleMarkRead}>Mark as Read</DropdownItem>
                    <DropdownItem to="#" onClick={handleMarkUnread}>Mark as Unread</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              {isLoadInProgress && <SpinnerChase className="sm mt-2 mb-2" />}
              {!isLoadInProgress && !notificationError && !!notification && <div className="p-3">
                <div className="d-flex justify-content-between">
                  <h4 className="mt-0 font-size-16">
                    {notification.subject}
                  </h4>
                  <h6>
                    {formatTimestamp(notification.createdTs, formats.DATETIME)}
                  </h6>
                </div>
                <p dangerouslySetInnerHTML={{ __html: sanitizeHtml(notification.message) }} />
                <hr />
              </div>}
              {!isLoadInProgress && notificationError && <Alert color="danger" className="fade show text-center mx-3">
                <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load notification
              </Alert>}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
    {isConfirmationVisible && <Confirmation
      confirmBtnText="Delete"
      onConfirm={() => {
        setIsConfirmationVisible(false);
        removeNotification();
      }}
      onCancel={() => setIsConfirmationVisible(false)}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to delete this notification?</span>
    </Confirmation>}
  </div>
}

const breadcrumbs = notification => [{
  title: 'NOTIFICATIONS',
  url: route(routes.list_notifications),
}, {
  title: notification?.subject,
}];

export default View;